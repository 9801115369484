import React, { useEffect, useState } from "react";
import { Tabs, Space } from "antd";
import SummaryTab from './SummaryTab';
import PaymentTab from './PaymentTab';
import EntriesTab from './EntriesTab';
import { PurchaseOrderDocuments } from './PurchaseOrderDocuments';
import CommentsTab from './CommentsTab';
import Parse from "parse";

const { TabPane } = Tabs;

const fetchPurchaseOrder = async (objectId) => {
  const PurchaseOrder = Parse.Object.extend("PurchaseOrder");
  const query = new Parse.Query(PurchaseOrder);
  query.equalTo("objectId", objectId);
  query.include(["payments", "entries", "comments", "supplier"]);
  const result = await query.first();
  return result.toJSON();
};

const TabsContainer = ({ purchaseOrder, setOpenPayment, setPayment, setShowDelivery, setDelivery, setOpenWarehouseOrder, refresh }) => {
  const [modalRefresh, setModalRefresh] = useState(false);
  const [data, setData] = useState(purchaseOrder);
  const [tab, setTab] = useState('summary');

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPurchaseOrder(purchaseOrder.objectId);
      setData(result);
    };
    fetchData();

  }, [modalRefresh]);

  const refreshModal = () => {
    setModalRefresh(!modalRefresh);
    refresh();
  }

  return (
    <>
      <Space>
        <Tabs defaultActiveKey="summary" onChange={(key) => setTab(key)}>
          <TabPane tab="Resumen" key="summary" />
          <TabPane tab="Pagos" key="payments" />
          <TabPane tab="Recepciones" key="entries" />
          <TabPane tab="Documentos" key="documents" />
          <TabPane tab="Comentarios" key="comments" />
        </Tabs>
      </Space>
      {tab === "summary" && <SummaryTab purchaseOrder={data} refresh={refreshModal} />}
      {tab === "payments" && <PaymentTab purchaseOrder={data} setOpenPayment={setOpenPayment} setPayment={setPayment} refresh={refreshModal} />}
      {tab === "entries" && (
        <EntriesTab
          refresh={refreshModal}
          purchaseOrder={data}
          setShowDelivery={setShowDelivery}
          setDelivery={setDelivery}
          setOpenWarehouseOrder={setOpenWarehouseOrder}
        />
      )}
      {tab === "documents" && <PurchaseOrderDocuments purchaseOrder={data} />}
      {tab === "comments" && <CommentsTab purchaseOrder={data} refresh={refreshModal} />}
    </>
  );
};

export default TabsContainer;
