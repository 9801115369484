import React from "react";
import { Button, Card, Table, Typography, Modal, Image, Descriptions, Row, Col, message, Flex } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import moment from "moment";
import OwnButton from "../../OwnButton";
import Parse from "parse";
import { Printer } from "react-bootstrap-icons";

const { Title, Text } = Typography;

const DeliveryDetail = ({ open, setOpen, delivery, sale }) => {
    const [printing, setPrinting] = React.useState(false);
    console.log("📦 Order: ", delivery);

    if (!delivery) return null;

    const handlePrint = async () => {
        setPrinting(true);
        const params = {
            number: delivery.orderNumber,
            date: delivery.createdAt,
            createdBy: delivery.createdBy?.fullName || "N/A",
            sale: sale.saleNumber,
            client: sale.client.fullName,
            seller: sale.seller.fullName,
            comments: delivery.comments || "Sin comentarios",
            link: 'https://app.dasnerpetcare.com/deliveries/signature/' + delivery.uuid,
            products: delivery.products,
        };

        try {
            const data = await Parse.Cloud.run("printTransportDetails", params);
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        } catch (error) {
            console.error("Error printing delivery order:", error);
            message.error("Ocurrió un error al imprimir la orden de salida. Intente de nuevo.");
        } finally {
            setPrinting(false);
        }
    };

    return (
        <Modal
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Flex gap="10px" alignItems="flex-end" justify="end">
                        <FileTextOutlined style={{ fontSize: "22px", color: "#1890ff" }} />
                        <Title level={4} style={{ marginBottom: 0 }}>Información de transporte</Title>
                    </Flex>
                    {sale?.active && (
                        <OwnButton
                            icon={<Printer />}
                            type="default"
                            onClick={handlePrint}
                            loading={printing}
                        >
                            Imprimir
                        </OwnButton>
                    )}
                </div>
            }
            open={open}
            closable={false}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" type="primary" onClick={() => setOpen(false)}>
                    Cerrar
                </Button>,
            ]}
            width={900}
            bodyStyle={{ padding: "24px" }}
        >
            {/* Order Details */}
            <Card bordered={false} style={{ backgroundColor: "#f9f9f9", marginBottom: "20px" }}>
                <Descriptions column={2} size="middle" layout="vertical">
                    <Descriptions.Item label="Fecha">
                        {moment(delivery.createdAt).format("DD/MM/YYYY")}
                    </Descriptions.Item>
                   
                    <Descriptions.Item label="Emitida por">
                        {delivery.createdBy?.fullName || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cliente">
                        {sale.client?.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Venta">
                        {sale.saleNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vendedor">
                        {sale.seller.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Lugar de entrega">
                        {sale.deliveryAddress?.address}
                    </Descriptions.Item>
                    {sale.deliveryNotes && <Descriptions.Item label="Notas de entrega">
                     {sale.deliveryNotes}
                    </Descriptions.Item>}
                    <Descriptions.Item label="Comentarios" span={2}>
                        {delivery.comments || "Sin comentarios"}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {/* Product List */}
            <Title level={4} style={{ marginBottom: "10px" }}>Productos</Title>
            <Table
                dataSource={delivery?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <Text strong>{text}</Text>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        align: "center",
                        render: (text) => <Text>{text}</Text>,
                    },
                    {
                        title: "Entregado",
                        dataIndex: "delivered",
                        key: "delivered",
                        align: "center",
                        render: (text) => (
                            <Text style={{ color: text > 0 ? "green" : "red" }}>{text}</Text>
                        ),
                    },
                ]}
                pagination={false}
                rowKey={(record) => record.name}
                style={{ marginBottom: "20px" }}
            />

        </Modal>
    );
};

export default DeliveryDetail;