import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, Modal, Select, message } from "antd";
import { addPackaging } from "../services";

const AddPackaging = ({ production, open, setOpen, refresh }) => {
    const [presentation, setPresentation] = useState({});
    const [loading, setLoading] = useState(false);

    // Function to handle form submission
    const handleAddPackaging = async () => {
        if (!presentation.presentation || !presentation.quantity) {
            message.error("Por favor, seleccione una presentación y especifique la cantidad.");
            return;
        }

        setLoading(true);
        try {
            await addPackaging({
                production,
                presentations: [presentation],
            });
            // message.success("Empaque agregado exitosamente.");
            setOpen(false);
        } catch (error) {
            console.error("Error adding packaging:", error);
            // message.error("Ocurrió un error al agregar el empaque. Intente de nuevo.");
        } finally {
            setLoading(false);
            refresh();
        }
    };

    useEffect(() => {
        if (!open) {
            setPresentation({});
        }
    }, [open]);

    // Calculate the volume dynamically
    const calculatedVolume =
        presentation?.presentation?.content && presentation?.quantity
            ? presentation.presentation.content * presentation.quantity
            : 0;

    return (
        <Modal
            title="Agregar empaque"
            open={open}
            okText="Agregar"
            cancelText="Cancelar"
            confirmLoading={loading}
            onOk={handleAddPackaging}
            onCancel={() => setOpen(false)}
            width={800}
        >
            <Row gutter={16}>
                {/* Presentation Selection */}
                <Col span={12}>
                    <Card title="Presentación">
                        <Select
                            placeholder="Seleccione una presentación"
                            value={presentation.presentation?.objectId}
                            onChange={(value) =>
                                setPresentation((prev) => ({
                                    ...prev,
                                    presentation: production?.product?.presentations.find(
                                        (p) => p.objectId === value
                                    ),
                                }))
                            }
                            style={{ width: "100%" }}
                        >
                            {production?.product?.presentations.map((p) => (
                                <Select.Option key={p.objectId} value={p.objectId}>
                                    {p.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Card>
                </Col>

                {/* Quantity Input */}
                <Col span={12}>
                    <Card title="Cantidad">
                        <Input
                            type="number"
                            placeholder="Cantidad"
                            value={presentation.quantity}
                            onChange={(e) =>
                                setPresentation((prev) => ({
                                    ...prev,
                                    quantity: parseFloat(e.target.value) || 0, // Ensure numeric value
                                }))
                            }
                        />
                    </Card>
                </Col>
            </Row>

            {/* Volume Calculation */}
            <Row style={{ marginTop: 16 }}>
                <Col span={24}>
                    <Card>
                        <strong>Volumen envasado:</strong>{" "}
                        {calculatedVolume > 0 ? `${calculatedVolume.toLocaleString()} kgs` : "N/A"}
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
};

export default AddPackaging;