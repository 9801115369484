import React, { useEffect, useState } from "react";
import { Form, Typography, Button, Input, Table } from "antd";
import DebounceSelect from "../../../../form/DebounceSelect";
import Parse from "parse";
import NewRecipe from "../modals/NewRecipe";

const { Title } = Typography;

const RecipeList = ({ parentForm, open, setOpen, recipe, setRecipe }) => {
    console.log("RecipeList -> recipe", recipe);
    const [form] = Form.useForm();
    const [newRecipeOpen, setNewRecipeOpen] = useState(false);
    const [recipes, setRecipes] = useState([]);

    // Fetch recipes from Parse Server
    const fetchRecipes = async (search) => {
        const Recipe = Parse.Object.extend("Recipe");
        const query = new Parse.Query(Recipe);
        query.contains("name", search);
        const results = await query.find();

        const fetchedRecipes = results.map((result) => ({
            value: result.id,
            label: result.get("name"),
            ...result.toJSON(),
        }));

        setRecipes(fetchedRecipes);
        return fetchedRecipes;
    };

    // Initialize form values with the selected recipe
    useEffect(() => {
        if (recipe) {
            form.setFieldsValue({
                name: recipe.name,
                description: recipe.description,
                ingredients: recipe.ingredients,
            });
        }
    }, [recipe]);

    // Update ingredients and recalculate costs
    const handleIngredientChange = (objectId, newQuantity) => {
        if (!recipe || !recipe.ingredients) return;

        const updatedIngredients = recipe.ingredients?.map((ingredient) =>
            ingredient.objectId === objectId
                ? { ...ingredient, quantity: parseFloat(newQuantity) || 0 }
                : ingredient
        );

        const baseCost = updatedIngredients.reduce(
            (total, ingredient) => total + ingredient.baseCost * ingredient.quantity,
            0
        );

        const totalCost = baseCost * (recipe.baseQuantity || 1);

        setRecipe({ ...recipe, ingredients: updatedIngredients, baseCost, totalCost });
    };

    const columns = [
        {
            title: "Insumo",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity, record) => (
                <Input
                    type="number"
                    value={quantity}
                    onChange={(e) => handleIngredientChange(record.objectId, e.target.value)}
                />
            ),
        },
        {
            title: "Costo unitario",
            dataIndex: "baseCost",
            key: "baseCost",
        },
        {
            title: "Costo total",
            dataIndex: "total",
            key: "total",
            render: (text) => <p>${(text || 0).toFixed(2)}</p>,
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {/* New Recipe Modal */}
            <NewRecipe
                open={newRecipeOpen}
                setOpen={setNewRecipeOpen}
                addRecipe={(newRecipe) => {
                    setRecipe(newRecipe);
                    setNewRecipeOpen(false);
                }}
            />

            {/* Recipe Search and Add Button */}
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <DebounceSelect
                    fetchOptions={fetchRecipes}
                    onChange={(selectedRecipe) => {
                        const fullRecipe = recipes.find((r) => r.value === selectedRecipe.value);
                        setRecipe(fullRecipe);
                    }}
                    placeholder="Buscar receta"
                    style={{ flex: 1 }}
                    allowClear
                    showSearch
                />
                <Button type="primary" onClick={() => setNewRecipeOpen(true)}>
                    + Nueva receta
                </Button>
            </div>

            {/* Ingredients Table */}
            <h2>{recipe?.name || "Receta"}</h2>
            <Table
                dataSource={recipe?.ingredients || []}
                columns={columns}
                rowKey="objectId"
                pagination={false}
                scroll={{ x: true }}
                summary={() => {
                    let cost = 0;
                    let volume = 0;
                    recipe?.ingredients?.forEach((ingredient) => {
                        cost += ingredient.baseCost * ingredient.quantity;
                        volume += ingredient.quantity;
                    });

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>{volume} kg</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>${cost.toFixed(2)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />

            {/* Costs Summary */}
            {recipe && (
                <Title level={5}>
                    Costo por {recipe.baseUnit || "unidad"}: ${recipe.baseCost?.toFixed(2) || "0.00"}
                </Title>
            )}
        </div>
    );
};

export default RecipeList;