import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Select } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchIngredients } from "../utils/query";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;

const addPresentation = (product, presentation) => {
    console.log("product", product);
    const presentations = product.presentations || [];
    presentations.push(presentation);
    console.log("presentations", presentations);
    return presentations;
};
const setPresentation = (product, presentation) => {
    const presentations = product.presentations || [];
    const foundPresentation = presentations.find((p) => p.label === presentation.label);
    console.log("foundPresentation", foundPresentation);
    if (foundPresentation && foundPresentation.name) {
        return foundPresentation;
    }
    return addPresentation(product, presentation);
};

const IngredientsList = ({ form, setRenderId }) => {
    const [products, setProducts] = useState([]);
    const [presentationText, setPresentationText] = useState("");

    const ensureNumber = (value) => {
        const num = parseFloat(value);
        return isNaN(num) ? 0 : parseFloat(num.toFixed(2));
    };
    
    const calculatePriceUpdates = ({ form, product, field, value, setRenderId }) => {
        const products = form.getFieldValue("products");
        if (!product) return;
    
        let updatedProduct = { ...product };
        let numericValue = ensureNumber(value);
    
        switch (field) {
            case "price":
                updatedProduct.price = numericValue;
                updatedProduct.unitPrice = ensureNumber(numericValue / (updatedProduct.presentation?.quantity || 1));
                updatedProduct.total = ensureNumber(updatedProduct.quantity * numericValue);
                break;
    
            case "unitPrice":
                updatedProduct.unitPrice = numericValue;
                updatedProduct.price = ensureNumber(numericValue * (updatedProduct.presentation?.quantity || 1));
                updatedProduct.total = ensureNumber(updatedProduct.quantity * updatedProduct.price);
                break;
    
            case "quantity":
                updatedProduct.quantity = numericValue;
                updatedProduct.total = ensureNumber(updatedProduct.price * numericValue);
                break;
    
            case "presentationQuantity":
                if (updatedProduct.presentation) {
                    updatedProduct.presentation.quantity = numericValue;
                    updatedProduct.unitPrice = ensureNumber(updatedProduct.price / numericValue);
                    updatedProduct.total = ensureNumber(updatedProduct.quantity * updatedProduct.price);
                }
                break;
    
            default:
                return;
        }
    
        // Ensure price is not below minPrice
        if (field === "price" && updatedProduct.price < updatedProduct.minPrice) {
            message.error("El precio no puede ser menor al mínimo.");
            updatedProduct.price = updatedProduct.minPrice;
            updatedProduct.total = ensureNumber(updatedProduct.quantity * updatedProduct.minPrice);
        }
    
        // Update products in the form
        const newProducts = products.map((p) =>
            p.objectId === product.objectId ? updatedProduct : p
        );
    
        form.setFieldValue("products", newProducts);
        setProducts(newProducts);
        setRenderId((prev) => prev + 1);
    };
    useEffect(() => {
        form.getFieldValue("products") && setProducts(form.getFieldValue("products"));
    }, [form]);
    

    const onProductSelected = (objectId, product) => {
        console.log("product", product);
        const products = form.getFieldValue("products");
        if (products.find((p) => p.objectId === product.objectId)) {
            return;
        }
        let presentations = product.presentations?.map((p) => ({
            label: p.presentation,
            value: p.presentation,
            ...p,
        }));
        presentations = [...presentations, { label: product.basePresentation, value: product.basePresentation, quantity: 1 }];
        products.push({
            ...product,
            quantity: 1,
            objectId: product.objectId,
            presentations
        });
        console.log("products", products);
        form.setFieldValue("products", products);
        setProducts(products);
        setRenderId((prev) => prev + 1);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchIngredients}
                onChange={onProductSelected}
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
        <Table
            dataSource={products}
            rowKey="objectId"
            pagination={false}
            scroll={{ x: true }}
        >
            <Table.Column
                title="Producto"
                dataIndex="name"
                key="name"
            />
            <Table.Column
    title="Presentación"
    dataIndex="presentation"
    key="presentation"
    render={(text, product, key) => (
        <Select
            style={{ width: 200 }}
            showSearch
            value={product.presentation?.label}
            onSearch={(value) => setPresentationText(value)} // Capture user-typed input
            notFoundContent={
                presentationText ? (
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            const presentation = setPresentation(product, { label: presentationText, value: presentationText, quantity: 1, presentation: presentationText });
                            product.presentation = presentation;
                            product.presentationQuantity = presentation.quantity;
                            product.price = ensureNumber(product.unitPrice * presentation.quantity);
                            product.total = ensureNumber(product.quantity * product.price);
                            const products = form.getFieldValue("products");
                            products[key] = product;
                            form.setFieldValue("products", products);
                            setProducts(products);
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Agregar "{presentationText}"
                    </Button>
                ) : null
            }
            onInputKeyDown={(e) => {
                if (e.key === "Enter") {
                    const presentation = setPresentation(product, { label: presentationText, value: presentationText, quantity: 1, presentation: presentationText });
                    product.presentation = presentation;
                    product.presentationQuantity = presentation.quantity;
                    product.price = ensureNumber(product.unitPrice * presentation.quantity);
                    product.total = ensureNumber(product.quantity * product.price);
                    const products = form.getFieldValue("products");
                    products[key] = product;
                    form.setFieldValue("products", products);
                    setProducts(products);
                    setRenderId((prev) => prev + 1);
                }
            }}
            onChange={(value) => {
                const products = form.getFieldValue("products");
                const updatedProduct = products[key];
                const presentation = product.presentations.find((p) => p.label === value);
                
                setPresentation(updatedProduct, presentation);
                updatedProduct.presentation = presentation;
                updatedProduct.presentationQuantity = presentation.quantity;
                updatedProduct.price = ensureNumber(updatedProduct.unitPrice * presentation.quantity);
                updatedProduct.unitPrice = ensureNumber(updatedProduct.price / presentation.quantity);
                updatedProduct.total = ensureNumber(updatedProduct.quantity * updatedProduct.price);
                
                products[key] = updatedProduct;
                form.setFieldValue("products", products);
                setProducts(products);
                setRenderId((prev) => prev + 1);
            }}
            options={product.presentations}
        />
    )}
/>
            
            <Table.Column
    title="Contenido"
    dataIndex="presentation"
    key="presentationQuantity"
    render={(text, record) => (
        <Input
            type="number"
            value={record.presentation?.quantity}
            onChange={(e) => calculatePriceUpdates({ 
                form, 
                product: record, 
                field: "presentationQuantity", 
                value: e.target.value, 
                setRenderId 
            })}
            suffix={'kg'}
        />
    )}
/>
            
            {/* <Table.Column
                title="Última compra"
                dataIndex="lastPurchase"
                key="lastPurchase"
                render={(text, product) => <p>{text ? moment(text).format("DD/MM/YYYY") : "Sin compras previas"}</p>}
                colSpan={(text) => text ? 1 : 2}
            /> */}
            {/* <Table.Column
                title="Último precio"
                dataIndex="lastPrice"
                key="lastPrice"
                render={(text) => <p>{text ? '$' + text.toLocaleString() : ""}</p>}
            /> */}
           <Table.Column
    title="Precio"
    dataIndex="price"
    key="price"
    render={(text, record) => (
        <Input
            type="number"
            value={text}
            onClick={(e) => e.target.select()}
            style={{ width: 80 }}
            onChange={(e) => calculatePriceUpdates({ 
                form, 
                product: record, 
                field: "price", 
                value: e.target.value, 
                setRenderId 
            })}
        />
    )}
/>
<Table.Column
    title="Precio unitario"
    dataIndex="unitPrice"
    key="unitPrice"
    render={(text, record) => (
        <Input
            type="number"
            value={text}
            onClick={(e) => e.target.select()}
            style={{ width: 80 }}
            onChange={(e) => calculatePriceUpdates({ 
                form, 
                product: record, 
                field: "unitPrice", 
                value: e.target.value, 
                setRenderId 
            })}
        />
    )}
/>
<Table.Column
    title="Cantidad"
    dataIndex="quantity"
    key="quantity"
    render={(text, record) => (
        <Input
            type="number"
            value={text}
            onClick={(e) => e.target.select()}
            style={{ width: 80 }}
            onChange={(e) => calculatePriceUpdates({ 
                form, 
                product: record, 
                field: "quantity", 
                value: e.target.value, 
                setRenderId 
            })}
        />
    )}
/>
            <Table.Column
                title="Total"
                dataIndex="total"
                key="total"
                render={(text) => <p>${text?.toLocaleString()}</p>}
            />
            <Table.Column
                title="Acción"
                key="action"
                render={(text, record) => (
                    <Button
                        type="danger"
                        onClick={() => {
                            const products = form.getFieldValue("products");
                            form.setFieldValue(
                                "products",
                                products.filter((p) => p.objectId !== record.objectId)
                            );
                            setProducts(products.filter((p) => p.objectId !== record.objectId));
                            setRenderId((prev) => prev + 1);
                        }}
                    >
                        Eliminar
                    </Button>
                )}
            />
        </Table>
        </Space>
    );
}

export default IngredientsList;
