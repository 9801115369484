import Parse from 'parse';

const fetchInventory = async (filters) => {
    return new Promise((resolve) => {
        const Inventory = Parse.Object.extend("Ingredient");
        const query = new Parse.Query(Inventory);
        if (filters?.name) query.matches("name", filters.name, "i");
        if (filters?.warehouse?.length) query.containedIn("warehouse", filters.warehouse);
        query.find().then((results) => {
            const inventory = results.map((entry) => ({
                value: entry.id,
                ...entry.toJSON()
            }));
            resolve(inventory);
        });
    });
}

const fetchIngredients = async () => {
    const Ingredients = Parse.Object.extend('Ingredient');
    const query = new Parse.Query(Ingredients);
    query.limit(10);
    const results = await query.find();
    const ingredients = results.map((result) => ({
        key: result.id,
        label: result.get('name'),
        value: result.id,
        ...result.toJSON()
    }));
    return ingredients;
}

console.log('fetchIngredients');

const fetchPackaging = async (value) => {
    const Ingredients = Parse.Object.extend('Ingredient');
    const query = new Parse.Query(Ingredients);
    query.equalTo('warehouse', 'Envasado');
    value && query.matches('name', value, 'i');
    query.limit(10);
    const results = await query.find();
    const ingredients = results.map((result) => ({
        key: result.id,
        label: result.get('name'),
        value: result.id,
        ...result.toJSON()
    }));
    return ingredients;
}

export { fetchInventory, fetchIngredients, fetchPackaging };