// Template
import DataTable from "../component/applications/DataTable";
import FullCalender from "../component/applications/FullCalender";
import Kanban from "../component/applications/Kanban";
import Wizard from "../component/applications/Wizard";
import Invoice from "../component/pages/account/Invoice";
// import Settings from "../component/pages/account/Settings";
import Alerts from "../component/pages/Alerts";
import Billing from "../component/pages/Billing";
import ChartLine from "../component/pages/ChartLine";
import CRM from "../component/pages/CRM";
import OrderDetails from "../component/pages/ecommerce/order/OrderDetails";
import OrderList from "../component/pages/ecommerce/order/OrderList";
import EditProduct from "../component/pages/ecommerce/Products/EditProduct";
import NewProduct from "../component/pages/ecommerce/Products/NewProduct";
import ProductPage from "../component/pages/ecommerce/Products/ProductPage";
import Home from "../component/pages/Home";
import PurchasesList from "../component/pages/purchases/PurchasesList";
import ClientsList from '../component/pages/clients/ClientsList';
import SellersList from '../component/pages/sellers/SellersList';
import SuppliersList from '../component/pages/suppliers/SuppliersList';
import Notifications from "../component/pages/Notifications";
import Profile from "../component/pages/Profile";
import AllProject from "../component/pages/profile/AllProject";
import TimeLine from "../component/pages/projects/TimeLine";
import Rtl from "../component/pages/Rtl";
import NewUsers from "../component/pages/users/NewUsers"
import SalesList from "../component/pages/sales/SalesList";
import ClientDetails from "../component/pages/clients/ClientDetails/ClientDetails";
import SellerDetails from "../component/pages/sellers/SellerDetails/SellerDetails";
import SupplierDetails from "../component/pages/suppliers/SupplierDetails/SupplierDetails.js";
import QuoteDetail from "../component/pages/sales/QuoteDetail";
import SalesDetails from "../component/pages/sales/SalesDetails";
// Custom
import ProductionList from "../component/pages/production/ProductionList";
import PackagingList from "../component/pages/packaging/PackagingList.js";
import PackagingDetails from "../component/pages/packaging/PackagingDetails.js";
import OKRsDetails from "../component/pages/okrs/okrsDetails.js"; 
import ProductionDetails from "../component/pages/production/ProductionDetails.js"; 
import FinanceList from "../component/pages/finance/FinanceList"; 
import Payments from "../component/pages/finance/Payments";
import Outpyaments from "../component/pages/finance/Outpayments";
import SignIn from "../component/pages/SignIn";
import SignUp from "../component/pages/SignUp";
import UsersList from "../component/pages/users/UsersList.js";
import RolesList from "../component/pages/users/RolesList.js";
import Settings from "../component/pages/settings/Settings";
import MaintenanceList from "../component/pages/maintenance/MaintenanceList.js";
import { 
  ProductDetails,
  ProductInventory,
  RecipeList,
  InventoryDetails,
  WarehouseEntryList,
  WarehouseEntryDetail,
  InputInventory,
  Recipe,
  WarehouseOutput,
  WarehouseOutputList
} from "../component/pages/inventory";

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: SalesList,
  },

  {
    path: "/sign-up",
    exact: true,
    name: "Registrarse",
    component: SignUp,
  },
  
  {
    path: "/dashboard",
    exact: true,
    name: "Default",
    component: SalesList,
  },
  {
    path: "/Clients",
    exact: true,
    name: "Default",
    component: SalesList,
  },
  {
    path: "/sales/SalesDetails",
    exact: true,
    name: "Detalle de Venta",
    component: SalesDetails
  },
  {
    path: "/dashboard/crm",
    exact: true,
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/profile-overview",
    exact: true,
    name: "Profile Overview",
    component: Profile,
  },
  {
    path: "/pages/profile/all-projects",
    exact: true,
    name: "All Project",
    component: AllProject,
  },
  {
    path: "/pages/users/new-user",
    exact: true,
    name: "New User",
    component: NewUsers,
  },
  {
    path: "/pages/account/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    exact: true,
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/projects/timeline",
    exact: true,
    name: "Timeline",
    component: TimeLine,
  },
  {
    path: "/pages/rtl",
    exact: true,
    name: "RTL",
    component: Rtl,
  },
  {
    path: "/pages/charts",
    exact: true,
    name: "Charts",
    component: ChartLine,
  },
  {
    path: "/pages/alerts",
    exact: true,
    name: "Alerts",
    component: Alerts,
  },
  {
    path: "/pages/notifications",
    exact: true,
    name: "Notification",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    exact: true,
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    exact: true,
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-table",
    exact: true,
    name: "DataTable",
    component: DataTable,
  },
  {
    path: "/applications/calendar",
    exact: true,
    name: "Calender",
    component: FullCalender,
  },
  {
    path: "/ecommerce/products/new-product",
    exact: true,
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    exact: true,
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    exact: true,
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/orders/order-list",
    exact: true,
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/orders/order-details",
    exact: true,
    name: "Order List",
    component: OrderDetails,
  },
 
  {
    path: '/clients/clientslist',
    exact: true,
    name: 'Lista de clientes',
    component: ClientsList
  }, {
    path: '/sellers/sellerslist',
    exact: true,
    name: 'Lista de vendedores',
    component: SellersList
  },
  {
    path: '/suppliers/supplierslist',
    exact: true,
    name: 'Lista de provedores',
    component: SuppliersList

  },
  {
    path: '/purchases/PurchasesList',
    exact: true,
    name: 'Lista de compras',
    component: PurchasesList

  },
  {
    path: "/sales/SalesList",
    exact: true,
    name: "Lista de ventas",
    component: SalesList
  },

  {
    path: "/inventory/WarehouseEntryList",
    exact: true,
    name: "Lista de entradas de almacén",
    component: WarehouseEntryList
  },
  {
    path: "/clients/:uuid",
    exact: true,
    name: "Detalle de cliente",
    component: ClientDetails
  },
  {
    path: "/sellers/:uuid",
    exact: true,
    name: "Detalle de Vendedor",
    component: SellerDetails
  },
  {
    path: "/suppliers/:uuid",
    exact: true,
    name: "Detalle de Proveedor",
    component: SupplierDetails
  },
  
  {
    path: "/sales/quoteDetail",
    exact: true,
    name: "Detalle de cotización",
    component: QuoteDetail
  },
  {
    path: "/inventory/WarehouseEntryDetail",
    exact: true,
    name: "Detalle Entrada a Almacén",
    component: WarehouseEntryDetail
  },
  {
    path: "/inventory/InputInventory",
    exact: true,
    name: "Inventario de Insumos",
    component: InputInventory
  },
  {
    path: "/inventory/InventoryDetails",
    exact: true,
    name: "Detalle de Insumo",
    component: InventoryDetails
  },
  {
    path: "/inventory/Recipe",
    exact: true,
    name: "Receta",
    component: Recipe
  },
  {
    path: "/inventory/ProductInventory",
    exact: true,
    name: "Inventario de Producto",
    component: ProductInventory

  },
  {
    path: "/inventory/product-details/:uuid",
    exact: true,
    name: "Detalles de Producto",
    component: ProductDetails
  },
  
  {
    path: "/production/ProductionList",
    exact: true,
    name: "Lista de Producción",
    component: ProductionList
  },
  {
    path: "/production/PackagingList",
    exact: true,
    name: "Lista de Envasado",
    component: PackagingList
  },
  {
    path: "/production/recipes",
    exact: true,
    name: "Recetas",
    component: RecipeList
  },
  {
    path: "/inventory/WarehouseOutput",
    exact: true,
    name: "Salida de almacen",
    component: WarehouseOutput
  },
  {
    path: "/inventory/WarehouseOutputList",
    exact: true,
    name: "Lista de salidas de almacen",
    component: WarehouseOutputList
  },
  {
    path: "/okrs/okrsDetails",
    exact: true,
    name: "Detalles de OKRs",
    component: OKRsDetails
  },
  {
    path: "/production/detail/:id",
    exact: true,
    name: "Detalles de Producción",
    component: ProductionDetails
  },
  {
    path: "/finance/FinanceList/",
    exact: true,
    name: "Balance financiero",
    component: FinanceList
  }, 
  {
    path: "/finance/payments/",
    exact: true,
    name: "Cuentas por cobrar",
    component: Payments
  },
  {
    path: "/finance/outpayments/",
    exact: true,
    name: "Cuentas por pagar",
    component: Outpyaments
  },
  {
    path: "/users/UsersList",
    exact: true,
    name: "Lista de Usuarios",
    component: UsersList
  },
  {
    path: "/users/RolesList",
    exact: true,
    name: "Lista de Roles",
    component: RolesList
  },
  {
    path: "/packaging/detail/:id",
    exact: true,
    name: "Detalles de Envasado",
    component: PackagingDetails
  },
  {
    path: "/settings",
    exact: true,
    name: "Configuraciones",
    component: Settings
  },
  {
    path: "/maintenance/MaintenanceList",
    exact: true,
    name: "Mantenimiento",
    component: MaintenanceList
  },
  
];

export default routes;
