import React, { useEffect, useState, useContext } from 'react';
import { Button, Flex, message, Result, Skeleton, Card, Typography, Divider, Table } from 'antd';
import Parse from 'parse';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import dasner_logo_black from '../../../images/dasner_logo_black.png';

const { Title, Text } = Typography;

const formatNumber = (value, locale = 'es-MX') => {
    if (typeof value === 'string') {
        value = value.replace(/[^\d.-]/g, '');
        value = parseFloat(value);
    }
    return isNaN(value) 
        ? new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(0) 
        : new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

const fetchPresentations = async (products) => {
    const Presentation = Parse.Object.extend('Presentation');
    const query = new Parse.Query(Presentation);
    query.containedIn('objectId', products.map((product) => product.presentation.objectId));
    const response = (await query.find()).map((presentation) => presentation.toJSON());
    
    let productsWithPresentations = products.map((product) => {
        const presentation = response.find((presentation) => presentation.objectId === product.presentation.objectId);
        return {
            ...product,
            presentation
        };
    });
    return productsWithPresentations;
};



const AcceptQuote = () => {
    const [quote, setQuote] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { user, loadingUser, logout } = useContext(UserContext);

    useEffect(() => {
        if (!loadingUser && !user) {
            console.log('User not logged in');
            navigate('/signin', { state: { from: `/sales/AcceptQuote/${uuid}` } });
        } else if (user) {
            fetchQuote();
        }
    }, [user, loadingUser]);

    const fetchQuote = async () => {
        const Quote = Parse.Object.extend('Sales');
        const query = new Parse.Query(Quote);
        query.equalTo('uuid', uuid);
        query.include(['seller', 'presentation']);
        // query.equalTo('client', { __type: 'Pointer', className: '_User', objectId: user?.objectId });
        // query.equalTo('active', true);
        
        try {
            setLoading(true);
            const response = await query.first();
            console.log('response', response);
            if (!response) {
                console.log('No response');
                setError(true);
            } else {
                let quote = response.toJSON();
                const products = quote.products
                const productsWithPresentations = await fetchPresentations(products);
                quote.products = productsWithPresentations;
                console.log('quote', quote);
                setQuote(quote);
            }
        } catch (e) {
            console.log('Error', e);
            setError(true);
        }
        setLoading(false);
    };

    const acceptQuote = async () => {
        const Quote = Parse.Object.extend('Sales');
        const quoteObject = new Quote();
        quoteObject.set('objectId', quote.objectId);
        quoteObject.set('status', 'Confirmada');
        try {
            await quoteObject.save();
            message.success('Cotización aceptada');
            fetchQuote();
        } catch (error) {
            message.error('Error al aceptar la cotización');
        }
    };

    // if (error) {
    //     return (
    //         <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
    //             <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20, cursor: 'pointer' }} onClick={() => navigate('/')} />
    //             <Result status="error" title="Error" subTitle="No se encontró la cotización" />
    //             <Title level={4} style={{ textAlign: 'center' }}>
    //                 La cotización que intentas aceptar no existe o no tienes permisos para verla.
    //             </Title>
    //             <Button type="primary" onClick={logout} style={{ marginTop: '20px' }}>
    //                 Cerrar sesión
    //             </Button>
    //         </Flex>
    //     );
    // }

    if (loading) {
        return (
            <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20, cursor: 'pointer' }} onClick={() => navigate('/')} />
                <Skeleton active style={{ width: '80%' }} />
            </Flex>
        );
    }

    if (quote?.status === 'Cancelada') {
        return (
            <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20, cursor: 'pointer' }} onClick={() => navigate('/')} />
                <Result status="error" title="Cotización cancelada" subTitle="Esta cotización ha sido cancelada" />
            </Flex>
        );
    }

    return (
        <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px', maxWidth: '900px', margin: 'auto' }}>
            {/* Logo */}
            <img src={dasner_logo_black} alt="Dasner" style={{ width: 150, marginBottom: 20, cursor: 'pointer' }} onClick={() => navigate('/')} />

            {/* Cotización info */}
            <Card style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
                <Title level={2}>Cotización</Title>
                <Title level={4} style={{ marginBottom: 10 }}>{quote?.quoteNumber}-{quote?.version}</Title>
                <Text strong>Vendedor: </Text> <Text>{quote?.seller?.fullName}</Text>
                <br />
                <Text strong>Total: </Text> <Text>${quote?.total?.toLocaleString()}</Text>
            </Card>

            <Divider />

            {/* Tabla de productos */}
            <Table
                dataSource={quote?.products}
                rowKey="objectId"
                pagination={false}
                style={{ width: '100%' }}
                bordered
                columns={[
                    {
                        title: 'Producto',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text) => <Text strong>{text}</Text>,
                        ellipsis: true,
                    },
                    {
                        title: 'Presentación',
                        dataIndex: ['presentation', 'name'],
                        key: 'presentation',
                        align: 'center',
                    },
                    {
                        title: 'Volumen unitario',
                        dataIndex: ['presentation', 'content'],
                        key: 'volume',
                        render: (text) => <Text>{formatNumber(text)} kgs</Text>,
                    },
                    {
                        title: 'Cantidad',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        align: 'center',
                        render: (text) => <Text>{formatNumber(text)}</Text>,
                    },
                   
                    {
                        title: 'Precio Unitario',
                        dataIndex: 'price',
                        key: 'price',
                        align: 'center',
                        render: (text) => <Text>${formatNumber(text)}</Text>,
                    },
                    {
                        title: 'Volumen total',
                        dataIndex: 'total',
                        key: 'total',
                        render: (text, record) => (
                            <Text>
                                {formatNumber(record.quantity * record.presentation.content)} kgs
                            </Text>
                        ),
                    },
                    {
                        title: 'Subtotal',
                        dataIndex: 'total',
                        key: 'total',
                        align: 'center',
                        render: (text) => <Text>${formatNumber(text)}</Text>,
                    },
                ]}
            />

            <Divider />

            {/* Botones de acción */}
            {quote?.status !== 'Confirmada' && (
                <Flex justify="center" gap={20} style={{ marginTop: '20px' }}>
                    <Button type="primary" size="large" onClick={acceptQuote}>
                        Aceptar Cotización
                    </Button>
                    <Button type="default" size="large" onClick={() => navigate(-1)}>
                        Regresar
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default AcceptQuote;