import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Input, message, Modal, Form, Card, Flex } from "antd";
import DebounceSelect from "../../../../form/DebounceSelect";
import { fetchIngredients } from "../services";
import Parse from "parse";

const { Title } = Typography;

const transformData = (data) => {
    return data.map((item) => ({
      name: item.name,
      basePresentation: item.basePresentation,
      warehouse: item.warehouse,
      baseCost:  parseFloat(item.baseCost.toFixed(2)),
      objectId: item.objectId,
      quantity: parseFloat(item.quantity),
      total: parseFloat((item.baseCost * item.quantity).toFixed(2)),
    }));
  };

const queryProduct = async (name) => {
    const query = new Parse.Query("Product");
    query.matches("name", name, "i");
    const results = await query.find();
    return results.map((result) => ({
        value: result.id,
        label: result.get("name"),
        ...result.toJSON(),
    }));
};

const NewRecipe = ({ open, setOpen, addRecipe, recipe, refresh, requiresProduct }) => {
    const [form] = Form.useForm();
    const [ingredients, setIngredients] = useState([]);
    const [renderId, setRenderId] = useState(0); // Used to force rerender
    const [totalCost, setTotalCost] = useState(0); // Store total cost
    const [unitCost, setUnitCost] = useState(0); // Store cost per unit
    const [cloningRecipe, setCloningRecipe] = useState(false);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if (recipe && open) {
            form.setFieldsValue({
                name: recipe.name,
                description: recipe.description,
                ingredients: recipe.ingredients,
                finalWeight: recipe.finalWeight,
            });
            setIngredients(recipe.ingredients || []);
        }
    }, [recipe, open]);

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIngredients([]);
            setTotalCost(0);
            setUnitCost(0);
            setCloningRecipe(false);
        }
    }, [open]);

    useEffect(() => {
        const total = ingredients.reduce((sum, ing) => sum + ing.baseCost * ing.quantity, 0);
        const finalWeight = form.getFieldValue("finalWeight") || 1;
        const unit = total / finalWeight;

        setTotalCost(isNaN(total) ? 0 : total.toFixed(2));
        setUnitCost(isNaN(unit) ? 0 : unit.toFixed(2));
    }, [ingredients, form, renderId]);

    const handleIngredientSelect = (objectId, ingredient) => {
        if (ingredients.some((ing) => ing.objectId === ingredient.objectId)) return;

        const newIngredient = { ...ingredient, quantity: 1, total: 0 };
        setIngredients([...ingredients, newIngredient]);
        triggerRerender();
    };

    const triggerRerender = () => setRenderId((prev) => prev + 1);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const recipeData = {
                ...values,
                finalWeight: parseFloat(values.finalWeight),
                ingredients: transformData(ingredients),
                ingredientPointers: ingredients?.map((ing) => ({
                    __type: "Pointer",
                    className: "Ingredient",
                    objectId: ing.objectId,
                })),
                ingredientsWeight: ingredients.reduce((sum, ing) => sum + parseFloat(ing.quantity), 0),
                totalCost: parseFloat(totalCost),
                baseCost: parseFloat(unitCost),
                baseUnit: "Kg",
                product: {
                    __type: "Pointer",
                    className: "Product",
                    objectId: recipe?.product?.objectId || product?.objectId || product?.value || null,
                },
            };

            verifyDuplicates(recipeData);
        });
    };

    const verifyDuplicates = (data) => {
        // Verify there is no other recipe with the same name and the same product
        const query = new Parse.Query("Recipe");
        query.equalTo("name", data.name);
        query.equalTo("product", {
            __type: "Pointer",
            className: "Product",
            objectId: recipe?.product?.objectId,
        });

        query.first().then((result) => {
            if (!result) {
                saveRecipeToParse(data);
                return;
            }
            if (!result.id) {
                saveRecipeToParse(data);
                return;
            }
            if ((result && result.id !== recipe.objectId) || (result && cloningRecipe)) {
                message.error("Ya existe una receta con el mismo nombre y producto");
            } else {
                saveRecipeToParse(data);
            }
        });
    };

    const saveRecipeToParse = (data) => {
        const Recipe = Parse.Object.extend("Recipe");
        const recipeParse = new Recipe();

        if (recipe && !cloningRecipe) {
            recipeParse.set("objectId", recipe.objectId);
        }
        Object.keys(data).forEach((key) => recipeParse.set(key, data[key]));


        recipeParse
            .save()
            .then((recipe) => {
                message.success("Receta guardada correctamente");
                form.resetFields();
                if (addRecipe) addRecipe(recipe.toJSON());
                if (refresh) refresh();
                setOpen(false);
            })
            .catch((error) => console.error("Error saving recipe:", error));
    };

    const handleIngredientRemove = (objectId) => {
        setIngredients(ingredients.filter((ing) => ing.objectId !== objectId));
        triggerRerender();
    };

    const columns = [
        {
            title: "Insumo",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity, record) => (
                <Input
                    type="number"
                    value={quantity}
                    onClick={(e) => e.target.select()}
                    style={{ width: 80 }}
                    onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        const updatedIngredients = ingredients?.map((ing) =>
                            ing.objectId === record.objectId
                                ? { ...ing, quantity: value, total: value * ing.baseCost }
                                : ing
                        );
                        setIngredients(updatedIngredients);
                        triggerRerender();
                    }}
                />
            ),
        },
        {
            title: "Costo unitario",
            dataIndex: "baseCost",
            key: "baseCost",
            render: (cost) => `$${cost.toFixed(2)}`,
        },
        {
            title: "Costo total",
            key: "total",
            render: (text, record) => `$${(record.baseCost * record.quantity).toFixed(2)}`,
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) => (
                <Button type="danger" onClick={() => handleIngredientRemove(record.objectId)}>
                    Eliminar
                </Button>
            ),
        },
    ];

    return (
        <Modal
            title={recipe ? "Editar receta" : "Nueva receta"}
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="cancel" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="save" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: "Por favor ingrese el nombre de la receta" }]}
                >
                    <Input />
                </Form.Item>
                    {recipe && !cloningRecipe && (
                        <Button type="primary" onClick={() => setCloningRecipe(true)} allignSelf="center">
                            Clonar receta
                        </Button>
                    )}
                    {
                        recipe && cloningRecipe && (
                            <Card style={{ marginBottom: 10 }}>
                            <Typography.Text>
                                Se está clonando la receta, asegúrese de cambiar el nombre y los ingredientes
                            </Typography.Text>
                            </Card>
                        )
                    }
                <Form.Item
                    label="Descripción"
                    name="description"
                    rules={[{ required: true, message: "Por favor ingrese la descripción de la receta" }]}
                >
                    <Input.TextArea />
                </Form.Item>
                    <Form.Item
                        label="Producto"
                        name="product"
                    >
                        <DebounceSelect
                            fetchOptions={queryProduct}
                            onChange={(product) => setProduct(product)}
                            placeholder="Buscar producto"
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                        />
                    </Form.Item>
                
                <Form.Item
                    label="Peso producto terminado"
                    name="finalWeight"
                    rules={[{ required: true, message: "Por favor indique el peso del producto terminado" }]}
                >
                    <Input type="number" suffix="Kg" onChange={(e) => triggerRerender()} />
                </Form.Item>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <DebounceSelect
                        fetchOptions={fetchIngredients}
                        onChange={handleIngredientSelect}
                        placeholder="Buscar ingrediente"
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                    />
                    <Table
                        dataSource={ingredients}
                        columns={columns}
                        rowKey="objectId"
                        pagination={false}
                        scroll={{ x: true }}
                        footer={() => (
                            <Flex justify="flex-end">
                            <Title level={5}>
                                Volúmen total: {ingredients.reduce((sum, ing) => sum + ing.quantity, 0)} Kg
                            </Title>
                            </Flex>
                        )}
                    />
                    <Title level={5}>Costo total: ${totalCost}</Title>
                    <Title level={5}>Costo por Kg: ${unitCost}</Title>
                </Space>
            </Form>
        </Modal>
    );
};

export default NewRecipe;