import React from "react";
import { Card, Form, Row, Col, Input } from "antd";

const ClientGeneralInfo = ({ supplier }) => {
    const contactPersons = supplier?.contactPersons || [];
    if (!supplier) return null;
    if (!contactPersons.length) return (
        <Card
        id="basic-info"
        className="mb-24 header-solid"
        bordered={false}
        title={<h5 className="mb-0">Personas de contacto</h5>}
        >
            <p>No hay personas de contacto</p>
        </Card>
    )
    return (
        <Card
        id="basic-info"
        className="mb-24 header-solid"
        bordered={false}
        title={<h5 className="mb-0">Personas de contacto</h5>}
      >
        <Form layout="vertical">
         {supplier && contactPersons?.map((contactPerson, index) => (
            <Row gutter={24} key={index}>
                <Col span={12}>
                <Form.Item label="Área">
                  <Input value={contactPerson.area} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Nombre">
                  <Input value={contactPerson.name} />
                </Form.Item>
              </Col>
              
              <Col span={12}>
                <Form.Item label="Teléfono">
                  <Input value={contactPerson.phoneNumber} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Correo">
                  <Input value={contactPerson.email} />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </Card>
    )
}


export default ClientGeneralInfo;