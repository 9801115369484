import React, { useEffect, useState } from "react";
import { Row, Col, FloatButton } from "antd";
import moment from "moment";
import NewClient from "../../../components/NewClient";
import { 
  ClientGeneralInfo, 
  ClientAddress, 
  ClientFiscalInfo, 
  ClientBalance, 
  ClientContactPersons,
  ClientPurchases, 
  ClientPayments 
} from "./components";

const ClientInfo = ({ client, refresh }) => {
  const [editClient, setEditClient] = useState(false);
  const [paymentDiff, setPaymentDiff] = useState(0);

  useEffect(() => {
    if (client) {
      const totalDiff = client.payments?.reduce((acc, payment) => {
        if (payment.dueDate && payment.paymentDate) {
          return (
            acc +
            moment(payment.dueDate?.iso).diff(moment(payment.paymentDate?.iso), "days")
          );
        }
        return acc;
      }, 0);
      setPaymentDiff(totalDiff / client.payments?.length || 0);
    }
  }, [client, refresh]);

  if (!client) return null;

  return (
    <Row gutter={[24, 24]}>
      <NewClient open={editClient} setOpen={setEditClient} client={client} editing refresh={refresh} />
      <FloatButton onClick={() => setEditClient(true)} />
      <Col span={16}>
        <ClientGeneralInfo client={client} />
        <ClientContactPersons client={client} />
        <ClientAddress client={client} />
        <ClientFiscalInfo client={client} />
      </Col>
      <Col span={8}>
        <ClientBalance client={client} paymentDiff={paymentDiff} />
        {/* <ClientPurchases client={client} />
        <ClientPayments client={client} /> */}
      </Col>
    </Row>
  );
};

export default ClientInfo;