import React from "react";
import { Button, Card, Table, Typography, Modal, Image, Descriptions, Row, Col, Flex, message } from "antd";
import moment from "moment";
import OwnButton from "../../OwnButton";
import { Printer, Envelope, Whatsapp, Pencil } from "react-bootstrap-icons";
import Parse from "parse";

const { Title, Text } = Typography;

const formatNumber = (value, locale = 'es-MX') => {
    if (typeof value === 'string') {
        value = value.replace(/[^\d.-]/g, '');
        value = parseFloat(value);
    }
    return isNaN(value) 
        ? new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(0) 
        : new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

const ReceptionDetail = ({ open, setOpen, delivery, order }) => {
    const [printing, setPrinting] = React.useState(false);
    console.log("📦 Order: ", delivery);

    const print = async () => {
        const numberOfProducts = order.products.reduce((acc, product) => acc + product.quantity, 0);

        setPrinting(true);
        const params = {
           orderNumber: order.purchaseOrderNumber,
           date: delivery.createdAt,
           createdBy: delivery.createdBy?.fullName || "N/A",
           supplier: order.supplier?.fullName || "N/A",
           purchaseOrder: order,
           comments: delivery.comments || "Sin comentarios",
            products: order.products,
            useCardStyle: true,
        };

        try {
        const data = await Parse.Cloud.run('printReceptionOrder', params);
       
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        }
        catch (error) {
            // Catch if pop-up is blocked
            console.error('Error opening PDF:', error);
            message.error({
                message: 'Error',
                description: 'Permite las ventanas emergentes para este sitio e intenta de nuevo',
            });
        }
        finally {
            setPrinting(false);
        }
    }
    
    if (!delivery) return null;

    return (
        <Modal
            closable={false}
            title={
                <Flex justify="space-between" align="center">
                    <Title level={4} style={{ marginBottom: 0 }}>Orden de entrada</Title>
                    <OwnButton icon={<Printer />} onClick={print} loading={printing}>Imprimir</OwnButton>
                    
                </Flex>
            }
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" type="primary" onClick={() => setOpen(false)}>
                    Cerrar
                </Button>,
            ]}
            width={900}
            bodyStyle={{ padding: "24px" }}
        >
            {/* Order Details */}
            <Card bordered={false} style={{ backgroundColor: "#f9f9f9", marginBottom: "20px" }}>
                <Descriptions column={2} size="middle" layout="vertical">
                    <Descriptions.Item label="Orden de compra">
                        {order?.purchaseOrderNumber || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Folio de recepción">
                        {delivery.orderNumber || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha">
                        {moment(delivery.createdAt).format("DD/MM/YYYY")}
                    </Descriptions.Item>
                     <Descriptions.Item label="Proveedor">
                                            {order.supplier?.fullName || "N/A"}
                                </Descriptions.Item>
                    <Descriptions.Item label="Creada por">
                        {delivery.createdBy?.fullName || "N/A"}
                    </Descriptions.Item>
                   
                    <Descriptions.Item label="Comentarios" span={2}>
                        {delivery.comments || "Sin comentarios"}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {/* Product Table */}
            <Title level={4} style={{ marginBottom: "10px" }}>Productos</Title>
            <Table
                dataSource={delivery?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <Text strong>{text}</Text>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "purchaseUnitQuantity",
                        key: "purchaseUnitQuantity",
                        align: "center",
                        render: (text) => <Text>{formatNumber(text)}</Text>
                    },
                    {
                        title: "Unidad",
                        dataIndex: ["presentation", "name"],
                        key: "unit",
                    },
                   
                    {
                        title: "Volumen esperado",
                        dataIndex: "baseUnitQuantity",
                        key: "baseUnitQuantity",
                        render: (text) => <Text>{formatNumber(text)} kgs</Text>
                    },
                ]}
                pagination={false}
                rowKey={(record) => record.name}
                style={{ marginBottom: "20px", borderRadius: "8px" }}
            />

            {/* Images */}
            {delivery?.images?.length > 0 && (
                <>
                    <Title level={4} style={{ marginBottom: "10px" }}>Imágenes de la Recepción</Title>
                    <Row gutter={[16, 16]}>
                        {delivery.images.map((image, index) => (
                            <Col span={6} key={index}>
                                <Image
                                    src={image}
                                    alt={`Delivery Image ${index + 1}`}
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    preview={true}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {/* Signatures in Two Columns */}
            {(delivery?.clientSignature || delivery?.carrierSignature) && (
                <Row gutter={16} style={{ marginTop: "20px" }}>
                    {delivery?.clientSignature && (
                        <Col span={12}>
                            <Card hoverable style={{ textAlign: "center", padding: "10px" }}>
                                <Title level={5}>Firma del Cliente</Title>
                                <Image
                                    src={delivery.clientSignature.image}
                                    alt="Client Signature"
                                    style={{ width: "80%", maxHeight: "100px" }}
                                    preview={false}
                                />
                                <Text style={{ display: "block", fontWeight: "bold", marginTop: "5px" }}>
                                    {delivery.clientSignature.name}
                                </Text>
                            </Card>
                        </Col>
                    )}
                    {delivery?.carrierSignature && (
                        <Col span={12}>
                            <Card hoverable style={{ textAlign: "center", padding: "10px" }}>
                                <Title level={5}>Firma del Transportista</Title>
                                <Image
                                    src={delivery.carrierSignature.image}
                                    alt="Carrier Signature"
                                    style={{ width: "80%", maxHeight: "100px" }}
                                    preview={false}
                                />
                                <Text style={{ display: "block", fontWeight: "bold", marginTop: "5px" }}>
                                    {delivery.carrierSignature.name}
                                </Text>
                            </Card>
                        </Col>
                    )}
                </Row>
            )}
        </Modal>
    );
};

export default ReceptionDetail;