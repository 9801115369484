import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, Modal, Select, message } from "antd";
import { addShrinkage } from "../services";

const AddShrinkage = ({ production, open, setOpen, refresh }) => {
    const [product, setProduct] = useState({});
    const [ingredient, setIngredient] = useState({});
    const [quantity, setQuantity] = useState(0);
    const [reason, setReason] = useState("");
    const [type, setType] = useState({});
    const [loading, setLoading] = useState(false);

    console.log('product', product);
    console.log('ingredient', ingredient);

    // // Function to handle form submission
    const handleAddShrinkage = async () => {
        if ((!product && !ingredient) || !quantity || !reason) {
            message.error("Por favor, seleccione un producto o insumo, especifique la cantidad y el motivo.");
            return;
        }

        setLoading(true);
        try {
            await addShrinkage({
                production,
                product,
                ingredient,
                quantity: parseFloat(quantity),
                reason,
                type
            });
            // message.success("Empaque agregado exitosamente.");
            setOpen(false);
        } catch (error) {
            console.error("Error adding packaging:", error);
            // message.error("Ocurrió un error al agregar el empaque. Intente de nuevo.");
        } finally {
            setLoading(false);
            refresh();
        }
    };

    useEffect(() => {
        if (!open) {
            
        }
    }, [open]);

    const renderProduct = (product) => {
        return <Select
            placeholder="Seleccione un producto"
            value={product}
            onChange={(value) => setProduct(value)}
            style={{ width: "100%" }}
        >
                <Select.Option key={production.product.objectId} value={production.product.objectId}>
                    {production.product.name}
                </Select.Option>
        </Select>
    }

    const renderIngredient = (ingredient) => {
        return <Select
            placeholder="Seleccione un insumo"
            value={ingredient}
            onChange={(value) => setIngredient(value)}
            style={{ width: "100%" }}
        >
            {production?.product?.presentations.map((presentation) => (
                presentation.packaging.map((ingredient) => (
                    <Select.Option key={ingredient.objectId} value={ingredient.objectId}>
                        {ingredient.name}
                    </Select.Option>
                ))
            ))}
        </Select>
              
    }

    return (
        <Modal
            title="Agregar merma"
            open={open}
            okText="Agregar"
            cancelText="Cancelar"
            confirmLoading={loading}
            onOk={handleAddShrinkage}
            onCancel={() => setOpen(false)}
            width={800}
        >
            <Row gutter={16}>
                {/* Presentation Selection */}
                <Col span={12}>
                    <Card title="Tipo">
                    <Select
                            placeholder="Seleccione un tipo"
                            value={type}
                            onChange={(value) => {
                                setType(value);
                                setProduct(undefined);
                                setIngredient(undefined);
                            }}
                            style={{ width: "100%" }}
                        >
                            <Select.Option value="product">Producto</Select.Option>
                            <Select.Option value="ingredient">Insumo</Select.Option>
                        </Select>
                        
                    </Card>
                </Col>
                {/* Product Selection */}
                {type === "product" && <Col span={12}>
                    <Card title="Producto">
                        {renderProduct(product)}
                    </Card>
                </Col>}
                {/* Ingredient Selection */}
                {type === "ingredient" && <Col span={12}>
                    <Card title="Insumo">
                        {renderIngredient(product)}
                    </Card>
                </Col>}

                {/* Quantity Input */}
                <Col span={12}>
                    <Card title="Cantidad">
                        <Input
                            type="number"
                            placeholder="Cantidad"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            suffix={type === "ingredient" ? "pz" : "kg"}
                        />
                    </Card>
                </Col>
                
                {/* Reason Input */}
                <Col span={24}>
                    <Card title="Motivo">
                        <Input
                            placeholder="Motivo"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </Card>
                </Col>
            </Row>

        </Modal>
    );
};

export default AddShrinkage;