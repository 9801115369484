import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Input } from "antd";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const libraries = ["places"];
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

const AddressAutocomplete = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState(""); // Stores the input text
  const [structuredData, setStructuredData] = useState(null); // Stores extracted address components
  const inputRef = useRef(null); // Ref for the input field
  const autocompleteRef = useRef(null); // Ref for Google Autocomplete instance

  // Load Google Maps API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // 🔹 Initialize Google Autocomplete when the API is loaded
  useEffect(() => {
    if (isLoaded && inputRef.current && !autocompleteRef.current) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: "mx" }, // Restrict results to Mexico
        fields: ["address_components", "formatted_address", "geometry", "place_id"],
      });

      // Listen for place selection
      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();
        if (place?.formatted_address) {
          setInputValue(place.formatted_address);
          const structured = extractAddressComponents(place);
          setStructuredData(structured);
          onSelect(structured); // Send structured data to the parent component
        }
      });
    }
  }, [isLoaded]);

  // 🔹 Extract structured address components
  const extractAddressComponents = (place) => {
    const components = {};
    if (!place?.address_components) return components;

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) components.streetNumber = component.long_name;
      if (types.includes("route")) components.street = component.long_name;
      if (types.includes("locality")) components.city = component.long_name;
      if (types.includes("sublocality")) components.neighborhood = component.long_name;
      if (types.includes("administrative_area_level_1")) components.state = component.long_name;
      if (types.includes("postal_code")) components.zipCode = component.long_name;
      if (types.includes("country")) components.country = component.long_name;
    });

    return components;
  };

  if (loadError) return <p>Error cargando Google Maps: {loadError.message}</p>;
  if (!isLoaded) return <p>Cargando Google Maps...</p>;

  const handleSelect = async (place) => {
    const geocode = await geocodeByPlaceId(place.value.place_id);
    const latLng = await getLatLng(geocode[0]);
    const { address_components, formatted_address, geometry, place_id } = geocode[0];
    let structured = extractAddressComponents({ address_components, formatted_address });
    structured.description = place.label;
    structured.place_id = place_id;
    structured.lat = latLng.lat;
    structured.lng = latLng.lng;
    structured.extNumber = structured.streetNumber;
    structured.place_id = place.value.place_id;
    onSelect(structured);
  };

  return (
    
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_MAPS_API_KEY}
      selectProps={{
        onChange: handleSelect,
        placeholder: "Buscar dirección",
        loadingMessage: () => "Cargando...",
        noOptionsMessage: () => "No hay resultados",
        styles: {
          control: (provided) => ({
            ...provided,
            width: "100%",
            height: "40px",
            padding: "0 10px",
            borderRadius: "5px",
            border: "1px solid #d9d9d9",
            fontSize: "14px",
            color: "#333",
            boxShadow: "none",
          }),
        },
      }}
      apiOptions={{
        language: 'es',
        region: 'mx',
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['mx'],
        },
      }}
      debounce={1000}
    />
  )

};

export default AddressAutocomplete;