import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button, Modal, Checkbox, Tag, message, Flex, Select } from "antd";
import Parse from "parse";
import Title from "antd/es/typography/Title";

const ConfirmPayment = ({ outpayment, open, setOpen, refresh }) => {
    const [loading, setLoading] = useState(false);
    const [selectedSales, setSelectedSales] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    
    const confirmPayment = async () => {
        const Outpayment = Parse.Object.extend("Commission");
        const outpaymentObject = new Outpayment();
        outpaymentObject.set("objectId", outpayment.objectId);
        outpaymentObject.set("status", "Pagado");
        outpaymentObject.set("paymentDate", new Date());
        outpaymentObject.set("paymentMethod", paymentMethod);
        outpaymentObject.set("payedBy", Parse.User.current());

        try {
            setLoading(true);
            await outpaymentObject.save();
            refresh();
            setLoading(false);
            setOpen(false);
            message.success("Pago confirmado correctamente");
        }
        catch (error) {
            console.error("Error while confirming payment: ", error);
            message.error("Error al confirmar el pago");
        }
    }


    if (!outpayment) return null;
    
    return (
        <Modal
            title="Confirmar Pago"
            open={open}
            onOk={confirmPayment}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={confirmPayment}>
                    Confirmar
                </Button>,
            ]}
        >
            <Flex vertical gap="10px">
                <Title level={3}>Monto a pagar: {`$${outpayment.amount}`}</Title>
                <Title level={4}>Vendedor: {outpayment.seller.fullName}</Title>
                <Select lable='Forma de pago' placeholder='Selecciona una forma de pago' onChange={setPaymentMethod}>
                    <Select.Option value='efectivo'>Efectivo</Select.Option>
                    <Select.Option value='transferencia'>Transferencia</Select.Option>
                    <Select.Option value='cheque'>Cheque</Select.Option>
                </Select>

            </Flex>


                
        </Modal>
    );
}

export default ConfirmPayment;