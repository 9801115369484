import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, FloatButton, Button, DatePicker, Flex, Input, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import NewProductionOrder from "./components/NewProductionOrder";
import Parse from "parse";
import moment from "moment";

const fetchProducts = async (name) => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.matches("name", name, "i");
  const results = await query.find();
  return results;
};

const fetchRecipes = async (name) => {
  const Recipe = Parse.Object.extend("Recipe");
  const query = new Parse.Query(Recipe);
  query.matches("name", name, "i");
  const results = await query.find();
  return results;
};

// Fetch production orders without presentations
const fetchProductionOrders = async (filters) => {
  const ProductionOrder = Parse.Object.extend("ProductionOrder");
  const query = new Parse.Query(ProductionOrder);
  query.include(["recipePointer", "ingredientPointers", "product"]);
  if (filters) {
    if (filters.folio) query.matches("folio", filters.folio, "i");
    if (filters.product) {
      const products = await fetchProducts(filters.product);
      query.containedIn("product", products);
    }
    if (filters.recipe) {
      const recipes = await fetchRecipes(filters.recipe);
      query.containedIn("recipePointer", recipes);
    }
    if (filters.date) {
      const start = moment(filters.date[0].$d).startOf("day").toDate();
      const end = moment(filters.date[1].$d).endOf("day").toDate();
      query.greaterThanOrEqualTo("createdAt", start);
      query.lessThanOrEqualTo("createdAt", end);
    }
  }

  query.descending("createdAt");
  const results = await query.find();
  return results.map((result) => result.toJSON());
};

const columns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
  },
  {
    title: "Folio",
    dataIndex: "folio",
    key: "folio",
    render: (text, order) => (
      <Button
        type="link"
        onClick={() =>
          (window.location.href = `/production/detail/${order.uuid}`)
        }
      >
        {text}
      </Button>
    ),
    sorter: (a, b) => a.folio - b.folio,
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",
    render: (product) => <h3>{product?.name}</h3>,
    sorter: (a, b) => a.product.name.localeCompare(b.product.name),
  },
  {
    title: "Receta",
    dataIndex: "recipePointer",
    key: "recipePointer",
    render: (recipePointer) => <h3>{recipePointer?.name}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "productionQuantity",
    key: "productionQuantity",
    render: (productionQuantity, order) => (
      <h3>
        {productionQuantity} {order?.product?.baseUnit}
      </h3>
    ),
  },
  {
    title: "Progreso",
    dataIndex: "progress",
    key: "progress",
    render: (progress, order) => (
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <div>
          Producido: {order.produced}/{order.productionQuantity}
        </div>
        <div>
          <Progress
           percent={((order.produced / order.productionQuantity) * 100)?.toFixed(2)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    ),
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    render: (status, order) => <h3>{order?.closed ? "Cerrado" : "Abierto"}</h3>,
  }
];

const InventoryDetails = () => {
  const [newProduction, setNewProduction] = useState(false);
  const [productionOrders, setProductionOrders] = useState([]);
  const [filters, setFilters] = useState({});
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    fetchProductionOrders(filters).then((data) => {
      setProductionOrders(data);
    });
  }, [shouldUpdate, filters]);

  const refresh = () => {
    setShouldUpdate(!shouldUpdate);
  };

  const renderFilters = () => {
    return (
      <Flex gap="10px" alignItems="center" style={{ marginBottom: 20 }}>
        <Input 
          placeholder="Folio"
          onChange={(e) => setFilters({ ...filters, folio: e.target.value })}
        />
        <Input
          placeholder="Producto"
          onChange={(e) => setFilters({ ...filters, product: e.target.value })}
        />
        <Input
          placeholder="Receta"
          onChange={(e) => setFilters({ ...filters, recipe: e.target.value })}
        />
        <DatePicker.RangePicker
          style={{ width: 500, height: 40 }}
          onChange={(value) => setFilters({ ...filters, date: value })}
        />
      </Flex>
    );
  }


  return (
    <Row gutter={[24, 24]}>
      <FloatButton onClick={() => setNewProduction(true)} />
      <NewProductionOrder
        open={newProduction}
        setOpen={setNewProduction}
        refresh={refresh}
      />
      <Col span={24}>
        <Flex justifyContent="center" alignItems="center">
          <h2 className="p-15 h2">Órdenes de producción</h2>
          {renderFilters()}
        </Flex>
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: "100%" }}
              dataSource={productionOrders}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default InventoryDetails;