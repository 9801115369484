import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message, Flex, Tabs, Checkbox, Card } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
import AddressAutocomplete from "../../../form/PlacesSelect";
import {setACL} from "../../../../utils/ACL";
import { fiscalRegimesPersonasFisicas, fiscalRegimesPersonasMorales } from "../utils/fiscalRegimes";
import { v4 as uuidv4 } from 'uuid';



const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    let cleaned = value.replace(/\D/g, "");
  
    // Remove leading country code if present (e.g., +52)
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(cleaned.length - 10); // Keep only the last 10 digits
    }
    
    return cleaned
  };
  
const formatWhatsappNumber = (value) => {
    // Remove all non-numeric characters
    let cleaned = value.replace(/\D/g, "");

    return cleaned
};

const formatEmail = (value) => {
    return value?.toLowerCase()?.trim();
};


const ContactPersons = ({ form, setRenderId }) => {
    const [persons, setPersons] = React.useState(form.getFieldValue("contactPersons") || []);
    return (
        <Flex vertical>
            {persons.map((person, index) => (
                <Card title={<Flex justify="space-between"><span>Persona de contacto {index + 1}</span><Button onClick={() => {
                    setPersons(persons.filter((_, i) => i !== index));
                    form.setFieldsValue({ contactPersons: persons.filter((_, i) => i !== index) });
                }}>Eliminar</Button></Flex>}>

                <Flex key={index} vertical>
                    <Form.Item
                        label="Área"
                        name={['contactPersons', index, 'area']}
                        rules={[{ required: true, message: 'El nombre es requerido' }]}
                    >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        label="Nombre"
                        name={['contactPersons', index, 'name']}
                        rules={[{ required: true, message: 'El nombre es requerido' }]}
                    >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        label="Teléfono"
                        name={['contactPersons', index, 'phoneNumber']}
                        rules={[{ required: true, message: 'El teléfono es requerido' },
                        { pattern: /^[0-9]{10}$/, message: 'El teléfono debe tener 10 dígitos' }]}
                    >
                        <Input
                            onChange={(e) => form.setFieldsValue({ [`phoneNumber[${index}]`]: formatPhoneNumber(e.target.value) })}
                            onPaste={(e) => form.setFieldsValue({ [`phoneNumber[${index}]`]: formatPhoneNumber(e.target.value) })}
                            placeholder="(xx) xxx
                            xxxx"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Correo"
                        name={['contactPersons', index, 'email']}
                        rules={[{ required: true, message: 'El correo es requerido' },
                        { type: 'email', message: 'Correo electrónico inválido' }]}
                    >
                        <Input onChange={(e) => form.setFieldsValue({ [`email[${index}]`]: formatEmail(e.target.value) })} placeholder="ejemplo@ejemplo.com" />
                    </Form.Item>
                </Flex>
                </Card>
            ))}
             <Button onClick={() => {
            setPersons([...persons, { area: "", name: "", phoneNumber: "", email: "" }]);
            }}>Agregar persona de contacto</Button>
        </Flex>
    );
};

  
const NewClient = ({ open, setOpen, refresh, client, editing }) => {
    const [form] = Form.useForm();
    const [renderId, setRenderId] = React.useState(0);
    const [clientType, setClientType] = React.useState("physical");
    const [fiscalType, setFiscalType] = React.useState("physical");
    const [genericRFC, setGenericRFC] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (editing && open) {

            setClientType(client.clientType);
            setFiscalType(client.fiscalInformation.personType);

            if (client) form.setFieldsValue({
                email: client.email,
                firstName: client.firstName,
                lastName: client.lastName,
                fullName: client.fullName,
                clientType: client.clientType,
                contactPerson: client.contactPerson,
                phoneNumber: client.phoneNumber,
                contactPersons: client.contactPersons || [],
            });
            if (client?.address) form.setFieldsValue({
                address: client.address?.description,
                street: client.address?.street,
                extNumber: client.address?.extNumber,
                intNumber: client.address?.intNumber,
                zipCode: client.address?.zipCode,
                neighborhood: client.address?.neighborhood,
                city: client.address?.city,
                state: client.address?.state,
                geoPoint: client.address?.geoPoint,
            });
            if (client?.fiscalInformation) form.setFieldsValue({
                fiscalStreet: client.fiscalInformation.address?.street,
                fiscalExtNumber: client.fiscalInformation.address?.extNumber,
                fiscalIntNumber: client.fiscalInformation.address?.intNumber,
                fiscalZipCode: client.fiscalInformation.address?.zipCode,
                fiscalNeighborhood: client.fiscalInformation.address?.neighborhood,
                fiscalCity: client.fiscalInformation.address?.city,
                fiscalState: client.fiscalInformation.address?.state,
                fiscalAddress: client.fiscalInformation.address?.description,
                fiscalGeoPoint: client.fiscalInformation.address?.geoPoint,
                rfc: client.fiscalInformation.rfc,
                fiscalRegime: client.fiscalInformation.fiscalRegime,
                fiscalType: client.fiscalInformation.personType,
                businessName: client.fiscalInformation.businessName,
            });

        } else {
        form.setFieldsValue({
            email: '',
            fistName: '',
            lastName: '',
            businessName: '',
            clientType: 'physical',
            contactPerson: '',
            phoneNumber: '',
            whatsappNumber: '',
            address: '',
            street: '',
            extNumber: '',
            intNumber: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: '',
            geoPoint: '',
            fiscalStreet: '',
            fiscalExtNumber: '',
            fiscalIntNumber: '',
            fiscalZipCode: '',
            fiscalNeighborhood: '',
            fiscalCity: '',
            fiscalState: '',
            fiscalAddress: '',
            fiscalGeoPoint: '',
            rfc: '',
            fiscalRegime: '',
            fiscalType: 'physical',
        });
        }
    }, [open, client, editing, form, renderId]);

    const handleAddressSelect = async (
        addressDetails
      ) => {
        console.log("📍 Selected Place: ", addressDetails);

        form.setFieldsValue({ address: addressDetails.description, ...addressDetails, geoPoint: new Parse.GeoPoint({ latitude: addressDetails.lat, longitude: addressDetails.lng }) });
       
      };

      const handleFiscalAddressSelect = async (
             values
             ) => {
             console.log("📍 Selected Place: ", values);

             if (genericRFC) {
                return;
            }
                     
                 const addressDetails = {
                     fiscalStreet: values.street,
                     fiscalExtNumber: values.extNumber,
                     fiscalNeighborhood: values.neighborhood,
                     fiscalCity: values.city,
                     fiscalState: values.state,
                     fiscalZipCode: values.zipCode,
                     fiscalCountry: values.country,
                     description: values.description,
                    lat: values.lat,    
                    lng: values.lng,
                    fiscalGeoPoint: new Parse.GeoPoint({ latitude: values.lat, longitude: values.lng }),
                    place_id: values.place_id,
                 };
    
     
                 console.log("📍 Final Address Details: ", addressDetails)
                     
                 // Update form fields with extracted address details
                 form.setFieldsValue({ fiscalAddress: addressDetails.description, ...addressDetails });
         };


      

    const moralPerson = () => {
        return <Flex vertical>
        <Form.Item
            label="Nombre"
            name="fullName"
            rules={[{ required: true, message: 'El nombre es requerido' }]}
        >
            <Input placeholder="Nombre" />
        </Form.Item>
        <Form.Item
            label="Persona de contacto"
            name="contactPerson"
            rules={[{ required: true, message: 'La persona de contacto es requerida' }]}
        >
            <Input placeholder="Persona de contacto" />
        </Form.Item>
        </Flex>
    };

    const physicalPerson = () => {
        return <Flex vertical>
            <Form.Item
                label="Nombre"
                name="firstName"
                rules={[{ required: true, message: 'El nombre es requerido' }]}
            >
                <Input placeholder="Nombre" />
            </Form.Item>
            <Form.Item
                label="Apellidos"
                name="lastName"
                rules={[{ required: true, message: 'El apellido es requerido' }]}
            >
                <Input placeholder="Apellidos" />
            </Form.Item>
        </Flex>
    };


// Helper function to handle the creation of an Address
const createAddress = async (addressData) => {
    const Address = Parse.Object.extend("Address");
    const newAddress = new Address();

    console.log("📍 Address Data: ", addressData)

    if (addressData.objectId) {
        console.log("📍 Updating Address: ", addressData.objectId);
        newAddress.set("objectId", addressData.objectId);
    }
    newAddress.set("street", addressData.street);
    newAddress.set("extNumber", addressData.extNumber);
    newAddress.set("intNumber", addressData.intNumber);
    newAddress.set("zipCode", addressData.zipCode);
    newAddress.set("neighborhood", addressData.neighborhood);
    newAddress.set("city", addressData.city);
    newAddress.set("state", addressData.state);
    newAddress.set("country", "México");
    newAddress.set("geoPoint", addressData.geoPoint);
    newAddress.set("description", addressData.description);
    newAddress.set("place_id", addressData.place_id);

    const addressACL = new Parse.ACL();
    addressACL.setPublicReadAccess(true);
    addressACL.setPublicWriteAccess(true);
    newAddress.setACL(addressACL);

    try {
        return await newAddress.save();
    } catch (error) {
        console.error("Error while creating Address: ", error);
        throw error;
    }
};

const createContactPersons = async (contactPersons) => {
    if (!contactPersons) {
        return [];
    }
    const ContactPerson = Parse.Object.extend("ContactPerson");
    const newContactPerson = new ContactPerson();

    console.log("📍 Contact Person Data: ", contactPersons)

    let toSave = [];
    contactPersons.forEach((contactPerson) => {
        if (contactPerson.objectId) {
            console.log("📍 Updating Contact Person: ", contactPerson.objectId)
            newContactPerson.set("objectId", contactPerson.objectId);
        }
        newContactPerson.set("area", contactPerson.area);
        newContactPerson.set("name", contactPerson.name);
        newContactPerson.set("phoneNumber", contactPerson.phoneNumber);
        newContactPerson.set("email", contactPerson.email);
        toSave.push(newContactPerson);
    });

    try {
        return await Parse.Object.saveAll(toSave);
    } catch (error) {
        console.error("Error while creating Contact Persons: ", error);
        throw error;
    }
};

// Helper function to save Fiscal Information
const createFiscalInformation = async ({ values, address, objectId, fiscalAddressObjectId }) => {
    const FiscalInformation = Parse.Object.extend("FiscalInformation");
    const newFiscalInformation = new FiscalInformation();

    console.log("sameAddress", values.sameAddress);

    if (objectId) {
        console.log("📍 Updating Fiscal Information: ", objectId);
        newFiscalInformation.set("objectId", objectId);
    }
    newFiscalInformation.set("rfc", values.rfc);
    newFiscalInformation.set("fiscalRegime", values.fiscalRegime);
    newFiscalInformation.set("personType", values.clientType);
    newFiscalInformation.set("businessName", values.businessName);

    const fiscalInformationACL = new Parse.ACL();
    fiscalInformationACL.setPublicReadAccess(true);
    fiscalInformationACL.setPublicWriteAccess(true);
    newFiscalInformation.setACL(fiscalInformationACL);

    if (genericRFC) {
        return await newFiscalInformation.save();
    }

    try {
        const fiscalAddress = await createAddress({
            street: values.fiscalStreet,
            extNumber: values.fiscalExtNumber,
            intNumber: values.fiscalIntNumber,
            zipCode: values.fiscalZipCode,
            neighborhood: values.fiscalNeighborhood,
            city: values.fiscalCity,
            state: values.fiscalState,
            geoPoint: values.fiscalGeoPoint,
            description: values.fiscalAddress,
            objectId: fiscalAddressObjectId
        });
        newFiscalInformation.set("address", fiscalAddress);
        
        return await newFiscalInformation.save();
    } catch (error) {
        console.error("Error saving Fiscal Information: ", error);
        throw error;
    }
};

// Helper function to save Client
const saveClient = async (client) => {
    const Client = Parse.Object.extend("_User");
    const newClient = new Client();

    if (client.objectId) {
        console.log("📍 Updating Client: ", client.objectId);
        newClient.set("objectId", client.objectId);
    }
    newClient.set("username", client.email);
    newClient.set("password", generateRandomPassword());
    newClient.set("email", client.email);
    newClient.set("firstName", client.firstName?.trim());
    newClient.set("lastName", client.lastName?.trim());
    newClient.set("businessName", client.businessName?.trim());
    newClient.set('fullName', client.fullName?.trim());
    newClient.set("contactPerson", client.contactPerson?.trim());
    newClient.set("clientType", client.clientType);
    newClient.set("phoneNumber", client.phoneNumber);
    newClient.set("whatsappNumber", client.whatsappNumber);
    newClient.set('address', client.address);
    newClient.set('fiscalInformation', client.fiscalInformation);
    newClient.set('contactPersons', client.contactPersons);
    newClient.set('uuid', client.uuid);

    const acl = setACL("Cliente");
    newClient.setACL(acl);

    try {
        const savedClient = await newClient.save();
        const Role = Parse.Object.extend("_Role");
        const query = new Parse.Query(Role);
        query.equalTo("name", "Cliente");
        const role = await query.first();
        role.getUsers().add(savedClient);
        await role.save();
        return savedClient;
    } catch (error) {
        console.error("Error while creating Client: ", error);
        throw error;
    }
};

// Main createClient function orchestrating all helpers
const createClient = async () => {
    const values = form.getFieldsValue();
    setLoading(true);
    try {
        const address = await createAddress({
            street: values.street,
            extNumber: values.extNumber,
            intNumber: values.intNumber,
            zipCode: values.zipCode,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            geoPoint: values.geoPoint,
            description: values.address
        });

        const fiscalInformation = await createFiscalInformation({
            values,
            address
        });

        const contactPersons = await createContactPersons(values.contactPersons);
        console.log("📍 Contact Persons: ", contactPersons);

        const newClient = await saveClient({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: values.fullName || `${values.firstName} ${values.lastName}`,
            contactPerson: values.contactPerson,
            clientType: values.clientType,
            phoneNumber: values.phoneNumber,
            whatsappNumber: values.whatsappNumber,
            fiscalInformation,
            contactPersons,
            address,
            uuid: uuidv4()
        });
        editing ? message.success("Cliente actualizado correctamente") :
        message.success("Cliente agregado correctamente");
        setOpen(false);
        refresh();
        setLoading(false);
    } catch (error) {
        console.error("Error while creating the client process: ", error);
        editing ? message.error("Error al actualizar el cliente") :
        message.error("Error al agregar el nuevo cliente");
        setLoading(false);
    }
};

const updateClient = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    console.log("📍Address objectId", client.address.objectId)

    try {
        const address = await createAddress({
            street: values.street,
            extNumber: values.extNumber,
            intNumber: values.intNumber,
            zipCode: values.zipCode,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            geoPoint: values.geoPoint,
            description: values.address,
            objectId: client.address.objectId
        });

        const fiscalInformation = await createFiscalInformation({ 
            values, 
            address, 
            objectId: client.fiscalInformation.objectId, 
            fiscalAddressObjectId: values.fiscalAddress?.objectId
         });

         const contactPersons = await createContactPersons(values.contactPersons);
         console.log("📍 Contact Persons: ", contactPersons);

        const newClient = await saveClient({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: values.fullName || `${values.firstName} ${values.lastName}`,
            contactPerson: values.contactPerson,
            clientType: values.clientType,
            phoneNumber: values.phoneNumber,
            whatsappNumber: values.whatsappNumber,
            fiscalInformation,
            address,
            contactPersons,
            objectId: client.objectId
        });

        message.success("Cliente agregado correctamente");
        setOpen(false);
        refresh();
        setLoading(false);
    } catch (error) {
        console.error("Error while creating the client process: ", error);
        message.error("Error al agregar el nuevo cliente");
        setLoading(false);
    }
};

const setSameAddress = () => {
    form.setFieldsValue({
        fiscalAddress: form.getFieldValue("address"),
        fiscalStreet: form.getFieldValue("street"),
        fiscalExtNumber: form.getFieldValue("extNumber"),
        fiscalIntNumber: form.getFieldValue("intNumber"),
        fiscalZipCode: form.getFieldValue("zipCode"),
        fiscalNeighborhood: form.getFieldValue("neighborhood"),
        fiscalCity: form.getFieldValue("city"),
        fiscalState: form.getFieldValue("state"),
        fiscalGeoPoint: form.getFieldValue("geoPoint"),
    });
};



    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log(values);
            if (editing) {
                updateClient();
            } else {
                createClient();
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title={editing ? "Editar cliente" : "Nuevo cliente"}
            open={open}
            onCancel={() => setOpen(false)}
            width={800}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Información general" key="1">
                        
                <Form.Item
                    label="Tipo de cliente"
                    name="clientType"
                    rules={[{ required: true, message: 'Tipo' }]}
                    shouldUpdate
                >
                    <Select shouldUpdate onChange={(value) => {
                        if (value === "physical") {
                            form.setFieldsValue({ clientType: "physical" });
                            setClientType("physical");
                        } else {
                            form.setFieldsValue({ clientType: "moral" });
                            setClientType("moral");
                        }
                    }}
                    value={clientType}
                    >
                        <Select.Option value="physical">Persona física</Select.Option>
                        <Select.Option value="moral">Persona moral</Select.Option>
                    </Select>
                </Form.Item>
               {form.getFieldValue("clientType") === "physical" ? physicalPerson() : moralPerson()}
                <Form.Item
                                   label="Teléfono"
                                   name="phoneNumber"
                                   rules={[{ required: true, message: 'El teléfono es requerido' },
                                   { pattern: /^[0-9]{10}$/, message: 'El teléfono debe tener 10 dígitos' }]}
                               >
                                   <Input 
                                       onChange={(e) => form.setFieldsValue({ phoneNumber: formatPhoneNumber(e.target.value) })}
                                       onPaste={(e) => form.setFieldsValue({ phoneNumber: formatPhoneNumber(e.target.value) })}
                                       placeholder="(xx) xxx xxxx"
                                   />
                               </Form.Item>
                               <Form.Item
                                   label="WhatsApp"
                                   name="whatsappNumber"
                                   rules={[{ pattern: /^[0-9]{11,12}$/, message: 'El WhatsApp debe tener 10 dígitos' }]}
                               >
                                   <Input 
                                       onChange={(e) => form.setFieldsValue({ whatsappNumber: formatWhatsappNumber(e.target.value) })}
                                       onPaste={(e) => form.setFieldsValue({ whatsappNumber: formatWhatsappNumber(e.target.value) })}
                                       placeholder="(52) xxx xxxx xxxx"
                                   />
                               </Form.Item>
                               <Form.Item
                                   label="Correo electrónico"
                                   name="email"
                                   rules={[{ required: true, message: 'El correo electrónico es requerido' },
                                   { type: 'email', message: 'Correo electrónico inválido' }]}
                               >
                                   <Input 
                                       onChange={(e) => form.setFieldsValue({ email: formatEmail(e.target.value) })}
                                       placeholder="ejemplo@ejemplo.com"
                                   />
                               </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Contacto" key="2">
                <ContactPersons form={form} setRenderId={setRenderId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Domicilio" key="3">
               <Form.Item label="Dirección" name="address" rules={[{ required: true, message: "Ingresa una dirección" }]}>
                    <AddressAutocomplete onSelect={handleAddressSelect} parentValue={form.getFieldValue("address")} />
                </Form.Item>
                <Form.Item
                    label="Calle"
                    name="street"
                        rules={[{ required: true, message: 'Calle' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Número exterior"
                        name="extNumber"
                        rules={[{ required: true, message: 'Número exterior' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Número interior"
                        name="intNumber"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Código postal"
                        name="zipCode"
                        rules={[{ required: true, message: 'Código postal' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Colonia"
                        name="neighborhood"
                        rules={[{ required: true, message: 'Colonia' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Ciudad"
                        name="city"
                        rules={[{ required: true, message: 'Ciudad' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Estado"
                        name="state"
                        rules={[{ required: true, message: 'Estado' }]}
                    >
                        <Input />
                    </Form.Item>
                </Tabs.TabPane>
               
                <Tabs.TabPane tab="Información fiscal" key="4">
                <Form.Item
                    name="genericRFC"
                >
                     <Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            form.setFieldsValue({ 
                                rfc: "XAXX010101000", 
                                fiscalRegime: { key: "616", value: "616", children: "Sin obligaciones fiscales" },
                                businessName: "PUBLICO EN GENERAL", 
                                fiscalType: "moral" });
                            setGenericRFC(true);
                        } else {
                            form.setFieldsValue({ rfc: undefined, fiscalRegime: undefined, businessName: undefined, fiscalType: "physical" });
                            setGenericRFC(false);
                        }
                    }
                    }>
                        RFC genérico
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label="Tipo de persona"
                    name="fiscalType"
                    rules={[{ required: true, message: 'Tipo de persona' }]}    
                >
                    <Select onChange={(value) => {
                        if (value === "physical") {
                            form.setFieldsValue({ fiscalType: "physical" });
                            form.setFieldsValue({ fiscalRegime: "" });
                            setFiscalType("physical");
                        } else {
                            form.setFieldsValue({ fiscalType: "moral" });
                            form.setFieldsValue({ fiscalRegime: "" });
                            setFiscalType("moral");
                        }
                    }}
                    value={fiscalType}
                    disabled={genericRFC}
                    >
                        <Select.Option value="physical">Persona física</Select.Option>
                        <Select.Option value="moral">Persona moral</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Razón social"
                    name="businessName"
                    rules={[{ required: true, message: 'Razón social' }]}
                >
                    <Input placeholder="Razón social" disabled={genericRFC} />
                </Form.Item>
                 <Form.Item
                    label="RFC"
                    name="rfc"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Ingresa un RFC');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input placeholder="RFC" disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Régimen fiscal"
                    name="fiscalRegime"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Selecciona un régimen fiscal');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Select showSearch onSearch={(value) => {
                        if (form.getFieldValue("fiscalType") === "physical") {
                            return fiscalRegimesPersonasFisicas.filter((regime) => regime.name.toLowerCase().includes(value.toLowerCase()));
                        }
                        return fiscalRegimesPersonasMorales.filter((regime) => regime.name.toLowerCase().includes(value.toLowerCase()));
                    }}
                    disabled={genericRFC}
                    onChange={(value, item) => {
                        console.log("📍 Selected Fiscal Regime: ", item)
                        form.setFieldsValue({ fiscalRegime: { ...item } });
                        }}>
                        {form.getFieldValue("fiscalType") === "physical" ? fiscalRegimesPersonasFisicas.map((regime) => (
                            <Select.Option key={regime.value} value={regime.value}>{regime.name}</Select.Option>
                        )) : fiscalRegimesPersonasMorales.map((regime) => (
                            <Select.Option key={regime.value} value={regime.value}>{regime.name}</Select.Option>
                        ))}
                        
                    </Select>
                </Form.Item>
                {!genericRFC && <>
                <Button onClick={() => setSameAddress()} disabled={genericRFC}>
                    Usar el mismo domicilio
                </Button>
                <Flex vertical>
                <Form.Item
                    label="Dirección fiscal"
                    name="fiscalAddress"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Ingresa una dirección');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <AddressAutocomplete onSelect={handleFiscalAddressSelect} parentValue={form.getFieldValue("fiscalAddress")} disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Calle"
                    name="fiscalStreet"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Calle');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Número exterior"
                    name="fiscalExtNumber"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Número exterior');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Número interior"
                    name="fiscalIntNumber"
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Código postal"
                    name="fiscalZipCode"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Código postal');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Colonia"
                    name="fiscalNeighborhood"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Colonia');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Ciudad"
                    name="fiscalCity"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Ciudad');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                <Form.Item
                    label="Estado"
                    name="fiscalState"
                    rules={[{ validator: (rule, value) => {
                        if (!value && !genericRFC) {
                            return Promise.reject('Estado');
                        }
                        return Promise.resolve();
                    } }]}
                >
                    <Input disabled={genericRFC} />
                </Form.Item>
                </Flex>
                </> }


                </Tabs.TabPane>
                </Tabs> 
            </Form>
        </Modal>
    );
}

export default NewClient;
