import React, { useEffect, useState } from "react";
import { Row, Col, Button, Steps, Progress, message, Typography, Divider, Space, Card } from "antd";
import Parse from "parse";
import { useParams } from "react-router-dom";
import ProductionSummary from "./Tabs/ProductionSummary";
import {
    ForageAdding,
    Greasing,
    Packaging,
    Shrinkage,
    Reprocessing
} from "./Tabs";
import { Printer } from "react-bootstrap-icons";

// Fetch production data
const fetchProduction = async (uuid) => {
    const Production = Parse.Object.extend("ProductionOrder");
    const query = new Parse.Query(Production);
    query.equalTo("uuid", uuid);
    query.include([
        "product",
        "product.presentations",
        "recipePointer",
        "ingredientPointers",
        "productionEvents",
        "shrinkage",
        "closedBy",
    ]);
    const result = await query.first();
    return result?.toJSON();
};

// Custom Button with Hover Label
const IconButton = ({ icon, children, ...props }) => {
    const [showLabel, setShowLabel] = useState(false);
    return (
        <Button
            {...props}
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s ease",
            }}
            onMouseEnter={() => setShowLabel(true)}
            onMouseLeave={() => setShowLabel(false)}
        >
            {icon}
            {showLabel && <span>{children}</span>}
        </Button>
    );
};

// Progress Calculations
const calculateProgress = (production, stepIndex) => {
    if (!production) return 0;

    switch (stepIndex) {
        case 0:
            return (
                (production?.grainsAdded / production?.totalGrains) * 33 +
                (production?.fatAdded / production?.totalFat) * 33
            );
        case 1:
            return (production?.grainsAdded / production?.totalGrains) * 100;
        case 2:
            return (production?.fatAdded / production?.totalFat) * 100;
        case 3:
            return (production?.colors ? 33 : 0) + 
                   (production?.finalWeight ? 33 : 0) + 
                   (production?.retrievedFinePowders ? 34 : 0);
        default:
            return 0;
    }
};

// Step Definitions
const steps = [
    { title: "Resumen", content: ProductionSummary },
    { title: "Forrajes", content: ForageAdding },
    { title: "Engrasado", content: Greasing },
    { title: "Envasado", content: Packaging },
    { title: "Merma", content: Shrinkage },
    { title: "Reprocesos", content: Reprocessing },
];

const ProductionDetails = () => {
    const { id } = useParams();
    const [current, setCurrent] = useState(0);
    const [production, setProduction] = useState(null);
    const [printing, setPrinting] = useState(false);

    const print = async () => {
        setPrinting(true);
        const params = {
            orderNumber: production.folio,
            date: production.createdAt,
            createdBy: production.createdBy?.fullName || "N/A",
            product: production.product,
            quantity: production.productionQuantity,
            recipe: production.recipe,
            useCardStyle: true,
        };

        try {
            const data = await Parse.Cloud.run("printProductionOrder", params);
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        } catch (error) {
            console.error("Error opening PDF:", error);
            message.error({
                message: "Error",
                description: "Permite las ventanas emergentes para este sitio e intenta de nuevo",
            });
        } finally {
            setPrinting(false);
        }
    };

    const refresh = () => {
        fetchProduction(id).then(setProduction);
    };

    useEffect(() => {
        fetchProduction(id).then((result) => {
            if (!result) return;

            const ingredients = {};
            result.recipe.ingredients.forEach((ingredient) => {
                ingredients[ingredient.objectId] = {
                    ...ingredient,
                    added: 0,
                    total: ingredient.quantity * result.productionQuantity,
                };
            });

            result.production?.forEach((prod) => {
                prod?.ingredients?.forEach((ingredient) => {
                    if (ingredient.quantity && ingredient.quantity !== "0") {
                        ingredients[ingredient.objectId].added =
                            (ingredients[ingredient.objectId]?.added || 0) +
                            parseFloat(ingredient.quantity);
                    }
                });
            });

            const grainsAdded = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Forrajes")
                .reduce((sum, ing) => sum + ing.added, 0);

            const fatAdded = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Producción")
                .reduce((sum, ing) => sum + ing.added, 0);

            const totalGrains = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Forrajes")
                .reduce((sum, ing) => sum + ing.quantity, 0) * result.productionQuantity;

            const totalFat = Object.values(ingredients)
                .filter((ing) => ing.warehouse === "Producción")
                .reduce((sum, ing) => sum + ing.quantity, 0) * result.productionQuantity;

            setProduction({
                ...result,
                grainsAdded,
                fatAdded,
                totalGrains,
                totalFat,
                ingredients: Object.values(ingredients),
            });
        });
    }, [id]);

    const handleStepChange = (newStep) => {
        setCurrent(newStep);
    };

    const CurrentComponent = steps[current].content;

    return (
        <div style={{ padding: "20px" }}>
            <Row justify="space-between" align="middle">
                <Col>
                    <Typography.Title level={3}>Detalles de Producción</Typography.Title>
                    <Typography.Text strong>{production?.product?.name}</Typography.Text>
                    <Typography.Text type="secondary">{production?.folio}</Typography.Text>
                </Col>
                <Col>
                    <IconButton icon={<Printer />} onClick={print} loading={printing}>
                        Imprimir
                    </IconButton>
                </Col>
            </Row>

            <Divider />

            <Row gutter={24}>
                <Col span={16}>
                    <Card>
                        <CurrentComponent production={production} refresh={refresh} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Steps
                            current={current}
                            onChange={handleStepChange}
                            direction="vertical"
                        >
                            {steps.map((step, index) => (
                                <Steps.Step
                                    key={index}
                                    title={
                                        <Space>
                                            <span>{step.title}</span>
                                            <Progress
                                                percent={calculateProgress(production, index)}
                                                format={() => ""}
                                                style={{ width: "4rem" }}
                                            />
                                        </Space>
                                    }
                                />
                            ))}
                        </Steps>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ProductionDetails;