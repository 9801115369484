import Parse from "parse";
import { message } from "antd";

// Helper to ensure a value is numeric
const ensureNumber = (value) => (isNaN(parseFloat(value)) ? 0 : parseFloat(value));


const updateProductionOrder = async ({ production, productionEvent, quantity }) => {
    if (!production || !productionEvent) {
        throw new Error("Falta información: producción o evento de producción.");
    }

    console.log(`Actualizando orden de producción ID: ${production.objectId}, Cantidad: ${quantity}`);

    try {
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const query = new Parse.Query(ProductionOrder);
        const productionOrder = await query.get(production.objectId);

        if (!productionOrder) {
            throw new Error(`Orden de producción no encontrada para ID: ${production.objectId}`);
        }

        const previousEvents = productionOrder.get("productionEvents") || [];
        productionOrder.set("productionEvents", [
            ...previousEvents,
            { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
        ]);

        await productionOrder.save();
        console.log(`Orden de producción actualizada correctamente: ${production.objectId}`);
    } catch (error) {
        console.error("Error actualizando la orden de producción:", error);
        throw error;
    }
};

// Centralized addShrinkage function
const addReprocessing = async ({ production, quantity }) => {
    if (!production || !quantity) {
        message.error("Por favor, complete todos los campos obligatorios.");
        return;
    }

    const ProductionEvents = Parse.Object.extend("ProductionEvents");

    try {
        // Crear el evento de producción
        const productionEvent = new ProductionEvents();
        productionEvent.set("event", "addReprocessing");
        productionEvent.set("production", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        productionEvent.set("quantity", ensureNumber(quantity));

        await productionEvent.save();
        console.log(`Evento de producción guardado con ID: ${productionEvent.id}`);

        // Actualizar orden de producción
        await updateProductionOrder({ production, productionEvent, quantity });

        message.success("Reprocesamiento registrado correctamente.");
    } catch (error) {
        console.error("Error registrando reprocesamiento:", error);
        message.error("Error al registrar reprocesamiento. Por favor, intente nuevamente.");
        throw error;
    }
};

// Centralized addShrinkage function
const retrieveReprocessing = async ({ production, quantity }) => {
    if (!production || !quantity) {
        message.error("Por favor, complete todos los campos obligatorios.");
        return;
    }

    const ProductionEvents = Parse.Object.extend("ProductionEvents");

    try {
        // Crear el evento de producción
        const productionEvent = new ProductionEvents();
        productionEvent.set("event", "retrieveReprocessing");
        productionEvent.set("production", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        productionEvent.set("quantity", ensureNumber(quantity));

        await productionEvent.save();
        console.log(`Evento de producción guardado con ID: ${productionEvent.id}`);

        // Actualizar orden de producción
        await updateProductionOrder({ production, productionEvent, quantity });

        message.success("Reprocesamiento registrado correctamente.");
    } catch (error) {
        console.error("Error registrando reprocesamiento:", error);
        message.error("Error al registrar reprocesamiento. Por favor, intente nuevamente.");
        throw error;
    }
};

export { addReprocessing, retrieveReprocessing };