import Parse from "parse";
import React, { createContext, useEffect, useState } from "react";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(null);
    const [loadingSettings, setLoadingSettings] = useState(true);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const query = new Parse.Query("Settings");
            const results = await query.find();
            setSettings(results.map((setting) => setting.toJSON()));
        } catch (error) {
            console.error("Error fetching settings:", error);
        } finally {
            setLoadingSettings(false);
        }
    };

    return (
        <SettingsContext.Provider value={{ settings, loadingSettings, setSettings }}>
            {children}
        </SettingsContext.Provider>
    );
}

export { SettingsContext, SettingsProvider };