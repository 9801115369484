import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Switch, Statistic, Progress, Button } from "antd";
import Parse from "parse";
import moment from "moment";
import CalculateCommissions from "./CalculateCommissions";


const fetchSellerSales = async (sellerId) => {
  const Sales = Parse.Object.extend("Sales");
  const query = new Parse.Query(Sales);
  query.equalTo("seller", { __type: "Pointer", className: "_User", objectId: sellerId });
  query.equalTo("status", "Venta");
  query.include(["client", "seller", "payments", "outlets", "comments", "client.address"]);
  query.descending("createdAt");
  const results = await query.find();
  return results.map((result) => result.toJSON());
};


const SellerSales = ({ seller, refresh }) => {
  const [sales, setSales] = useState([]);
  const [calculateCommissions, setCalculateCommissions] = useState(false);


  useEffect(() => {
    const fetchSales = async () => {
      const sales = await fetchSellerSales(seller.objectId);
      console.log("📦 Sales: ", sales);
      setSales(sales);
    };
    fetchSales();
  }, [seller]);


  const StatisticsCard = () => {
    const thisMontSales = sales.filter((sale) => moment(sale.authorizationDate?.iso).format("MM") === moment().format("MM"));
    const totalSales = thisMontSales.reduce((acc, curr) => acc + curr.total, 0);
    const percent = (totalSales / seller?.salesGoal) * 100;

    return (
      <Card title="Estadísticas" style={{ width: "100%" }}>
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title="Objetivo"
              value={seller?.salesGoal}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Ventas del mes"
              value={sales?.reduce((acc, curr) => acc + curr.total, 0)}
              valueStyle={{ color: '#3f8600' }}
              prefix="$"
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Avances en Ventas"
              value={percent}
              precision={2}
              suffix="%"
            />
            <Progress percent={parseFloat(percent).toFixed(2)} />
          </Col>
        </Row>
      </Card>
    );
  };

  const columns = [
   
    {
      title: "Folio de venta",
      dataIndex: "saleNumber",
      key: "saleNumber",
      render: (text) => <h3>{text}</h3>,
      sorter: (a, b) => a.saleNumber - b.saleNumber,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (client) => <h3>{client.fullName}</h3>,
    },
    {
      title: "Fecha de autorización",
      dataIndex: "authorizationDate",
      key: "authorizationDate",
      render: (date) => <h3>{moment(date?.iso).format("DD/MM/YYYY")}</h3>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Pagado",
      dataIndex: "totalPaid",
      key: "totalPaid",
      render: (text) => <h3>{text}</h3>,
    },
    {
      title: "Saldo",
      dataIndex: "balance",
      key: "balance",
      render: (text, sale) => <h3>{sale.total - sale.totalPaid}</h3>,
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      render: (text, sale) => sale.total === sale.totalPaid ? <h3>Pagado</h3> : <h3>Pendiente</h3>
    },
    {
      title: "Calculada",
      dataIndex: "commissionCalculated",
      key: "commissionCalculated",
      render: (commissionCalculated) => commissionCalculated ? <h3>Si</h3> : <h3>No</h3>,
      sorter: (a, b) => a.commissionCalculated - b.commissionCalculated,
    }
    
  ];

  return (
    <Col>
    <CalculateCommissions seller={seller} sales={sales} open={calculateCommissions} setOpen={setCalculateCommissions} refresh={refresh} />
      <Row>
        <StatisticsCard />
      </Row>
      <Row>
        <Col span={24} lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h2>Ventas</h2>}
          >
            <div className="ant-list-box table-responsive">
              <Table
                style={{ width: '100%' }}
                dataSource={sales}
                columns={columns}
                rowKey="key"
                pagination={true}
                footer={() => (
                  <Button type="primary" style={{ float: 'right' }} onClick={() => setCalculateCommissions(true)}>Calcular Comisión</Button>
                )}
            
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export { SellerSales };
