import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Statistic, FloatButton, Button } from "antd";
import { useNavigate } from "react-router-dom";
import NewProduct from "../modals/NewProduct.js";
import { fetchProducts } from "../services/index.js";

const { Title } = Typography;

const ProductInventory = () => {
  const [newProductModal, setNewProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts().then((results) => setProducts(results));
  }, [shouldRefresh]);

  const refresh = () => setShouldRefresh(!shouldRefresh);

  const totalValueSum = () =>
    products.reduce((acc, product) => acc + product.presentations.reduce((sum, item) => sum + (item.minPrice || 0) * (item.stock || 0), 0), 0);

  const columns = [
    {
      title: "Producto",
      dataIndex: "name",
      render: (text, product) => <Button type="link" onClick={() => navigate(`/inventory/product-details/${product.uuid}`)}>{text}</Button>,
    },
    {
      title: "Stock envasado",
      render: (_, product) => <p>{product.presentations.reduce((sum, item) => sum + item.stock, 0)} pz</p>,
    },
    {
      title: "Stock a granel",
      dataIndex: "stock",
      render: (text) => <p>{(text || 0).toFixed(2)} kg</p>,
    },
    {
      title: "Volúmen total",
      render: (_, product) => (
        <p>{product.presentations.reduce((sum, item) => sum + item.stock * item.content, 0).toFixed(2)} kg</p>
      ),
    },
  ];

  return (
    <div className="layout-content">
      <FloatButton onClick={() => setNewProductModal(true)} />
      <NewProduct open={newProductModal} setOpen={setNewProductModal} refresh={refresh} />
      <Row>
        <Col lg={24}>
          <h2>Inventario de productos</h2>
          <Card bordered={false} title="Productos">
            <Table
              dataSource={products}
              columns={columns}
              rowKey="uuid"
              pagination
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    dataSource={record.presentations}
                    columns={[
                      { title: "Presentación", dataIndex: "name" },
                      { title: "Stock", dataIndex: "stock", render: (text) => `${text} pz` },
                      { title: "Precio mínimo", dataIndex: "minPrice", render: (text) => `$${text}` },
                    ]}
                    rowKey="uuid"
                    pagination={false}
                  />
                ),
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card title="Valor en $" bordered={false}>
            <Statistic title="Total" value={totalValueSum().toFixed(2)} prefix="$" />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductInventory;