import React, { useEffect, useState, useContext, useCallback } from "react";
import { Card, Col, Row, Table, Typography, FloatButton, Flex, Tag, Tooltip, Input, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import moment from "moment";
import MaintenanceEventDetail from "./components/MaintenanceEventDetail";
import AddMaintenanceEvent from "./components/AddMaintenanceEvent";
import Parse from "parse";

const { Title } = Typography;

const MaintenanceList = () => {
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [filters, setFilters] = useState({});
  const { permissions } = useContext(UserContext);

const fetchMaintenance = useCallback(async () => {
    const query = new Parse.Query("Maintenance");
    query.include("user");
    console.log("📦 Filters:", filters);
    const startDate = moment(filters.date?.[0].$d).startOf("day").toDate();
    const endDate = moment(filters.date?.[1].$d).endOf("day").toDate();
    query.greaterThanOrEqualTo("createdAt", startDate);
    query.lessThanOrEqualTo("createdAt", endDate);
    if (filters.device) {
      query.matches("device", filters.device, "i");
    }
    query.descending("createdAt");
    const results = await query.find();
    console.log("📦 Maintenance results:", results);
    if (results) {
      setEvents(results?.map((event) => event.toJSON()));
    }
  }, [filters]);

  useEffect(() => {
    fetchMaintenance();
  }, [filters]);
  


  const columns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      showSorterTooltip: false,
    },
    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio",
      render: (text, event) => <a
        onClick={() => {
          setEvent(event);
          setShowEventDetail(true);
        }}
      >{text}</a>,
        
    },
    {
      title: "Equipo",
      dataIndex: "device",
      key: "device",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Reportado por",
      dataIndex: "user",
      key: "user",
      render: (client) => <p>{client?.fullName}</p>,
    },

  ];

  const renderFilters = () => (
    <Flex justify="flex-end" gap={10} className="mb-24">

      <Input  
        placeholder="Buscar por equipo"
        style={{ width: 200 }}
        value={filters.device}
        onChange={(e) => setFilters({ ...filters, device: e.target.value })}
        allowClear
      />


      <DatePicker.RangePicker
        style={{ width: 300 }}
        value={filters.date}
        onChange={(value) => setFilters({ ...filters, date: value })}
      />
    </Flex>
  );

  return (
    <div className="layout-content">
      <AddMaintenanceEvent open={showAddEvent} setOpen={setShowAddEvent} fetchMaintenance={fetchMaintenance} refresh={fetchMaintenance} />
      <MaintenanceEventDetail event={event} open={showEventDetail} setOpen={setShowEventDetail} />
           <FloatButton
        style={{ right: 20, bottom: 20 }}
        tooltip="Nuevo evento"
        icon={<PlusOutlined />}
        onClick={() => setShowAddEvent(true)}
      />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Mantenimiento</h2>
          {renderFilters()}
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <Table
              dataSource={events}
              columns={columns}
              rowKey={(record) => record.objectId}
              pagination={true}
             
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MaintenanceList;