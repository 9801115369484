import React, { useEffect, useState } from "react";
import { Button, InputNumber, Input, Space, Typography, Modal, Form, DatePicker, Select, message} from "antd";
import Parse from "parse";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchSuppliers } from "../../suppliers/utils/query";
const { Title } = Typography;



const NewPayment = ({open, setOpen, object, parseClass, refresh}) => {
    const [form] = Form.useForm();
    const [disableSave, setDisableSave] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, []);

    const savePayment = async () => {
        try {
            // Validate form fields
            await form.validateFields();
            const values = form.getFieldsValue();
            console.log("💸 Payment: ", values, object, parseClass);
    
            // Initialize Parse Object for Payment
            const payment = await preparePayment(values);
    
            // Save payment object and update related Parse objects
            await payment.save();
    
            // Save updated index if a new payment number was generated
        
            const index = await updateIndex();
            await index.save();
    
            // Provide user feedback
            refresh();
            message.success('Pago creado correctamente');
            setOpen(false);
        } catch (error) {
            console.error('Error while creating Payment: ', error);
            message.error('Error al crear el pago');
        }
    };
    
    // Function to prepare the payment object
    const preparePayment = async (values) => {
        const Payment = Parse.Object.extend('Outpayments');
        const payment = new Payment();

        console.log("Supplierrr: ", values.supplier);
    
        // Generate new payment number
        const { paymentNumber, index } = await generatePaymentNumber();
        payment.set("paymentNumber", paymentNumber);
        payment.set('uuid', uuidv4());
        payment.set('supplier', { __type: 'Pointer', className: '_User', objectId: values.supplier.key });
    
        // Set common payment fields
        payment.set('amount', parseFloat(values.amount));
        payment.set('method', values.paymentType);
        payment.set('notes', values.notes);
        
        
        payment.set('category', values.category);     
        payment.set('pending', false);
        payment.set('status', 'Pagado');
        payment.set('paymentDate', moment(values.paymentDate.$d).toDate());
    
    
        return payment;
    };
    
    // Function to generate a new payment number
    const generatePaymentNumber = async () => {
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'Outpayment');
        query.equalTo('year', new Date().getFullYear());
    
        const index = await query.first();
        const paymentNumber = 'P' + (index.get('value') + 1).toString().padStart(4, '0') + '-' + new Date().getFullYear();
        index.increment('value', 1);
    
        // Return both the payment number and the updated index
        return { paymentNumber, index };
    };
    
    // Function to update the index object
    const updateIndex = async () => {
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'Payment');
        query.equalTo('year', new Date().getFullYear());
    
        const index = await query.first();
        if (!index) {
            throw new Error('Index not found for Payments');
        }
    
        index.increment('value', 1);
        return index;
    };
    
    
    const title = () => {
        return 'Nuevo gasto';
    };


    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
    >
        <Title level={4}>{title()}</Title>
        <Form
            form={form}
            layout="vertical"
            onFinish={savePayment}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item label="Proveedor" name="supplier" rules={[{ required: true, message: "Selecciona un proveedor" }]}>
            <DebounceSelect
                allowClear
                showSearch
                fetchOptions={fetchSuppliers}
                onSearch={fetchSuppliers}
                placeholder="Selecciona un proveedor"
                onChange={(value) => form.setFieldsValue({ supplier: value })}
                style={{ width: "100%" }}
            />
            </Form.Item>
                <Form.Item
                    label="Fecha de pago"
                    name="paymentDate"
                    rules={[{ required: true, message: "Por favor, ingrese la fecha de pago" }]}
                >
                    <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Monto"
                    name="amount"
                    rules={[{ required: true, message: "Por favor, ingrese el monto del pago" }]}
                >
                    <InputNumber style={{ width: "100%" }} prefix="$" formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                </Form.Item>
                <Form.Item
                    label="Tipo de pago"
                    name="paymentType"
                    rules={[{ required: true, message: "Por favor, seleccione el tipo de pago" }]}
                >
                    <Select style={{ width: "100%" }}>
                        <Select.Option value="Efectivo">Efectivo</Select.Option>
                        <Select.Option value="Transferencia">Transferencia</Select.Option>
                        <Select.Option value="Cheque">Cheque</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Categoría"
                    name="category"
                    rules={[{ required: true, message: "Por favor, seleccione la categoría del pago" }]}
                >
                    <Select style={{ width: "100%" }}>
                        <Select.Option value="Mantenimiento">Mantenimiento</Select.Option>
                        <Select.Option value="Luz">Luz</Select.Option>
                        <Select.Option value="Servicios digitales">Servicios digitales</Select.Option>
                        <Select.Option value="Marketing">Marketing</Select.Option>
                        <Select.Option value="Combustibles">Combustibles</Select.Option>
                        <Select.Option value="Otros">Otros</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Observaciones"
                    name="notes"
                >
                    <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                        <Button type="primary" htmlType="submit" disabled={disableSave}>Guardar</Button>
                    </Space>
                </Form.Item>
            </Space>
        </Form>
    </Modal>
}

export default NewPayment;