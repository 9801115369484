import React, { useEffect, useState } from "react";
import { Button, Space, Table, Badge, message, Image, Result, Spin, Form, Flex } from "antd";
import CameraModal from "../../../media/Camera"
import SignCanvas from "../../../media/SignCanvas";
import Parse from "parse/lib/browser/Parse";
import dasner_logo_black from '../../../images/dasner_logo_black.png';


const ClientSignature = () => {
    const [form] = Form.useForm();
    const [outlet, setOutlet] = useState(null);
    const [clientImages, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [clientSignature, setClientSignature] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    /**
     * Fetch outlet details by UUID.
     * @param {string} outletId 
     */
    const fetchOutlet = async (outletId) => {
        try {
            setLoading(true);
            const WarehouseOutlet = Parse.Object.extend("WarehouseOutlet");
            const query = new Parse.Query(WarehouseOutlet);
            query.include(["sale", "sale.client"]);
            query.equalTo("uuid", outletId);

            const result = await query.first();
            if (result) {
                const outletData = result.toJSON();
                setOutlet(outletData);
                setImages(outletData.clientImages || []);
                setClientSignature(outletData.clientSignature);
                setDisabled(!!outletData.clientSignature);
                setLoading(false);
            } else {
                setError(true);
                message.error("Orden de salida no encontrada.");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching outlet:", error);
            if (error.code === 209) {
                Parse.User.logOut();
                window.location.reload();
            }
            message.error("Error al cargar la orden de salida.");
            setError(true);
            setLoading(false);
        }
    };

    /**
     * Prepare and save WarehouseOutlet data.
     */
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const outletObject = await prepareWarehouseOutlet(values);

            await outletObject.save();
            message.success("Información de entrega guardada correctamente.");
            setDisabled(true);
            fetchOutlet(outlet.uuid); // Refresh outlet data.
        } catch (error) {
            console.error("Error saving warehouse outlet:", error);
            message.error("Error al guardar la información de entrega.");
        }
    };

    /**
     * Prepare WarehouseOutlet object.
     * @param {Object} values 
     * @returns {Parse.Object}
     */
    const prepareWarehouseOutlet = async (values) => {
        const WarehouseOutlet = Parse.Object.extend("WarehouseOutlet");
        const outletObject = new WarehouseOutlet();

        outletObject.set("objectId", outlet.objectId);
        outletObject.set("clientImages", clientImages);
        outletObject.set("clientSignature", clientSignature);
        outletObject.set("deliveryDate", new Date());
        outletObject.set("status", "Entregado");
        outletObject.set("receivedBy", values.receivedBy || "Cliente");

        const updatedProducts = outlet.products.map((product) => ({
            ...product,
            delivered: parseInt(product.delivered, 10),
        }));
        outletObject.set("products", updatedProducts);

        return outletObject;
    };

    /**
     * Delete an image by index.
     * @param {number} index 
     */
    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    /**
     * Load outlet data on component mount.
     */
    useEffect(() => {
        const outletId = window.location.href.split("/").pop(); // Extract ID from URL.
        fetchOutlet(outletId);
    }, []);

    if (loading) {
        return (
            <Flex vertical align="center" justify="center" padding={20}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} />
                <Spin size="large" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Flex vertical align="center" justify="center" padding={20}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} />
                <Result
                    status="error"
                    title="Error"
                    subTitle="Ocurrió un error al cargar la orden de salida."
                />
            </Flex>
        );
    }

    // if (outlet?.status === "Entregado") {
    //     return (
    //         <Flex vertical align="center" justify="center" padding={20}>
    //             <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} />
    //             <Result
    //                 status="success"
    //                 title="Orden entregada"
    //                 subTitle="Esta orden de salida ya ha sido entregada."
    //             />
    //         </Flex>
    //     );
    // }

    if (outlet?.status === "Cancelado") {
        return (
            <Flex vertical align="center" justify="center" padding={20}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} />
                <Result
                    status="error"
                    title="Orden cancelada"
                    subTitle="Esta orden de salida ha sido cancelada."
                />
            </Flex>
        );
    }

    return (
        <Space direction="vertical" style={{ width: "100%", padding: 20 }} justify="center" align="space-around">
            <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} />
            <h2>Venta: {outlet?.sale?.saleNumber}</h2>
            <h3>Orden de salida: {outlet?.orderNumber}</h3>
            <h3>Cliente: {outlet?.sale?.client?.fullName}</h3>
            <h3>Lugar de entrega: {outlet?.sale?.deliveryAddress?.address}</h3>
            <h3>Notas de entrega: {outlet?.sale?.deliveryNotes}</h3>
            {outlet?.sale?.notes && <h4>Notas: {outlet.sale.notes}</h4>}

            <Table
                dataSource={outlet?.products}
                rowKey={(record) => record.objectId}
                pagination={false}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, record) => `${text}`,
                    },
                ]}
            />

            <Space direction="horizontal" wrap>
                {clientImages.map((image, index) => (
                    !disabled ? <Badge
                        key={index}
                        title="Eliminar"
                        count="X"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeImage(index)}
                    >
                        <Image
                            src={image}
                            alt={`Foto ${index}`}
                            style={{ width: 90, height: 160, objectFit: "cover" }}
                        />
                    </Badge> :
                        <Image
                            key={index}
                            src={image}
                            alt={`Foto ${index}`}
                            style={{ width: 90, height: 160, objectFit: "cover" }}
                        />
                    
                ))}
            </Space>

            <CameraModal
                open={openCamera}
                setOpen={setOpenCamera}
                onImageTook={setImages}
            />

            {clientSignature && (
                <Space direction="vertical">
                    <h3>Firma del cliente</h3>
                    <Image
                        src={clientSignature?.image}
                        alt={clientSignature?.name}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                    />
                    <p>{clientSignature?.name}</p>
                </Space>
            )}

            <SignCanvas
                open={openSign}
                setOpen={setOpenSign}
                onSave={(image, name) => setClientSignature({ image, name })}
            />

            <Space>
                <Button
                    disabled={disabled}
                    type="primary"
                    onClick={() => setOpenCamera(true)}
                >
                    Agregar foto
                </Button>
                <Button
                    disabled={disabled}
                    type="primary"
                    onClick={() => setOpenSign(true)}
                >
                    Firma del cliente
                </Button>
                <Button
                    disabled={disabled}
                    type="primary"
                    onClick={handleSubmit}
                >
                    Guardar
                </Button>
            </Space>
        </Space>
    );
};

export default ClientSignature;