import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message, Image, InputNumber } from 'antd';
import CameraModal from '../../../media/Camera';
import SignCanvas from '../../../media/SignCanvas';
import Parse from 'parse/lib/browser/Parse';

const NewInventorysale = ({ open, setOpen, outletInfo }) => {
    const [form] = Form.useForm();
    const [outlet, setOutlet] = useState(outletInfo);
    const [clientImages, setClientImages] = useState([]);
    const [carrierImages, setCarrierImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [clientSignature, setClientSignature] = useState(null);
    const [carrierSignature, setCarrierSignature] = useState(null);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            // Catch any errors and prevent saving
            if (!values) return;
        } catch (error) {
            console.error("Validation failed:", error);
            return;
        }

        try {
            const values = form.getFieldsValue();
            // Step 2: Prepare objects for saving
            const outletObject = await prepareWarehouseOutlet(values);
            const productObjects = prepareProductUpdates(outlet.products);
            const stockMovements = await prepareStockMovements(outlet.products, outletObject);
    
            // Step 3: Save all objects
            await saveWarehouseOutletAndProducts(outletObject, productObjects, stockMovements);
    
            // Step 4: Notify success and close form
            message.success("Salida de almacén creada correctamente");
            setOpen(false);
        } catch (error) {
            console.error("Error al crear la salida de almacén:", error);
            message.error("Error al crear la salida de almacén");
        }
    };
    
    // Function to prepare the WarehouseOutlet object
    const prepareWarehouseOutlet = async (values) => {
        
        const WarehouseOutlet = Parse.Object.extend("WarehouseOutlet");
        const outletObject = new WarehouseOutlet();
    
        outletObject.set("objectId", outlet.objectId);
        outletObject.set("deliveredBy", Parse.User.current());
        outletObject.set("clientImages", clientImages);
        outletObject.set("carrierImages", carrierImages);
        outletObject.set("clientSignature", clientSignature);
        outletObject.set("carrierSignature", carrierSignature);
        outletObject.set("deliveryDate", new Date());
        outletObject.set("status",  outlet?.deliveryPlace === 'Pie de fábrica' ? "Entregado" : "En ruta");
        outletObject.set("receivedBy", outlet?.deliveryPlace === 'Pie de fábrica' ? 'Cliente' : 'Transportista');
        const vehicleInfo = {
            vehicle: values.vehicle,
            plates: values.plates,
            driver: values.driver,
            carrier: values.carrier,
        }
        outletObject.set("lots", values.lots);
        outletObject.set("vehicleInfo", vehicleInfo);
        outletObject.set("comments", values.comments);

        outletObject.set(
            "products",
            outlet.products.map((product) => ({
                ...product,
                delivered: parseInt(product.delivered),
            }))
        );
    
        return outletObject;
    };
    
    // Function to prepare product updates (decrement stock)
    const prepareProductUpdates = (products) => {
        const Presentation = Parse.Object.extend("Presentation");
    
        return products.map((product) => {
            const productObject = new Presentation();
            productObject.set("objectId", product?.presentationId);
            productObject.decrement("stock", parseInt(product.delivered));
            return productObject;
        });
    };
    
    // Function to create StockMovement objects for each product
    const prepareStockMovements = async (products, outletObject) => {
        const StockMovement = Parse.Object.extend("StockMovement");
        const Presentation = Parse.Object.extend("Presentation");
    
        return Promise.all(
            products.map(async (product) => {
                const query = new Parse.Query(Presentation);
                query.equalTo("objectId", product.presentationId);
    
                const presentation = await query.first();
    
                const stockMovement = new StockMovement();
                stockMovement.set("type", "Salida");
                stockMovement.set("previousStock", presentation.get("stock"));
                stockMovement.set("quantity", parseInt(product.delivered));
                stockMovement.set("currentStock", presentation.get("stock") - parseInt(product.delivered));
                stockMovement.set("reason", "Venta");
                stockMovement.set("user", Parse.User.current());
                stockMovement.set("product", {
                    __type: "Pointer",
                    className: "Product",
                    objectId: product.objectId,
                });
                stockMovement.set("presentation", {
                    __type: "Pointer",
                    className: "Presentation",
                    objectId: product.presentationId,
                });
                stockMovement.set("warehouseOutlet", {
                    __type: "Pointer",
                    className: "WarehouseOutlet",
                    objectId: outletObject.objectId, // Use `outletObject` ID after saving
                });
                stockMovement.set("sale", { __type: "Pointer", className: "Sales", objectId: outletObject.get("sale").id });
    
                return stockMovement;
            })
        );
    };
    
    // Function to save WarehouseOutlet, product updates, and StockMovements
    const saveWarehouseOutletAndProducts = async (outletObject, productObjects, stockMovements) => {
        console.log("📦 Outlet: ", outletObject);
        console.log("📦 Products: ", productObjects);
        console.log("📦 Stock Movements: ", stockMovements);
    
        try {
            // Save the WarehouseOutlet
            await outletObject.save();
    
            // Update StockMovements with the saved WarehouseOutlet ID
            stockMovements.forEach((movement) => {
                movement.set("warehouseOutlet", {
                    __type: "Pointer",
                    className: "WarehouseOutlet",
                    objectId: outletObject.id,
                });
            });
    
            // Save all product updates and StockMovements
            await Parse.Object.saveAll([...productObjects, ...stockMovements]);
        } catch (error) {
            throw new Error("Error saving objects: " + error.message);
        }
    };
    
    

    useEffect(() => {
        console.log(outletInfo);
        setOutlet(outletInfo);
    }, [outletInfo]);


    console.log("Signatures: ", clientSignature, carrierSignature);

    
    return (
        <Modal
            title="Nueva salida de almacén"
            open={open}
            onCancel={() => setOpen(false)}
            style={{ top: 20 }}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
            <Space direction="vertical">
            <h2>Venta: {outlet?.sale?.saleNumber}</h2>
            <h3>Orden de salida: {outlet?.orderNumber}</h3>
            <h3>Cliente: {outlet?.sale?.client?.fullName}</h3>
            <h3>Recibe: {outlet?.sale?.deliveryPlace === 'Domicilio' ? 'Transportista' : 'Cliente'}</h3>
           <Table
                dataSource={outlet?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        render: (text, product) => (
                            <p>{text} {product.baseUnit?.toLowerCase()}</p>
                        ),
                    },
                    {
                        title: 'Entregado',
                        dataIndex: 'delivered',
                        key: 'delivered',
                        render: (text, product) => (
                            <Form.Item
                                name={product.name}
                                initialValue={text}
                                rules={[ { required: true, message: 'Por favor ingrese la cantidad entregada' } ]}
                            >
                            <InputNumber
                                style={{ color: parseInt(product.quantity) === parseInt(text) ? 'green' : 'red' }} 
                                min={0}
                                step={1}
                                onChange={(value) => {
                                    const updatedProducts = outlet.products.map((p) => {
                                        if (p.name === product.name) {
                                            return { ...p, delivered: value };
                                        }
                                        return p;
                                    });
                                    setOutlet({ ...outlet, products: updatedProducts });
                                    form.setFieldsValue({ products: updatedProducts });
                                }}
                            />
                            </Form.Item> 
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
            <Form.Item
                label="Lotes"
                name="lots"
                rules={[ { required: true, message: 'Por favor ingrese los lotes' } ]}
            >
                <Input />
            </Form.Item>
            <h3>Detalles de entrega</h3>
            <Form.Item
                label="Vehículo"
                name="vehicle"
                rules={[ { required: true, message: 'Por favor ingrese el vehículo' } ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Placas"
                name="plates"
                rules={[ { required: true, message: 'Por favor ingrese las placas' } ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Conductor"
                name="driver"
                rules={[ { required: true, message: 'Por favor ingrese el nombre del conductor' } ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Empresa de transporte"
                name="carrier"
                rules={[ { required: true, message: 'Por favor ingrese la empresa de transporte' } ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Observaciones"
                name="comments"
            >
                <Input.TextArea />
            </Form.Item>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={openCamera === 'client' ? setClientImages : setCarrierImages} />
            
            {clientSignature && (
                <Space direction="vertical">
                    <h3>Firma del cliente</h3>
                    <img src={clientSignature.image} alt={clientSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{clientSignature.name}</p>
                </Space>
            )}
            {carrierSignature && (
                <Space direction="vertical">
                    <h3>Firma del transportista</h3>
                    <img src={carrierSignature.image} alt={carrierSignature.name} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <p>{carrierSignature.name}</p>
                </Space>
            )}
             <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {clientImages && clientImages.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setClientImages(clientImages.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <Image key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <SignCanvas open={openSign} setOpen={setOpenSign} onSave={(image, name) => {
                if (openSign === 'client') {
                    setClientSignature({ image, name });
                } else {
                    setCarrierSignature({ image, name });
                }
            }} />
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {carrierImages && carrierImages.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setCarrierImages(carrierImages.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <Image key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <Space direction="horizontal">
            {outlet?.sale?.deliveryPlace === 'Domicilio' ? (
                <Button type="primary" onClick={() => setOpenCamera('carrier')}>Fotos de entrega a transportista</Button>
            ) : (
                <Button type="primary" onClick={() => setOpenCamera('client')}>Fotos de entrega a cliente
                </Button>
            )}
            {outlet?.sale?.deliveryPlace === 'Domicilio' ? (
                <Button type="primary" onClick={() => setOpenSign('carrier')}>Firma del transportista</Button>
            ) : (
                <Button type="primary" onClick={() => setOpenSign('client')}>Firma del cliente</Button>
            )}
            
            </Space>
            </Space>
            </Form>
        </Modal>
    );
}

export default NewInventorysale;
