import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message, InputNumber } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
import Parse from 'parse';

const AddMaintenanceEvent = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);


    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            console.log("📦 Form values:", values);
        } catch (error) {
            console.error("Error during form validation:", error);
            message.error("Por favor complete los campos requeridos.");
            return;
        }
        
        const Index = Parse.Object.extend("Index");
        const indexQuery = new Parse.Query(Index);
        indexQuery.equalTo("name", "Maintenance");
        indexQuery.equalTo("year", new Date().getFullYear());
        const index = await indexQuery.first();
        const indexValue = index.get("value");

        try {
            // Validate form fields
            const values = await form.validateFields();
            // Prepare the Maintenance object
            const Maintenance = Parse.Object.extend("Maintenance");
            const maintenance = new Maintenance();
            maintenance.set("device", values.device);
            maintenance.set("failure", values.failure);
            maintenance.set("comments", values.comments);
            maintenance.set("user", Parse.User.current());
            maintenance.set("images", images);
            maintenance.set("folio", `RF${(indexValue + 1).toString().padStart(4, "0")}-${new Date().getFullYear()}`);
           
            // Save the object
            await maintenance.save();
            index.increment("value");
            await index.save();
    
            // Success handling
            message.success('Evento de mantenimiento guardado correctamente.');
            refresh();
            setOpen(false);
        } catch (error) {
            // Error handling
            console.error("Error during handleSubmit:", error);
    
            // Error message for form validation failure or save failure
            if (error instanceof Parse.Error) {
                message.error("Error al guardar el evento de mantenimiento.");
            } else {
                message.error("Error al guardar el evento de mantenimiento.");
            }
        }
    };
    

    return (
        <Modal
            title="Nuevo reporte de falla"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">

            
                        <Form.Item
                            label="Equipo"
                            name="device"
                            rules={[ { required: true, message: 'Por favor ingrese el vehículo' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Falla"
                            name="failure"
                            rules={[ { required: true, message: 'Por favor ingrese las placas' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Observaciones"
                            name="comments"
                        >
                            <Input.TextArea />
                        </Form.Item>
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
            </Form>
        </Modal>
    );
}

export default AddMaintenanceEvent;
