import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography, Statistic, Button, Flex, Input, DatePicker, Select } from "antd";
import ReactApexChart from "react-apexcharts";
import NewInventoryExit from "./NewInventoryExit";
import Parse from "parse";
import moment from "moment";

const { Title } = Typography;

const fetchClients = async (name) => {
  const Client = Parse.Object.extend("_User");
  const query = new Parse.Query(Client);
  query.matches("fullName", name, "i");
  const results = await query.find();
  return results;
};

const fetchSales = async (filters) => {
  const SaleOrder = Parse.Object.extend("Sales");
  const query = new Parse.Query(SaleOrder);
  query.include(["client"]);
  if (filters.saleOrderNumber || filters.client) {
    const clients = await fetchClients(filters.client);
    query.containedIn("client", clients);
    if (filters.saleOrderNumber) query.matches("saleNumber", filters.saleOrderNumber, "i");
  }
  const results = await query.find();
  return results;
};

const fetchProducts = async (name) => {
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  query.matches("name", name, "i");
  const results = await query.find();
  return results;
};

const fetchSaleOrders = async (filters) => {
  const WarehouseOutlet = Parse.Object.extend("WarehouseOutlet");
  const query = new Parse.Query(WarehouseOutlet);
  query.include(["sale", "sale.client"]);
  if (filters.status?.length) query.containedIn("status", filters.status);
  if (filters.saleNumber || filters.client) {
    const sales = await fetchSales({ saleOrderNumber: filters.saleNumber, client: filters.client });
    query.containedIn("sale", sales);
  }
  if (filters.orderNumber) query.contains("orderNumber", filters.orderNumber);
  if (filters.product) {
    const products = await fetchProducts(filters.product);
    query.containedIn("productPointers", products);
  }
  if (filters.date) {
    const start = moment(filters.date[0].$d).startOf("day").toDate();
    const end = moment(filters.date[1].$d).endOf("day").toDate();
    query.greaterThanOrEqualTo("createdAt", start);
    query.lessThanOrEqualTo("createdAt", end);
  }
  query.descending("createdAt");
  const results = await query.find();
  return results?.map((result) => result.toJSON());
}
  

const WarehouseOutputList = () => {
  const [saleOrders, setSaleOrders] = React.useState([]);
  const [newOutput, setNewOutput] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [output, setOutput] = React.useState(null);

  useEffect(() => {
    fetchSaleOrders(filters).then((results) => {
      setSaleOrders(results);
    });
  }, [filters]);


  const dataSource = [
    {
      date: '10/06/2024',
      SalesOrder: 'ODC012',
      Products: [{
        name: 'croqueta gato 10kg',
        quantity: 100,
        unitCost: "3",
        baseUnit: 'Piezas',
        total: '$300.00'
      }],
      Client: "Comercial Cabrera",
      DeliveryOptions: 'Flete',
    },
    {
      date: '10/06/2024',
      SalesOrder: 'ODC011',
      Products: [{
        name: 'croqueta perro 15kg',
        quantity: 100,
        unitCost: "7",
        baseUnit: 'Piezas',
        total: '$700.00'
      }],
      Client: "Previtep",
      DeliveryOptions: 'Tienda',
    },
    {
      date: '07/06/2024',
      SalesOrder: 'ODC010',
      Products: [{
        name: 'croqueta gato 20kg',
        quantity: 200,
        unitCost: "10",
        baseUnit: 'Piezas',
        total: '$2000.00'
      }],
      Client: "Super Victoria",
      DeliveryOptions: 'Pie de fabrica',
    },
    {
      date: '05/06/2024',
      SalesOrder: 'ODC009',
      Products: [{
        name: 'croqueta perro 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Veterinaria Docmaine",
      DeliveryOptions: 'Flete',
    },
    {
      date: '01/06/2024',
      SalesOrder: 'ODC008',
      Products: [{
        name: 'croqueta senior 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Vimitep",
      DeliveryOptions: "Tienda",
    },
    {
      date: '05/06/2024',
      SalesOrder: 'ODC017',
      Products: [{
        name: 'croqueta perro 25kg',
        quantity: 100,
        unitCost: "15",
        baseUnit: 'Piezas',
        total: '$1500.00'
      }],
      Client: "Super Victoria",
      DeliveryOptions: 'Pie de fabrica',
    },
  ];

  // Ordenar dataSource por la fecha (de más viejo a más nuevo)
  dataSource.sort((a, b) => new Date(a.date) - new Date(b.date));

  const columns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Orden de venta",
      dataIndex: "sale",
      key: "sale",
      render: (sale, output) => <Button type='link' onClick={() => {
        setNewOutput(true)
        setOutput(output)}
      } >
        <p>{sale.saleNumber}</p>
      </Button>,
      sorter: (a, b) => a.sale.saleNumber.localeCompare(b.sale.saleNumber),
    },
    {
      title: "Orden de salida",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (orderNumber, output) => <Button type='link' onClick={() => {
        setNewOutput(true)
        setOutput(output)}
      } >
        <p>{orderNumber}</p>
      </Button>,
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
    },
    {
      title: "Productos",
      dataIndex: "products",
      key: "products",
      render: (products) => (
          products?.map((product) => (
              <p>{product.quantity?.toLocaleString()} {product.name}</p>
          ))
      ),
    },
    {
      title: "Cliente",
      dataIndex: "sale",
      key: "sale",
      render: (sale) => <p>{sale?.client?.fullName}</p>,
    },
    {
      title: "Lugar de entrega",
      dataIndex: "sale",
      key: "sale",
      render: (sale) => <h3>{sale?.deliveryPlace}</h3>,
    },
  ];

  // Agrupar y sumar las salidas por fecha
  const groupedData = dataSource.reduce((acc, curr) => {
    const date = curr.date;
    if (acc[date]) {
      acc[date]++;
    } else {
      acc[date] = 1;
    }
    return acc;
  }, {});

  // Preparar datos para la gráfica de líneas de salidas por día
  const chartData = {
    series: [
      {
        name: "Salidas por día",
        data: Object.keys(groupedData).map((date) => ({
          x: new Date(date).getTime(),
          y: groupedData[date],
        })),
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'Número de Salidas',
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
      },
    },
  };

  // Calcular el total de salidas
  const totalOutputs = dataSource.length;

  const renderFilters = () => 
    <Row gutter={[24, 0]}>
       <Col lg={6} className="zindex">
       <Select
        placeholder="Estado"
        onChange={(value) => 
          setFilters({
            ...filters,
            status: value?.length ? value : ["Pendiente", "Entregado"],
          })
        }
        style={{ width: 200 }}
        mode="multiple"
      >
        <Select.Option value="Pendiente">Pendiente</Select.Option>
        <Select.Option value="Entregado">Entregado</Select.Option>
      </Select>
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Número de venta"
        onChange={(e) => setFilters({ ...filters, saleNumber: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Orden de salida"
        onChange={(e) => setFilters({ ...filters, orderNumber: e.target.value })}
      />
      </Col>
      <Col lg={6} className="zindex">
       <Input
        placeholder="Producto"
        onChange={(e) => setFilters({ ...filters, product: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <Input
        placeholder="Cliente"
        onChange={(e) => setFilters({ ...filters, client: e.target.value })}
        style={{ width: 200 }}
      />
      </Col>
      <Col lg={6} className="zindex">
      <DatePicker.RangePicker
        style={{ width: 200 }}
        onChange={(value) => setFilters({ ...filters, date: value })}
      />
      </Col>

    </Row>
  

  return (
    <div className="layout-content">
      <NewInventoryExit open={newOutput} setOpen={setNewOutput} outletInfo={output} />
      <Row gutter={[24, 0]}>
      <Flex justify="space-between" align="center">
      <h2 className="p-15 h2">Salidas de almacén</h2>
      {renderFilters()}
      </Flex>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <div className="ant-list-box table-responsive">
              <Table
                dataSource={saleOrders}
                columns={columns}
                rowKey={(record, index) => index}
                pagination={true}
              />
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Estadísticas de Salidas</h3>}
          >
            <Statistic title="Total de Salidas" value={totalOutputs} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <Card
            bordered={false}
            bodyStyle={{ padding: "20px" }}
            className="criclebox cardbody mb-24 header-solid"
            title={<h3>Salidas por día</h3>}
          >
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Card>
        </Col>
      </Row> */}


    </div>
  );
};

export default WarehouseOutputList;
