import Parse from "parse";

const fetchVisits = async (sellerId) => {
    console.log(sellerId);
        const Visit = Parse.Object.extend("Visits");
        const query = new Parse.Query(Visit);
        query.equalTo("seller", { __type: "Pointer", className: "_User", objectId: sellerId });
        query.include(["client"]);
        query.descending("createdAt");
        const results = await query.find();
        return results.map((result) => result.toJSON());
    }
    

const fetchClients = async () => {
   const Role = Parse.Object.extend("_Role");
    const query = new Parse.Query(Role);
    query.equalTo("name", "Cliente");
    const role = await query.first();
    const relation = role.relation("users");
    const queryUser = relation.query();
    const users = await queryUser.find();
    return users.map((user) => {
        return {
            ...user.toJSON(),
            value: user.get("fullName"),
            label: user.get("fullName"),
            key: user.id,
        };
    });
}

       


export { fetchVisits, fetchClients };
