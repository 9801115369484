import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Statistic, Button, Flex } from "antd";
import Parse from "parse";
import moment from "moment";
import ConfirmPayment from "../components/ConfirmPayment";

const printOrder = async (order) => {
  const params = {
    date: order.createdAt,
    number: order.number,
    seller: order.seller.fullName,
    sales: order.sales.map((sale) => { return {
      date: sale.createdAt,
      number: sale.saleNumber,
      client: sale.client.fullName,
      total: sale.total,
      commission: sale.total * (order.commissionPercentage / 100),
    }}),
    salesAmount: order.base,
    commission: order.amount,
  }

  try {
    const data = await Parse.Cloud.run('printCommissionOrder', params);
    const pdfWindow = window.open();
    pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`);
  }
  catch (error) {
    console.error('Error printing commission order:', error);
  }
};

const printReceipt = async (order) => {
  const params = {
    date: order.createdAt,
    number: order.number,
    seller: order.seller.fullName,
    sales: order.sales.map((sale) => { return {
      date: sale.createdAt,
      number: sale.saleNumber,
      client: sale.client.fullName,
      total: sale.total,
      commission: sale.total * (order.commissionPercentage / 100),
    }}),
    salesAmount: order.base,
    commission: order.amount,
    paymentDate: order.paymentDate,
  }

  try {
    const data = await Parse.Cloud.run('printCommissionReceipt', params);
    const pdfWindow = window.open();
    pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`);
  }
  catch (error) {
    console.error('Error printing commission receipt:', error);
  }
};

const fetchSellercommissionPercentages = async (sellerId) => {
    const Payments = Parse.Object.extend('Commission');
    const query = new Parse.Query(Payments);
    query.equalTo('seller', { __type: 'Pointer', className: '_User', objectId: sellerId });
    query.include(['sales', 'sales.client', 'sales.seller']);
    query.descending('createdAt');
    const results = await query.find();
    return results.map((result) => result.toJSON());
};

const SellerCommissions = ({ seller, refresh }) => {
  const [commissionPercentages, setcommissionPercentages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [outpayment, setOutpayment] = useState(null);

  useEffect(() => {
    const fetchcommissionPercentages = async () => {
      const commissionPercentages = await fetchSellercommissionPercentages(seller.objectId);
      setcommissionPercentages(commissionPercentages);
      setLoading(false);
    };
    fetchcommissionPercentages();
  }, [seller]);

  const renderActions = (record) => {
    return (
      <Flex gap="10px">
        {record.status === 'Pendiente' && (
          <Button
            type="primary"
            onClick={() => {
              printOrder(record);
            }}
          >
            Imprimir orden
          </Button>
        )}
         {record.status === 'Pendiente' && (
          <Button
            type="primary"
            onClick={() => {
              setOutpayment(record);
              setConfirmPayment(true);
            }}
          >
            Pagar
          </Button>
        )}
  
        {record.status === 'Pagado' && (
          <Button type="primary"
            onClick={() => printReceipt(record)}
          >
            Imprimir recibo
          </Button>
        )}
      </Flex>
    );
  };


  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <h3>{text}</h3>,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    
      {
        title: 'No. de Orden',
        dataIndex: 'number',
        key: 'number',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => <span>{moment(date.iso).format('DD/MM/YYYY')}</span>,
        sorter: (a, b) => moment(a.createdAt.iso).unix() - moment(b.createdAt.iso).unix(),
      },
      {
        title: 'Ventas',
        dataIndex: 'sales',
        key: 'sales',
        render: (sales) => <span>{sales.map((sale) => sale.saleNumber).join(', ')}</span>,
      },
      {
        title: 'Monto de venta',
        dataIndex: 'base',
        key: 'base',
      },
      {
        title: 'Comisión',
        dataIndex: 'commission',
        key: 'commission',
        render: (text, record) => <span>{record.amount}</span>,
      },
      {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => renderActions(record),
      }
  ];

  console.log(seller);
    return (
        <Row gutter={[24, 0]}>
          <ConfirmPayment outpayment={outpayment} open={confirmPayment} setOpen={setConfirmPayment} refresh={refresh} />
            <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Comisión</h2>}>
                <Row style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <Col>
                        <Statistic
                            title={<h3>Porcentaje de Comisión</h3>}
                            value={seller?.commissionPercentage}
                            formatter={(value) => <span>{value} %</span>}
                        />
                    </Col>
                </Row>
                </Card> 
            </Col>
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Historial de Comisiones</h2>
                    <Table columns={columns} dataSource={commissionPercentages} loading={loading} rowKey={(record) => record.objectId} />
                </Card>
            </Col>
        </Row>
    )
}

export {SellerCommissions};