import React, { useState, useEffect } from 'react';
import { Table, Typography, Button, Tag, Form, Input, Select, message, DatePicker, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Parse from 'parse';
import moment from 'moment';
import dayjs from 'dayjs';
import NewPayment from '../sales/components/SalesTabs/modals/NewPayment';

const fetchClients = (name) => {
    const query = new Parse.Query('_User');
    query.matches('fullName', name, 'i');
    return query.find();
}

const fetchPayments = async (filters) => {
    const query = new Parse.Query('Payment');
    if (filters.client) {
        const clients = await fetchClients(filters.client);
        query.containedIn('client', clients);
    }
    if (filters.status) {
        query.containedIn('status', filters.status);
    }
    if (filters.date) {
        const start = dayjs(filters.date[0]).startOf('day').toDate();
        const end = dayjs(filters.date[1]).endOf('day').toDate();
        const dueDateQuery = new Parse.Query('Payment');
        const paymentDateQuery = new Parse.Query('Payment');
        dueDateQuery.greaterThanOrEqualTo('dueDate', start);
        dueDateQuery.lessThanOrEqualTo('dueDate', end);
        paymentDateQuery.greaterThanOrEqualTo('paymentDate', start);
        paymentDateQuery.lessThanOrEqualTo('paymentDate', end);
        query._orQuery([dueDateQuery, paymentDateQuery]);
    }
    query.include(['sale', 'client']);
    query.descending('createdAt');
    return query.find();
}

const renderStatusTag = (status) => {
    switch (status) {
        case 'Pagado':
            return <Tag color="green">Pagado</Tag>;
        case 'Pendiente':
            return <Tag color="orange">Pendiente</Tag>;
        case 'Vencido':
            return <Tag color="red">Vencido</Tag>;
        case 'Cancelado':
            return <Tag color="gray">Cancelado</Tag>;
        default:
            return null;
    }
}

const renderFullfillment = (payment) => {
    if (!payment.credit) return ''
    const diff = dayjs(payment.paymentDate?.iso).diff(dayjs(payment.dueDate?.iso), 'days');
    if (payment.credit && payment.dueDate && payment.paymentDate) {
       if (diff > 0) {
           return <Tag color="red">{diff} días de retraso</Tag>;
       }
       else if (diff === 0) {
           return <Tag color="green">A tiempo</Tag>;
       } else if (diff < 0) {
           return <Tag color="purple">{diff*-1} días de anticipación</Tag>;
       }
    }
    return <Tag color="blue">Pendiente</Tag>;
}

const Payments = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({ status: ['Pendiente', 'Vencido'], date: null });
    const [sale, setSale] = useState(null);
    const [pendingPayment, setPendingPayment] = useState(false);
    
    useEffect(() => {
        fetchData()
    }, [filters]);
    
    const fetchData = () => {
        setLoading(true);
        fetchPayments(filters).then((results) => {
            setData(results.map((result) => result.toJSON()));
            setLoading(false);
        }
        );
    }

    useEffect(() => {
        if (filters.status?.length === 0) {
            setFilters({...filters, status: ['Pendiente', 'Vencido']});
        }
       
    }, [filters]);

    console.log(filters);
    
    const columns = [
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => renderStatusTag(status),
        sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: 'Folio',
            dataIndex: 'paymentNumber',
            key: 'paymentNumber',
            sorter: (a, b) => a.paymentNumber - b.paymentNumber,
            render: (paymentNumber, payment) => <a onClick={() => {
                if (payment.status === 'Pagado') return;
                if (payment.status === 'Cancelado') return;
                setPendingPayment(payment);
                setSale(payment.sale);
            }}>{paymentNumber}</a>
        },
        {
            'title': 'Cliente',
            'dataIndex': 'client',
            'key': 'client',
            render: (client) => client?.fullName,
            sorter: (a, b) => a.client.fullName.localeCompare(b.client.fullName),
        },
        {
        title: 'Venta',
        dataIndex: 'sale',
        key: 'sale',
        render: (sale) => sale?.saleNumber,
        sorter: (a, b) => a.sale.saleNumber - b.sale.saleNumber,
        },
        {
            title: 'Monto',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `$${amount.toFixed(2)}`,
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Fecha de vencimiento',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (dueDate) => dueDate ? moment(dueDate?.iso).format('DD/MM/YYYY') : 'Sin fecha',
            sorter: (a, b) => {
                if (a.dueDate && b.dueDate) {
                    return dayjs(a.dueDate.iso).diff(dayjs(b.dueDate.iso));
                }
                return 0;
            }
        },
        {
            title: 'Fecha de pago',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            render: (paymentDate) => paymentDate ? moment(paymentDate?.iso).format('DD/MM/YYYY') : 'Pendiente',
            sorter: (a, b) => {
                if (a.paymentDate && b.paymentDate) {
                    return dayjs(a.paymentDate.iso).diff(dayjs(b.paymentDate.iso));
                }
                return 0;
            }
        },
        {
            title: 'Cumplimiento',
            dataIndex: 'credit',
            key: 'credit',
            render: (credit, payment) => renderFullfillment(payment),
        }
        
    ];
    
    const renderFilters = () => {
        return (
        <Flex direction="row" justify="flex-end" gap={10}>
            <Input
                placeholder="Cliente"
                style={{ width: 200 }}
                value={filters.search}
                onChange={(e) => setFilters({...filters, client: e.target.value})}
            />
            <Select 
                placeholder="Status" style={{ width: 500 }} 
                value={filters.status}
                onChange={(e) => setFilters({...filters, status: e})}
                allowClear
                mode="multiple"
            >
                <Select.Option value="Pagado">Pagado</Select.Option>
                <Select.Option value="Pendiente">Pendiente</Select.Option>
                <Select.Option value="Vencido">Vencido</Select.Option>
                <Select.Option value="Cancelado">Cancelado</Select.Option>
            </Select>
            <DatePicker.RangePicker 
                style={{ width: 300 }} 
                onChange={(e) => setFilters({...filters, date: e})} 
                value={filters.date} 
                defaultValue={[moment(moment().add(-1, "month")), moment()]}

            />
        </Flex>
        );
    }
    
    return (
        <div>
        <NewPayment sale={sale} open={pendingPayment} setOpen={setPendingPayment} pendingPayment={pendingPayment} refresh={fetchData} />
        <Typography.Title level={3}>Cuentas por cobrar</Typography.Title>
            {renderFilters()}
        <Table dataSource={data} columns={columns} loading={loading} rowKey="id" />
        
        </div>
    );
    }

export default Payments;