import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message, InputNumber, Image, Descriptions } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
import Parse from 'parse';

const AddMaintenanceEvent = ({ open, setOpen, event }) => {
    const [form] = Form.useForm();
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);


    useEffect(() => {
        form.setFieldsValue({ device: event?.device, failure: event?.failure, comments: event?.comments });
    }, [event]);
    
    

    return (
        <Modal
            title="Reporte de falla"
            open={open}
            onCancel={() => setOpen(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Descriptions title="Detalles del evento" bordered layout="vertical">
                <Descriptions.Item label="Folio">{event?.folio}</Descriptions.Item>
                <Descriptions.Item label="Equipo">{event?.device}</Descriptions.Item>
                <Descriptions.Item label="Falla">{event?.failure}</Descriptions.Item>
                <Descriptions.Item label="Comentarios">{event?.comments}</Descriptions.Item>
                <Descriptions.Item label="Reportado por">{event?.user?.fullName}</Descriptions.Item>
            </Descriptions>
           
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {event?.images && event?.images?.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Image key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                    </Space>
                ))}
            </Flex>
            
        </Modal>
    );
}

export default AddMaintenanceEvent;
