import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, Steps, Table, Progress, Button, message, Modal, Descriptions, Flex } from 'antd';
import { SettingsContext } from '../../../../context/SettingsContext';
import { UserContext } from '../../../../context/UserContext';
import Parse from 'parse';
import moment from 'moment';

const shrinkageColumns = [
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Producto",
        dataIndex: "product",
        key: "product",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Motivo",
        dataIndex: "reason",
        key: "reason",
        render: (text) => <h3>{text}</h3>
    },
]

const recipeColumns = [
    {
        title: "Ingrediente",
        dataIndex: "name",
        key: "ingredient",
        render: (text) => <h3>{text}</h3>
    },
    {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        render: (text) => <h3>{text} kg</h3>
    },
  
]

const getIngredients = async (ingredients) => {
    const Ingredient = Parse.Object.extend("Ingredient");
    const query = new Parse.Query(Ingredient);
    query.containedIn("objectId", ingredients.map((ingredient) => ingredient.objectId));

    const results = await query.find();
    return results.map((ingredient) => ingredient.toJSON());
}

const CloseProduction = ({ production, open, setOpen, closeProduction }) => {
    const [loading, setLoading] = React.useState(false);
    return (
        <Modal
            title="Cerrar producción"
            open={open}
            onOk={() => closeProduction()}
            onCancel={() => setOpen(false)}
            loading={loading}
        >
            <p>¿Estás seguro que deseas cerrar la producción?</p>
        </Modal>
    )
}


const ProductionSummary = ({ production, refresh }) => {
    const [ productionInfo, setProductionInfo ] = React.useState(null);
    const [ shrinkage, setShrinkage ] = React.useState([]);
    const [showCloseProduction, setShowCloseProduction] = React.useState(false);

    const { settings } = React.useContext(SettingsContext);
    const { permissions } = React.useContext(UserContext);

    const productionSettings = settings?.find((setting) => setting.name === "production");
    const maxVariation = productionSettings?.values?.find((value) => value.name === "maxProductionVariation")?.value/100;
    const variationOverride = permissions?.find((permission) => permission.group === "production" && permission.action === "overrideVariation");


    const closeProduction = async () => {
        // Close the production
        if (production) {
            // Close the production
            const minProduction = production.productionQuantity * (1 - maxVariation);
            console.log("🔧 Min Production:", minProduction, production.produced, variationOverride)
            if (minProduction <= production.produced && !variationOverride) {
                message.error("No se puede cerrar la producción porque no se ha producido la cantidad mínima");
                return;
            }
        }

        // TODO: Calculate productionCost

        // Create a new ProductionInfo object
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const productionOrder = new ProductionOrder();
        productionOrder.set("objectId", production.objectId);
        productionOrder.set("closed", true);
        productionOrder.set("closedBy", Parse.User.current());
        productionOrder.set("closedAt", new Date());

        try {
            await productionOrder.save();
            setShowCloseProduction(false);
            refresh();
            message.success("Producción cerrada exitosamente");
        } catch (error) {
            console.error("Error closing production:", error);
            message.error("Error al cerrar la producción");
        }
    }
  

    return (
        <>
            <CloseProduction production={production} open={showCloseProduction} setOpen={setShowCloseProduction} closeProduction={closeProduction} />
            <Row gutter={16}>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Total de la órden de producción"
                                value={production?.productionQuantity}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8} xs={24} md={8}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ margin: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={
                            <Statistic
                                title="Total producido"
                                value={production?.produced}
                                suffix="KG"
                                style={{ fontSize: '20px' }}
                            />
                        }
                    />
                </Col>
                <Col span={8} xs={24} md={8}>
                    {!production?.closed ? <Button
                        type="primary"
                        style={{ margin: '10px' }}
                        onClick={() => setShowCloseProduction(true)}
                    >
                        Cerrar producción
                    </Button> : <Card
                        bordered={false}
                        bodyStyle={{ padding: '10px' }}
                        style={{ padding: '10px' }}
                        className="criclebox cardbody mb-24 header-solid"
                        title="Producción cerrada"
                    >
                          <Descriptions column={1}>
                                <Descriptions.Item label="Cerrada por">{production?.closedBy?.fullName}</Descriptions.Item>
                                <Descriptions.Item label="Fecha de cierre">{moment(production?.closedAt?.iso).format("DD/MM/YYYY")}</Descriptions.Item>
                            </Descriptions>
                    </Card>}
                    
                </Col>
              
            </Row>
            
            <Row>
            <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20, marginRight: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Receta</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={production?.recipe.ingredients}
                                columns={recipeColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={12} md={12}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: "0px"}}
                        style={{ padding: 20, marginRight: 10 }}
                        className="criclebox cardbody mb-24 header-solid"
                        title={<h2>Merma</h2>}
                    >
                        <div className="ant-list-box table-responsive">
                            <Table
                                style={{ width: '100%' }}
                                dataSource={shrinkage}
                                columns={shrinkageColumns}
                                rowKey={(record, index) => index}
                                pagination={true}
                            />
                        </div>
                    </Card>
                </Col>
              
            </Row>
        </>
    )
}


export default ProductionSummary;
