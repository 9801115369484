import "./App.css";
import "./responsive.css";
import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import Parse from "parse";

// Context
import { UserProvider } from "./context/UserContext";
import { SettingsProvider } from "./context/SettingsContext";

// Pages
import Main from "./component/layout/main";
import SignUp from "./component/pages/SignUp";
import SignIn from "./component/pages/SignIn";
import PasswordRecovery from "./component/pages/PasswordRecovery";
import AcceptQuote from "./component/pages/sales/components/AcceptQuote";
import { ClientSignature } from "./component/pages/inventory";
import Privacy from "./component/pages/Privacy";
import Terms from "./component/pages/Terms";
import PurchaseOrderPublicDetail from "./component/pages/purchases/components/PurchaseOrderPublicDetail";

// Load environment variables
const PARSE_APP_ID = process.env.REACT_APP_PARSE_APP_ID || "";
const PARSE_JS_KEY = process.env.REACT_APP_PARSE_JS_KEY || "";
const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL || "";

// Initialize Parse
Parse.initialize(PARSE_APP_ID, PARSE_JS_KEY);
Parse.serverURL = PARSE_SERVER_URL;

function App() {
  // Memoized Router to prevent unnecessary re-renders
  const router = useMemo(
    () =>
      createBrowserRouter([
        { path: "/*", element: <Main /> },
        { path: "/signup", element: <SignUp /> },
        { path: "/signin", element: <SignIn /> },
        { path: "/password-recovery", element: <PasswordRecovery /> },
        { path: "/sales/AcceptQuote/:uuid", element: <AcceptQuote /> },
        { path: "/deliveries/signature/:id", element: <ClientSignature /> },
        { path: "/purchaseOrder/:uuid", element: <PurchaseOrderPublicDetail /> },
        { path: "/privacy", element: <Privacy /> },
        { path: "/terms", element: <Terms /> },
      ]),
    []
  );

  return (
    <div className="App">
      <UserProvider>
        <SettingsProvider>
          <RouterProvider router={router} />
        </SettingsProvider>
      </UserProvider>
    </div>
  );
}

export default App;