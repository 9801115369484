import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message, InputNumber } from "antd";
import Parse, { Role } from "parse";
import { generateRandomPassword } from "../utils/randomPassword";
import { setACL } from "../../../../utils/ACL";
import { findErrorMessage } from "../../../../utils/parseErrors";
import { v4 as uuidv4 } from "uuid";

const { Title } = Typography;

const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    let cleaned = value.replace(/\D/g, "");
  
    // Remove leading country code if present (e.g., +52)
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(cleaned.length - 10); // Keep only the last 10 digits
    }
    
    return cleaned
  };
  
const formatWhatsappNumber = (value) => {
    // Remove all non-numeric characters
    let cleaned = value.replace(/\D/g, "");

    return cleaned
};

const formatEmail = (value) => {
    return value?.toLowerCase()?.trim();
};

const NewSeller = ({ open, setOpen, refresh, seller, editing }) => {
    const [form] = Form.useForm();

    useEffect(() => {
       if (editing && seller) {
           form.setFieldsValue({
               firstName: seller.firstName,
               lastName: seller.lastName,
               email: seller.email,
               phoneNumber: seller.phoneNumber,
                commissionPercentage: seller.commissionPercentage,
                salesGoal: seller.salesGoal,
           });
       } else {
           form.resetFields();
       }
    }, [open, seller, editing]);

    const createSeller = async () => {
        const seller = form.getFieldsValue();
        try {
            const newSeller = new Parse.User();
            newSeller.set("username", seller.email?.trim()?.toLowerCase());
            newSeller.set("password", generateRandomPassword());
            newSeller.set("firstName", seller.firstName?.trim());
            newSeller.set("lastName", seller.lastName?.trim());
            newSeller.set("fullName", `${seller.firstName?.trim()} ${seller.lastName?.trim()}`);
            newSeller.set("email", seller.email?.trim()?.toLowerCase());
            newSeller.set("phoneNumber", seller.phoneNumber?.trim());
            newSeller.set("whatsappNumber", seller.whatsappNumber?.trim());
            newSeller.set("commissionPercentage", parseInt(seller.commissionPercentage) || 0);
            newSeller.set("salesGoal", parseInt(seller.salesGoal) || 0);
            newSeller.set("uuid", uuidv4());
            newSeller.setACL(setACL("Ventas"));
    
            const result = await newSeller.save();
    
            // Add user to the "Ventas" role
            const roleQuery = new Parse.Query(Role);
            roleQuery.equalTo("name", "Ventas");
            const role = await roleQuery.first();
            if (role) {
                role.getUsers().add(newSeller);
                await role.save();
            } else {
                console.error("Role 'Ventas' not found");
            }
    
            console.log("Seller created", result);
            message.success("Nuevo vendedor agregado correctamente");
            refresh();
            setOpen(false);
        } catch (error) {
            console.error("Error while creating Seller: ", error);
            message.error(findErrorMessage(error) || "Error al agregar el nuevo vendedor");
        }
    };
    

    const updateSeller = () => {
        const values = form.getFieldsValue();
        try {
            const Seller = Parse.Object.extend("_User");
            const sellerObject = new Seller();
            sellerObject.set('objectId', seller.objectId);
            sellerObject.set('firstName', values.firstName?.trim());
            sellerObject.set('lastName', values.lastName?.trim());
            sellerObject.set('fullName', `${values.firstName?.trim()} ${values.lastName?.trim()}`);
            sellerObject.set('email', values.email?.trim()?.toLowerCase());
            sellerObject.set('phoneNumber', values.phoneNumber?.trim());
            sellerObject.set('commissionPercentage', parseInt(values.commissionPercentage));
            sellerObject.set('salesGoal', parseInt(values.salesGoal));
            sellerObject.save().then(
                (result) => {
                    console.log("Seller updated", result);
                    message.success("Vendedor actualizado correctamente");
                    refresh();
                    setOpen(false);
                }
            );
        } catch (error) {
            console.error("Error while updating Seller: ", error);
            message.error("Error al actualizar el vendedor");
        }
    }


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (editing) {
                updateSeller();
            } else {
                createSeller();
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Modal
            title={editing ? "Editar vendedor" : "Nuevo vendedor"}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="basic"
            >
                <Form.Item
                    label="Nombre"
                    name="firstName"
                    rules={[{ required: true, message: 'El nombre es requerido' }]}
                >
                    <Input placeholder="Nombre" />
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="lastName"
                    rules={[{ required: true, message: 'El apellido es requerido' }]}
                >
                    <Input placeholder="Apellido" />
                </Form.Item>

                 <Form.Item
                                    label="Teléfono"
                                    name="phoneNumber"
                                    rules={[{ required: true, message: 'El teléfono es requerido' },
                                    { pattern: /^[0-9]{10}$/, message: 'El teléfono debe tener 10 dígitos' }]}
                                >
                                    <Input 
                                        onChange={(e) => form.setFieldsValue({ phoneNumber: formatPhoneNumber(e.target.value) })}
                                        onPaste={(e) => form.setFieldsValue({ phoneNumber: formatPhoneNumber(e.target.value) })}
                                        placeholder="(xx) xxx xxxx"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="WhatsApp"
                                    name="whatsappNumber"
                                    rules={[{ pattern: /^[0-9]{11,12}$/, message: 'El WhatsApp debe tener 10 dígitos' }]}
                                >
                                    <Input 
                                        onChange={(e) => form.setFieldsValue({ whatsappNumber: formatWhatsappNumber(e.target.value) })}
                                        onPaste={(e) => form.setFieldsValue({ whatsappNumber: formatWhatsappNumber(e.target.value) })}
                                        placeholder="(52) xxx xxxx xxxx"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Correo electrónico"
                                    name="email"
                                    rules={[{ required: true, message: 'El correo electrónico es requerido' },
                                    { type: 'email', message: 'Correo electrónico inválido' }]}
                                >
                                    <Input 
                                        onChange={(e) => form.setFieldsValue({ email: formatEmail(e.target.value) })}
                                        placeholder="ejemplo@ejemplo.com"
                                    />
                                </Form.Item>
                <Form.Item
                    label="Porcentaje de comisión"
                    name="commissionPercentage"
                    rules={[{ required: true, message: 'Es necesario un porcentaje de comisión' }]}
                >
                    <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={100} 
                    onInvalid={() => message.warning('El porcentaje de comisión debe ser mayor a 0')}
                    suffix={<span>%</span>}
                    placeholder="10"
                    />
                </Form.Item>
                <Form.Item
                    label="Objetivo de ventas"
                    name="salesGoal"
                    rules={[{ required: true, message: 'Es necesario un objetivo de ventas' } ]}
                >
                    <InputNumber 
                        style={{ width: '100%' }}
                        min={0}
                        onInvalid={() => message.warning('El objetivo de ventas debe ser mayor a 0')}
                        prefix={<span>$</span>}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        placeholder="10,000"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewSeller;
