import React from "react";
import { Button, Card, Table, Typography, Modal, Image, Descriptions, Row, Col, message, Flex } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import moment from "moment";
import OwnButton from "../../OwnButton";
import Parse from "parse";
import { Printer } from "react-bootstrap-icons";

const { Title, Text } = Typography;

const DeliveryDetail = ({ open, setOpen, delivery, sale }) => {
    const [printing, setPrinting] = React.useState(false);
    console.log("📦 Order: ", delivery);

    if (!delivery) return null;

    const handlePrint = async () => {
        setPrinting(true);
        const params = {
            deliveryOrderNumber: delivery.orderNumber,
            deliveryDate: delivery.deliveryDate,
            createdBy: delivery.createdBy?.fullName || "N/A",
            sale: sale.saleNumber,
            receivedBy: delivery.carrierSignature?.name || delivery.clientSignature?.name || "N/A",
            recipientRole: delivery.carrierSignature ? "Transportista" : "Cliente",
            client: sale.client.fullName,
            seller: sale.seller.fullName,
            clientSignature: delivery.clientSignature,
            carrierSignature: delivery.carrierSignature,
            clientImages: delivery.clientImages,
            carrierImages: delivery.carrierImages,
            comments: delivery.comments || "Sin comentarios",
            products: delivery.products,
        };

        try {
            const data = await Parse.Cloud.run("printDeliveryProof", params);
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        } catch (error) {
            console.error("Error printing delivery order:", error);
            message.error("Ocurrió un error al imprimir la orden de salida. Intente de nuevo.");
        } finally {
            setPrinting(false);
        }
    };

    return (
        <Modal
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Flex gap="10px" alignItems="flex-end" justify="end">
                        <FileTextOutlined style={{ fontSize: "22px", color: "#1890ff" }} />
                        <Title level={4} style={{ marginBottom: 0 }}>Detalles de entrega</Title>
                    </Flex>
                    {sale?.active && (
                        <OwnButton
                            icon={<Printer />}
                            type="default"
                            onClick={handlePrint}
                            loading={printing}
                        >
                            Imprimir
                        </OwnButton>
                    )}
                </div>
            }
            open={open}
            closable={false}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" type="primary" onClick={() => setOpen(false)}>
                    Cerrar
                </Button>,
            ]}
            width={900}
            bodyStyle={{ padding: "24px" }}
        >
            {/* Order Details */}
            <Card bordered={false} style={{ backgroundColor: "#f9f9f9", marginBottom: "20px" }}>
                <Descriptions column={2} size="middle" layout="vertical">
                    <Descriptions.Item label="Fecha de entrega">
                        {moment(delivery.deliveryDate).format("DD/MM/YYYY")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Recibida por">
                        {delivery.carrierSignature?.name || delivery.clientSignature?.name || "N/A"} ({delivery.carrierSignature ? "Transportista" : "Cliente"})
                    </Descriptions.Item>
                    <Descriptions.Item label="Emitida por">
                        {delivery.createdBy?.fullName || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cliente">
                        {sale.client.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Venta">
                        {sale.saleNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vendedor">
                        {sale.seller.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Lotes">
                        {delivery.lots || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vehículo">
                        {delivery.vehicleInfo?.vehicle || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Placas">
                        {delivery.vehicleInfo?.plates || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Conductor">
                        {delivery.vehicleInfo?.driver || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Empresa">
                        {delivery.vehicleInfo?.carrier || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Comentarios" span={2}>
                        {delivery.comments || "Sin comentarios"}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {/* Product List */}
            <Title level={4} style={{ marginBottom: "10px" }}>Productos</Title>
            <Table
                dataSource={delivery?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <Text strong>{text}</Text>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        align: "center",
                        render: (text) => <Text>{text}</Text>,
                    },
                    {
                        title: "Entregado",
                        dataIndex: "delivered",
                        key: "delivered",
                        align: "center",
                        render: (text) => (
                            <Text style={{ color: text > 0 ? "green" : "red" }}>{text}</Text>
                        ),
                    },
                ]}
                pagination={false}
                rowKey={(record) => record.name}
                style={{ marginBottom: "20px" }}
            />

            {/* Images */}
            {delivery?.carrierImages?.length > 0 && (
                <>
                    <Title level={4} style={{ marginBottom: "10px" }}>Imágenes de la Entrega al Transportista</Title>
                    <Row gutter={[16, 16]}>
                        {delivery.carrierImages.map((image, index) => (
                            <Col span={6} key={index}>
                                <Image src={image} alt={`Transport Image ${index + 1}`} style={{ width: "100%", borderRadius: "8px" }} preview={true} />
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {delivery?.clientImages?.length > 0 && (
                <>
                    <Title level={4} style={{ marginBottom: "10px" }}>Imágenes de la Entrega al Cliente</Title>
                    <Row gutter={[16, 16]}>
                        {delivery.clientImages.map((image, index) => (
                            <Col span={6} key={index}>
                                <Image src={image} alt={`Client Image ${index + 1}`} style={{ width: "100%", borderRadius: "8px" }} preview={true} />
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {/* Signatures in Two Columns */}
            {(delivery?.carrierSignature || delivery?.clientSignature) && (
                <Row gutter={16} style={{ marginTop: "20px" }}>
                    {delivery?.carrierSignature && (
                        <Col span={12}>
                            <Card hoverable style={{ textAlign: "center", padding: "10px" }}>
                                <Title level={5}>Firma del Transportista</Title>
                                <Image src={delivery.carrierSignature.image} alt="Carrier Signature" style={{ width: "80%", maxHeight: "100px" }} preview={false} />
                                <Text style={{ display: "block", fontWeight: "bold", marginTop: "5px" }}>
                                    {delivery.carrierSignature.name}
                                </Text>
                            </Card>
                        </Col>
                    )}
                    {delivery?.clientSignature && (
                        <Col span={12}>
                            <Card hoverable style={{ textAlign: "center", padding: "10px" }}>
                                <Title level={5}>Firma del Cliente</Title>
                                <Image src={delivery.clientSignature.image} alt="Client Signature" style={{ width: "80%", maxHeight: "100px" }} preview={false} />
                                <Text style={{ display: "block", fontWeight: "bold", marginTop: "5px" }}>
                                    {delivery.clientSignature.name}
                                </Text>
                            </Card>
                        </Col>
                    )}
                </Row>
            )}
        </Modal>
    );
};

export default DeliveryDetail;