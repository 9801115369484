import React, { useEffect, useState } from "react";
import { Row, Col, Card, Upload, Button, Progress, Modal, Flex, Skeleton, Steps, message, Input } from "antd";
import Parse from "parse";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { FilePdfFill, Download } from "react-bootstrap-icons";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Dragger } = Upload;

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const documentList = [
  { key: "cfid", label: "Constancia de Situación Fiscal" },
  { key: "comprobante_domicilio", label: "Comprobante de Domicilio" },
  { key: "opinion_cumplimiento", label: "Opinión de Cumplimiento" },
  { key: "identificacion_representante", label: "Identificación del Representante" },
  { key: "acta_constitutiva", label: "Acta Constitutiva" },
  { key: "other", label: "Otros" },
];

const getURL = async (fileKey) => {
  const params = { Bucket: S3_BUCKET, Key: fileKey };
  try {
    return await s3.getSignedUrlPromise("getObject", params);
  } catch (error) {
    console.error("Error getting file URL:", error);
  }
};

const addFileToParse = async (filename, supplier, type, name) => {
  const Document = Parse.Object.extend("Document");
  const document = new Document();
  document.set("user", { __type: "Pointer", className: "_User", objectId: supplier.objectId });
  document.set("filename", filename);
  document.set("name", name.split(".").slice(0, -1).join("."));
  document.set("type", type);
  try {
    await document.save();
  } catch (error) {
    console.error("Error saving document:", error);
  }
};



const SupplierDocuments = ({ supplier }) => {
  const [documents, setDocuments] = useState({});
  const [fileLists, setFileLists] = useState({});
  const [loading, setLoading] = useState({});
  const [pdfModal, setPdfModal] = useState({ open: false, url: "", name: "" });

  const changeFilename = async (previousName, fileId, name) => {
    if (previousName === name) return;
    const Document = Parse.Object.extend("Document");
    const query = new Parse.Query(Document);
    try {
      const document = await query.get(fileId);
      document.set("name", name);
      await document.save();
      message.success("Nombre de archivo actualizado");
      fetchDocuments();
    } catch (error) {
      console.error("Error changing filename:", error);
      message.error("Error al actualizar el nombre del archivo");
    }
  };
  
  
  
  
  const PDFModal = ({ documents, open, setOpen, name }) => {
    const [documentList, setDocuments] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
  
    useEffect(() => {
      if (open) {
        setSelectedDocument(documents[0]);
        setDocuments(documents);
      }
    }, [open, documents]);
  
    if (!documents || documents.length === 0) return null;
  
    return (
      <Modal 
        title={name}
        open={open} 
        onOk={() => setOpen(false)} 
        onCancel={() => setOpen(false)} 
        width={1200} 
        style={{ top: 20 }} 
        bodyStyle={{ height: "80vh", overflow: 'hidden' }}
        closable={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={18}>
            {loading && <Skeleton.Image style={{ width: 200, height: 200 }} />}
            <Flex justify="center">
              <div style={{ maxHeight: "70vh", overflowY: "auto", padding: "10px" }}>
                <Document
                loading={loading}
                loadingIndicator={<Progress type="circle" percent={100} />}
                  file={selectedDocument?.url}
                  onLoadSuccess={({ numPages }) => {
                    setNumPages(numPages);
                    setLoading(false);
                  }}
                >
                  {Array.from(new Array(numPages), (_, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={10}>
              <Button type="primary" icon={<Download />} onClick={() => window.open(selectedDocument?.url, "_blank")}>
                Descargar
              </Button>
              <Steps 
                direction="vertical" 
                current={documents?.findIndex((doc) => doc === selectedDocument) || 0}
              >
                {documentList?.map((doc, index) => (
                  <Steps.Step 
                    key={index} 
                    title={doc.name}
                    description={moment(doc.createdAt).format("DD/MM/YYYY")}
                    onClick={() => setSelectedDocument(doc)} 
                  />
                ))}
              </Steps>
            </Flex>
          </Col>
        </Row>
      </Modal>
    );
  };
  

  // **Upload File to S3**
  const uploadFile = async (file, type) => {
    if (!supplier?.objectId) return;

    const fileFormat = file.name.split('.').pop();
    const filename = `${supplier.fullName}_${type}_${uuidv4()}.${fileFormat}`.replace(/ /g, "_");

    // **Initialize file state**
    setFileLists((prev) => ({
      ...prev,
      [type]: [{ name: file.name, percent: 0, status: "uploading", uid: file.uid }],
    }));
    setLoading((prev) => ({ ...prev, [type]: true }));

    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
    };

    try {
      const upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
        const percentCompleted = Math.round((evt.loaded / evt.total) * 100);
        setFileLists((prev) => ({
          ...prev,
          [type]: [{ name: file.name, percent: percentCompleted, status: "uploading", uid: file.uid }],
        }));
      });

      await upload.promise();

      // **Save file metadata in Parse**
      await addFileToParse(filename, supplier, type, file.name);

      // **Update document state**
      const url = await getURL(filename);
      setDocuments((prev) => ({ ...prev, [type]: { url, name: filename } }));

      // **Update UI**
      setFileLists((prev) => ({
        ...prev,
        [type]: [],
      }));
      message.success("Documentos actualizados");
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Error al subir el archivo");
      setFileLists((prev) => ({
        ...prev,
        [type]: [],
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
      fetchDocuments();
    }
  };

  const fetchDocuments = async () => {
    if (!supplier?.objectId) return;
    const query = new Parse.Query("Document");
    query.equalTo("user", { __type: "Pointer", className: "_User", objectId: supplier.objectId });
    query.descending("createdAt");
    try {
      const results = await query.find();
      const docMap = {};
      await Promise.all(results.map(async (doc) => {
        const filename = doc.get("filename");
        const type = doc.get("type");
        const url = await getURL(filename);
        if (!docMap[type]) docMap[type] = [];
        docMap[type].push({ url, name: doc.get("name"), createdAt: doc.createdAt, objectId: doc.id });
      }));
      console.log("Documents:", docMap);
      setDocuments(docMap);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [supplier]);

  return (
    <Row gutter={[24, 0]}>
      <PDFModal {...pdfModal} setOpen={setPdfModal} />
      {documentList.map(({ key, label }) => (
        <Col span={8} key={key}>
          <Card title={label} style={{ marginBottom: 24, borderRadius: 8, boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)" }}>
  <Flex direction="column" align="center" gap={10} justify="space-between">
    {documents[key] && documents[key].length > 0 && (
      <Button 
        type="link" 
        icon={<FilePdfFill size={60} />} 
        onClick={() => setPdfModal({ open: true, documents: documents[key], name: label })}
        style={{ fontSize: "16px", color: "#1890ff" }}
      >
        Ver Documentos
      </Button>
    )}
    <Dragger 
      multiple 
      customRequest={({ file }) => uploadFile(file, key)} 
      fileList={fileLists[key] || []} 
      accept=".pdf" 
      style={{ padding: 20, borderRadius: 8, borderColor: "#1890ff", backgroundColor: "#fafafa" }}
    >
      <p className="ant-upload-drag-icon">
        <Button type="primary" style={{ width: "100%" }}>Subir Archivos</Button>
      </p>
      <p className="ant-upload-text" style={{ fontSize: "14px", color: "#595959" }}>
        Arrastra o haz clic para subir documentos
      </p>
    </Dragger>
  </Flex>
</Card>
        </Col>
      ))}
    </Row>
  );
};

export { SupplierDocuments };
