import React, { useEffect, useState } from "react";
import { Tabs, Space } from "antd";
import SummaryTab from './SummaryTab';
import PaymentTab from './PaymentTab';
import DeliveriesTab from './DeliveriesTab';
import CommentsTab from './CommentsTab';
import { SaleDocuments } from "./SaleDocuments";
import Parse from "parse";

const { TabPane } = Tabs;

const fetchPresentations = async (products) => {
  const Presentation = Parse.Object.extend("Presentation");
  const query = new Parse.Query(Presentation);
  query.containedIn("objectId", products.map((product) => product.presentation.objectId));
  const result = (await query.find()).map((presentation) => presentation.toJSON());
  let newProducts = products.map((product) => {
    const presentation = result.find((presentation) => presentation.objectId === product.presentation.objectId);
    return { ...product, presentation };
  });
  return newProducts;
};

const fetchSales = async (objectId) => {
  const Sale = Parse.Object.extend("Sales");
  const query = new Parse.Query(Sale);
  query.equalTo("objectId", objectId);
  query.include(["seller", "client", "client.address", "client.fiscalInformation", "payments", "deliveries", "comments"]);
  const result = await query.first();
  let sale = result.toJSON();
  sale.products = await fetchPresentations(sale.products);
  return sale;
};


const TabsContainer = ({ sale, setOpenPayment, setPayment, setShowDelivery, setDelivery, setOpenWarehouseOrder, refresh, renderId, setRefresh }) => {
  const [data, setData] = useState(sale);
  const [tab, setTab] = useState('summary');
  const [refreshInfo, setRefreshInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSales(sale.objectId);
      setData(result);
      refresh();
    };
    fetchData();

  }, [refreshInfo]);

  const refreshModal = () => {
    setRefreshInfo(!refreshInfo);
    refresh();
  }

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Tabs defaultActiveKey="summary" onChange={(key) => setTab(key)}>
          <TabPane tab="Resumen" key="summary" />
          <TabPane tab="Pagos" key="payments" />
          <TabPane tab="Entregas" key="deliveries" />
          <TabPane tab="Documentos" key="documents" />
          <TabPane tab="Comentarios" key="comments" />
        </Tabs>
      </Space>
      {tab === "summary" && <SummaryTab sale={data} refresh={refreshModal} />}
      {tab === "payments" && <PaymentTab sale={data} setOpenPayment={setOpenPayment} setPayment={setPayment} refresh={refreshModal} />}
      {tab === "deliveries" && (
        <DeliveriesTab
          refresh={refreshModal}
          sale={data}
          setShowDelivery={setShowDelivery}
          setDelivery={setDelivery}
          setOpenWarehouseOrder={setOpenWarehouseOrder}
        />
      )}
      {tab === "documents" && <SaleDocuments sale={data} />}
      {tab === "comments" && <CommentsTab sale={data} refresh={refreshModal} />}
    </>
  );
};

export default TabsContainer;
