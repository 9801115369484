import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, Space, Table, Flex, Tooltip, Badge, message, InputNumber } from 'antd';
import CameraModal from '../../../media/Camera';
import Ribbon from 'antd/es/badge/Ribbon';
import Parse from 'parse';

const NewInventoryEntry = ({ open, setOpen, entryInfo, refresh }) => {
    const [form] = Form.useForm();
    const [entry, setEntry] = useState(entryInfo);
    const [images, setImages] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);

    useEffect(() => {
        if (!open) {
            setEntry(null);
            setImages([]);
            form.resetFields();
        }
        else {
            setEntry(entryInfo);
            setImages([]);
        }
    }, [open]);


    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            console.log("📦 Form values:", values);
        } catch (error) {
            console.error("Error during form validation:", error);
            message.error("Por favor complete los campos requeridos.");
            return;
        }


        try {
            // Validate form fields
            const values = await form.validateFields();
            // Prepare the WarehouseEntry object
            const WarehouseEntry = Parse.Object.extend("WarehouseEntry");
            const entryObject = new WarehouseEntry();
            entryObject.set("objectId", entryInfo.objectId);
            entryObject.set("status", "Recibido");
            entryObject.set("receivedBy", Parse.User.current());
            entryObject.set("receptionDate", new Date());
            entryObject.set("images", images);
            entryObject.set("products", entry.products.map((product) => {
                return {
                    ...product,
                    purchaseUnitReceived: parseInt(product.purchaseUnitReceived),
                };
            }));
            const vehicleInfo = {
                vehicle: values.vehicle,
                plates: values.plates,
                driver: values.driver,
                carrier: values.carrier,
                comments: values.comments,
            };
            entryObject.set("vehicleInfo", vehicleInfo);
            entryObject.set("comments", values.comments);
    
            // Save the object
            await entryObject.save();
    
            // Success handling
            message.success('Entrada de almacén creada correctamente');
            refresh();
            setOpen(false);
        } catch (error) {
            // Error handling
            console.error("Error during handleSubmit:", error);
    
            // Error message for form validation failure or save failure
            if (error instanceof Parse.Error) {
                message.error("Error al guardar la entrada de almacén.");
            } else {
                message.error("Error al validar los campos del formulario.");
            }
        }
    };
    
    console.log("📦 Entry: ", entry);

    useEffect(() => {
        const products = entryInfo?.products.map((product) => {
            return {
                ...product,
                purchaseUnitReceived: 0,
            };
        });
        setEntry({ ...entryInfo, products });
    }, [entryInfo, open]);

    const allowSave = () => {
        if (!entry) return false;
        if (!entry.products) return false;
        if (entry.products.length === 0) return false;
        }

    return (
        <Modal
            title="Nueva entrada de inventario"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
           <h2>Orden de compra: {entry?.purchaseOrder?.purchaseOrderNumber}</h2>
           <h3>Proveedor: {entry?.purchaseOrder?.supplier?.fullName}</h3>
           <Table
                dataSource={entry?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => (
                            <p>{text}</p>
                        ),
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "purchaseUnitQuantity",
                        key: "purchaseUnitQuantity",
                        render: (text, product) => (
                            <p>{text} {product.presentation?.name?.toLowerCase()}{text !== 1 ? 's' : ''}</p>
                        ),
                    },
                    
                    {
                        title: 'Recibido',
                        dataIndex: 'purchaseUnitReceived',
                        key: 'purchaseUnitReceived',
                        render: (text, product) => (
                            <Form.Item
                                name="purchaseUnitReceived"
                                rules={[ { required: true, message: 'Por favor ingrese la cantidad recibida' } ]}
                            >
                            <InputNumber
                                disabled={parseInt(product.purchaseUnitQuantity) <= 0}
                                style={{ color: product.purchaseUnitQuantity === parseFloat(text) ? 'green' : 'red', width: 200 }}
                                suffix={product.presentation?.name + (parseInt(text) !== 1 ? 's' : '')}
                                min={0}
                                onChange={(value) => {
                                    const products = entry.products.map((p) => {
                                        if (p.objectId === product.objectId) {
                                            return {
                                                ...p,
                                                purchaseUnitReceived: value,
                                            };
                                        }
                                        return p;
                                    });
                                    setEntry({ ...entry, products });
                                }}
                            />
                            </Form.Item>
                        )
                    }
                ]}
                rowKey={(record, index) => index}
                pagination={false}
            />
             <h3>Detalles de entrega</h3>
                        <Form.Item
                            label="Vehículo"
                            name="vehicle"
                            rules={[ { required: true, message: 'Por favor ingrese el vehículo' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Placas"
                            name="plates"
                            rules={[ { required: true, message: 'Por favor ingrese las placas' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Conductor"
                            name="driver"
                            rules={[ { required: true, message: 'Por favor ingrese el nombre del conductor' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Empresa de transporte"
                            name="carrier"
                            rules={[ { required: true, message: 'Por favor ingrese la empresa de transporte' } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Observaciones"
                            name="comments"
                        >
                            <Input.TextArea />
                        </Form.Item>
            <Button type="primary" onClick={() => setOpenCamera(true)}>Agregar foto</Button>
            <Flex direction="horizontal" style={{ marginTop: 20, overflowX: 'scroll' }}>
                {images && images.map((image, index) => (
                    <Space direction="vertical" style={{ marginRight: 10, paddingTop: 10 }}>
                        <Badge title="Eliminar" onClick={() => setImages(images.filter((_, i) => i !== index))} count="X" style={{ cursor: 'pointer' }}>
                            <img key={index} src={image} alt={`Foto ${index}`} style={{ width: 90, height: 160, objectFit: 'cover' }} />
                        </Badge>
                    </Space>
                ))}
            </Flex>
            <CameraModal open={openCamera} setOpen={setOpenCamera} onImageTook={setImages} />
            </Form>
        </Modal>
    );
}

export default NewInventoryEntry;
