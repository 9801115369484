import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, message } from "antd";
import { calculatePending, allowSave } from "./utils";
import { addIngredients } from "./services";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { UserContext } from "../../../../../context/UserContext";

const ForageAdding = ({ production, refresh }) => {
    const [ingredients, setIngredients] = useState([]);
    const [waitingWeight, setWaitingWeight] = useState(0);
    const { settings } = React.useContext(SettingsContext);
    const { permissions } = React.useContext(UserContext);

    const productionSettings = settings?.find((setting) => setting.name === "production");
    const maxVariation = productionSettings?.values?.find((value) => value.name === "maxProductionVariation")?.value/100;


    // Load and process data when `production` changes
    useEffect(() => {
        if (production) {
            const waitingEvent = production?.productionEvents?.find(
                (event) => event.event === "weightWaitingHopper"
            );
            const waitingHopperWeight = waitingEvent?.weights?.waitingHopperWeight || 0;
            setWaitingWeight(waitingHopperWeight);

            const initialIngredients = production.recipe.ingredients
                .filter((ingredient) => ingredient.warehouse === "Producción")
                .map((ingredient) => ({
                    ...ingredient,
                    pending: 0,
                    adding: 0,
                }));

            const updatedIngredients = calculatePending({ ingredients: initialIngredients, production });
            setIngredients(updatedIngredients);
        }
    }, [production]);


   const handleInputChange = (e, record) => {
        const variationOverride = permissions?.find((permission) => permission.group === "production" && permission.action === "overrideVariation");
        const value = parseFloat(e.target.value) || 0;
           const ingredient = ingredients.find((i) => i.objectId === record.objectId);
           const totalAdded = ingredient.quantity - ingredient.pending + value;
           if (totalAdded > ingredient.quantity + (ingredient.quantity * maxVariation) && !variationOverride)
               {
                   message.error("La cantidad a agregar excede la variación permitida");
                   setIngredients((prev) =>
                       prev.map((ingredient) =>
                           ingredient.objectId === record.objectId
                               ? { ...ingredient, adding: 0 }
                               : ingredient
                       )
                   );
                   return;
               }
            else if (value < 0) {
               message.error("La cantidad a agregar no puede ser negativa");
               setIngredients((prev) =>
                   prev.map((ingredient) =>
                       ingredient.objectId === record.objectId
                           ? { ...ingredient, adding: 0 }
                           : ingredient
                   )
               );
               return;
              }
              else if (totalAdded > ingredient.quantity*2) {
               message.error("La cantidad a agregar no puede ser mayor al doble de la cantidad requerida");
               setIngredients((prev) =>
                   prev.map((ingredient) =>
                       ingredient.objectId === record.objectId
                           ? { ...ingredient, adding: 0 }
                           : ingredient
                   )
               );
               return;
              }
           setIngredients((prev) =>
               prev.map((ingredient) =>
                   ingredient.objectId === record.objectId
                       ? { ...ingredient, adding: value }
                       : ingredient
               )
           );
       };

    // Save added ingredients
    const handleSave = async () => {
        const ingredientsToAdd = ingredients
            .filter((ingredient) => ingredient.adding > 0)
            .map((ingredient) => ({
                objectId: ingredient.objectId,
                quantity: ingredient.adding,
                name: ingredient.name,
            }));

        try {
            await addIngredients({ production, ingredients: ingredientsToAdd });
            message.success("Ingredientes agregados correctamente");
            refresh();
        } catch (error) {
            console.error("Error adding ingredients:", error);
            message.error("Error al agregar ingredientes.");
        }
    };

    return (
        <Row gutter={16}>
            {/* Ingredients Table */}
            <Col span={24}>
                <Table
                    dataSource={ingredients}
                    rowKey="objectId"
                    columns={[
                        {
                            title: "Ingrediente",
                            dataIndex: "name",
                            key: "name",
                        },
                        {
                            title: "Requerido",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (text, record) => `${text} ${record.basePresentation}`,
                        },
                        {
                            title: "Faltante",
                            dataIndex: "pending",
                            key: "pending",
                            render: (text, record) => `${text} ${record.basePresentation}`,
                        },
                        {
                            title: "Agregar",
                            dataIndex: "adding",
                            key: "adding",
                            render: (text, record) => (
                                <Input
                                    disabled={record.pending === 0}
                                    type="number"
                                    suffix="kg"
                                    value={record.adding}
                                    onChange={(e) => handleInputChange(e, record)}
                                />
                            ),
                        },
                    ]}
                    pagination={false}
                    summary={() => {
                                            let required = 0;
                                            let pending = 0;
                                            let adding = 0;
                                            
                                            ingredients.forEach((ingredient) => {
                                                required += ingredient.quantity;
                                                pending += ingredient.pending;
                                                adding += ingredient.adding;
                                            });
                    
                                            return (
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>{required} kg</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>{pending} kg</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}>{adding} kg</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            );
                                        }}
                />
            </Col>

            {/* Save Button */}
            <Col span={24}>
                <Button
                    type="primary"
                    disabled={!allowSave(ingredients) || production?.closed}
                    onClick={handleSave}
                    style={{ marginTop: 16 }}
                >
                    Agregar ingredientes
                </Button>
            </Col>

            
        </Row>
    );
};

export default ForageAdding;