import Parse from "parse";
import { message } from "antd";

// Function to update stock and save stock movements
const updateStockAndMovements = async ({ presentations, productionEvent }) => {
    if (!presentations || presentations.length === 0 || !productionEvent) {
        throw new Error(
            "Invalid input to updateStockAndMovements: missing presentations or productionEvent"
        );
    }

    const Presentation = Parse.Object.extend("Presentation");
    const StockMovement = Parse.Object.extend("StockMovement");

    const updates = presentations.map(async (pres) => {
        let { presentation } = pres;
        presentation.quantity = pres.quantity;
        console.log("Updating stock for presentation:", presentation, presentation.objectId);
        try {
            // Ensure `presentation.objectId` is valid
            if (!presentation.objectId) {
                throw new Error("Presentation objectId is missing or invalid.");
            }

            // Fetch the presentation
            const presentationQuery = new Parse.Query(Presentation);
            const presentationResult = await presentationQuery.get(presentation.objectId);

            if (!presentationResult) {
                throw new Error(`Presentation not found for objectId: ${presentation.objectId}`);
            }

            // Update stock
            const previousStock = presentationResult.get("stock") || 0;
            const newStock = previousStock + (presentation.quantity || 0);

            presentationResult.set("stock", newStock);
            await presentationResult.save();

            console.log(
                `Updated stock for Presentation ${presentation.objectId}: Previous = ${previousStock}, New = ${newStock}`
            );

            // Create stock movement
            const stockMovement = new StockMovement();
            stockMovement.set("presentation", {
                __type: "Pointer",
                className: "Presentation",
                objectId: presentation.objectId,
            });
            stockMovement.set("quantity", presentation.quantity);
            stockMovement.set("type", "Envasado");
            stockMovement.set("warehouse", "Producción");

            // Ensure productionEvent is valid
            if (!productionEvent.id) {
                throw new Error("ProductionEvent is not saved or missing an ID.");
            }

            stockMovement.set("productionEvent", {
                __type: "Pointer",
                className: "ProductionEvents",
                objectId: productionEvent.id,
            });
            stockMovement.set("previousStock", previousStock);
            stockMovement.set("currentStock", newStock);
            stockMovement.set("user", Parse.User.current());

            await stockMovement.save();

            console.log(`StockMovement created for Presentation ${presentation.objectId}`);
        } catch (error) {
            console.error(
                "Error updating stock or movements for presentation:",
                presentation,
                error
            );
            throw error; // Re-throw error to ensure the parent Promise.all fails
        }
    });

    return Promise.all(updates); // Wait for all updates to complete
};

// Function to update the production order
const updateProductionOrder = async ({ production, productionEvent, presentation }) => {
    if (!production || !productionEvent) {
        throw new Error("Invalid input to updateProductionOrder: missing production or productionEvent");
    }

    try {
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const query = new Parse.Query(ProductionOrder);
        const productionOrder = await query.get(production.objectId);

        if (!productionOrder) {
            throw new Error(`ProductionOrder not found for objectId: ${production.objectId}`);
        }

        const previousEvents = productionOrder.get("productionEvents") || [];
        productionOrder.set("productionEvents", [
            ...previousEvents,
            { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
        ]);
        productionOrder.increment("packaging", presentation.quantity);
        productionOrder.increment('produced', presentation.quantity * presentation.content);

        await productionOrder.save();
    } catch (error) {
        console.error("Error updating production order:", error);
        throw error;
    }
};

// Centralized addPackaging function
const addPackaging = async ({ production, presentations }) => {
    if (!production || !presentations || presentations.length === 0) {
        message.error("Entrada inválida para addPackaging: producción o presentaciones faltantes.");
        return;
    }

    console.log("Adding packaging for production:", production, "with presentations:", presentations);

    let presentation = presentations[0].presentation;
    presentation.quantity = presentations[0].quantity;

    try {
        // Create a new production event
        const ProductionEvent = Parse.Object.extend("ProductionEvents");
        const productionEvent = new ProductionEvent();

        productionEvent.set("event", "addPackaging");
        productionEvent.set("warehouse", "Envasado");
        productionEvent.set("productionOrder", {
            __type: "Pointer",
            className: "ProductionOrder",
            objectId: production.objectId,
        });

        // Calculate total quantity and volume
        const totalQuantity = presentations.reduce((acc, p) => acc + (p.quantity || 0), 0);
        const totalVolume = presentations.reduce(
            (acc, p) => acc + (p.presentation?.content || 0) * (p.quantity || 0),
            0
        );

        productionEvent.set("quantity", totalQuantity);
        productionEvent.set("volume", totalVolume);
        productionEvent.set("user", Parse.User.current());
        productionEvent.set("presentation", {__type: "Pointer", className: "Presentation", objectId: presentations[0].presentation.objectId});
        productionEvent.set("product", {__type: "Pointer", className: "Product", objectId: production.product.objectId});

        await productionEvent.save();

        // Update stock and create stock movements
        await updateStockAndMovements({ presentations, productionEvent });

        // Update production order
        await updateProductionOrder({ production, productionEvent, presentation });

        message.success("El envasado se agregó exitosamente.");
    } catch (error) {
        console.error("Error in addPackaging:", error);
        message.error("Ocurrió un error al agregar el empaque. Intente de nuevo.");
        throw error;
    }
};

export { addPackaging };