import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const columns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <h3>{moment(text?.iso).format("DD/MM/YYYY")}</h3>,
    sorter: (a, b) => moment(a.createdAt.iso).unix() - moment(b.createdAt.iso).unix(),
  },
  {
    title: "Orden de compra",
    dataIndex: "purchaseOrderNumber",
    key: "purchaseOrderNumber",
    render: (text) => <h3>{text}</h3>,
    sorter: (a, b) => a.purchaseOrderNumber.localeCompare(b.purchaseOrderNumber),
  },
  {
    title: "Producto",
    dataIndex: "products",
    key: "products",
    render: (products) => <h3>{products?.map((product) => product.name).join(", ")}</h3>,
  },
  {
    title: "Cantidad",
    dataIndex: "products",
    key: "products",
    render: (products) => (
      <h3>
        {products?.map((product) =>
          `${product.quantity} ${product?.presentation?.name}${
            product.quantity !== 1 ? "s" : ""
          }`
        ).join(", ")}
      </h3>
    ),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (total) =>
      <h3>${total?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}</h3>,
  },
];

const options = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: ["#1890FF", "#B37FEB"],
    width: 3,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      offsetY: -50,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
    },
    categories: [], // Categories will be dynamically filled
  },
  grid: {
    show: true,
    borderColor: "rgba(0, 0, 0, .2)",
    strokeDashArray: 6,
    position: "back",
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

const SupplierPurchases = ({ supplier }) => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const getPurchaseOrders = async () => {
      setLoading(true);
      const purchases = supplier.purchases || [];
      setPurchases(purchases);

      // Generate chart data
      const months = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun", 
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
      ];
      const purchaseData = new Array(12).fill(0);
      const kilosData = new Array(12).fill(0);

      purchases.forEach((purchase) => {
        const month = moment(purchase.createdAt.iso).month(); // Get the month index
        purchaseData[month] += purchase.total || 0; // Sum total in pesos
        purchase.products?.forEach((product) => {
          kilosData[month] += product.quantity * product.presentation.quantity; // Sum kilos
        });
      });

      setSeries([
        { name: "Compras en pesos", data: purchaseData },
        { name: "Kilos", data: kilosData },
      ]);

      options.xaxis.categories = months; // Update categories for the chart
      setLoading(false);
    };

    getPurchaseOrders();
  }, [supplier]);

  return (
    <Row gutter={[24, 0]}>
      <Col span={32} lg={24} className="zindex">
        <Card
          style={{ padding: 20 }}
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2>Compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <div className="linechart">
              <div></div>
              <div className="sales">
                <ul>
                  <li>{<MinusOutlined />} Kilos</li>
                  <li>{<MinusOutlined />} Pesos</li>
                </ul>
              </div>
            </div>

            <ReactApexChart
              className="full-width"
              options={options}
              series={series}
              type="area"
              height={350}
              width={"100%"}
            />
          </div>
        </Card>
      </Col>
      <Col span={32} lg={24} className="zindex">
        <Card
          bordered={false}
          bodyStyle={{ padding: "0px" }}
          style={{ padding: 20 }}
          className="criclebox cardbody mb-24 header-solid"
          title={<h2> Detalle de compras</h2>}
        >
          <div className="ant-list-box table-responsive">
            <Table
              style={{ width: "100%" }}
              dataSource={purchases}
              columns={columns}
              rowKey={(record, index) => index}
              pagination={true}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export { SupplierPurchases };