import React, { useEffect, useState, useContext } from "react";
import { Typography, Button, notification, message, Modal, Tag, Flex, Steps } from "antd";
import Parse from "parse/lib/browser/Parse";
import { Printer, Envelope, Whatsapp, Pencil } from "react-bootstrap-icons";
import { UserContext } from "../../../../context/UserContext";
import moment from "moment";

import NewQuote from "./NewQuote";
import TabsContainer from "./SalesTabs/TabsContainer";

const { Title } = Typography;

const fetchPresentations = async (products) => {
    const Presentation = Parse.Object.extend('Presentation');
    const query = new Parse.Query(Presentation);
    query.containedIn('objectId', products.map((product) => product.presentation.objectId));
    const results = (await query.find()).map((result) => result.toJSON());
    let newProducts = products.map((product) => {
        const presentation = results.find((r) => r.objectId === product.presentation.objectId);
        return {
            ...product,
            presentation,
        };
    });
    return newProducts;
}

const SaleDetail = ({ open, setOpen, sale, refresh, shouldRefresh, setSale }) => {
    const [tab, setTab] = useState("summary");
    const [renderId, setRenderId] = useState(0);
    const [printing, setPrinting] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [sales, setSales] = useState([]);
    const [editing, setEditing] = useState(false);
    const { permissions } = useContext(UserContext);

    const allowEdit = () => {
        if (!sale) return false;
        if (sales?.some((s) => s.status === 'Venta')) return false;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyAll')) return true;
        if (permissions.some((p) => p.group === 'quotes' && p.action === 'modifyOwn')) {
            if (sale.seller.objectId === Parse.User.current().id) return true;
        }
        return false;
    }


    const fetchAllVersions = async () => {
        if (!sale) return;
        const Sale = Parse.Object.extend('Sales');
        const query = new Parse.Query(Sale);
        query.equalTo('quoteNumber', sale.quoteNumber);
        query.ascending('createdAt');
        query.include(['client', 'seller', 'payments', 'outlets', 'comments', 'client.address', "products", "products.presentation"]);
        const results = await query.find();
        console.log("📦 Results: ", results);
        const sales = results?.map((result) => result.toJSON());
        for (let i = 0; i < sales.length; i++) {
            sales[i].products = await fetchPresentations(sales[i].products);
        }
        console.log("📦 Sales: ", sales);
        setSales(sales);
    }

    useEffect(() => {
       setRenderId(Math.random());
    }, [shouldRefresh]);

    useEffect(() => {
        fetchAllVersions();
    }, [sale]);

  



    const print = async () => {
        const numberOfProducts = sale.products.reduce((acc, product) => acc + product.quantity, 0);
        console.log("📦 Products: ", sale.products);
        setPrinting(true);
        const params = {
            customerName: sale.client.fullName,
            deliveryAddress: sale.deliveryAddress?.address,
            invoiceId: sale.status === 'Venta' ? sale.saleNumber + '-' + sale.version : sale.quoteNumber + '-' + sale.version,
            invoiceDate: sale.createdAt,
            items: sale.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  sale.deliveryPrice ? parseFloat(product.price) + (sale.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(sale.total),
            deliveryDate: sale.deliveryDate.iso,
            deliveryPlace: sale?.deliveryPlace,
            type: sale.status === 'Venta' ? 'invoice' : 'quote',
            link: 'https://app.dasnerpetcare.com/sales/AcceptQuote/' + sale.uuid,
            useCardStyle: true,
        };

        try {
        const data = await Parse.Cloud.run('printQuote', params);
       
            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );
        }
        catch (error) {
            // Catch if pop-up is blocked
            console.error('Error opening PDF:', error);
            notification.error({
                message: 'Error',
                description: 'Permite las ventanas emergentes para este sitio e intenta de nuevo',
            });
        }
        finally {
            setPrinting(false);
        }
    }

    const sendEmail = async () => {
        setSendingEmail(true);
        const numberOfProducts = sale.products.reduce((acc, product) => acc + product.quantity, 0);

        const info = {
            customerName: sale.client.fullName,
            deliveryAddress: sale.deliveryAddress?.address,
            invoiceId: sale.saleNumber + '-' + sale.version || sale.quoteNumber + '-' + sale.version,
            invoiceDate: sale.createdAt,
            items: sale.products.map((product) => ({
                // name: product.name,
                name: product.presentation.name,
                quantity: product.quantity,
                price:  sale.deliveryPrice ? parseFloat(product.price) + (sale.deliveryPrice/numberOfProducts) : parseFloat(product.price),
                tax: isNaN(parseFloat(product.tax)) ? 0 : parseFloat(product.tax),
            })),
            totalAmount: parseFloat(sale.total),
            deliveryDate: sale.deliveryDate.iso,
            deliveryPlace: sale?.deliveryPlace,
            useCardStyle: true,
        };

        const params = {
            to: sale.client.email,
            subject: 'Cotización Dasner ' + (sale.quoteNumber),
            text: 'Por favor, encuentre la cotización adjunta.',
            info,
            filename: 'Cotización Dasner ' + (sale.quoteNumber) + '.pdf',
            url: 'https://app.dasnerpetcare.com/sales/AcceptQuote/' + sale.uuid,
            type: 'quote',
        };

        try {
        const response = Parse.Cloud.run('sendMail', params);
        message.success('Correo enviado correctamente');
        }
        catch (error) {
            console.error('Error sending email:', error);
            notification.error({
                message: 'Error',
                description: 'Error al enviar el correo',
            });
        }
        finally {
            setSendingEmail(false);
        }
    }

    const sendWhatsapp = async () => {
        const params = {
            phoneNumber: sale.client.whatsappNumber,
            link: 'https://app.dasnerpetcare.com/sales/AcceptQuote/' + sale.uuid,
        };

        try {
        const response = Parse.Cloud.run('quoteReceived', params);
        message.success('Mensaje enviado correctamente');
        }
        catch (error) {
            console.error('Error sending WhatsApp:', error);
            notification.error({
                message: 'Error',
                description: 'Error al enviar el mensaje',
            });
        }
    }

    const OwnButton = ({ icon, children, ...props }) => {
        const [showLabel, setShowLabel] = useState(false);
        return <Button {...props} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>{icon}{showLabel && children}</Button>
    }

    const buttons = () => 
        <Flex justify="end" gap={10} style={{ marginBottom: 10 }}>
            {sale?.active && <OwnButton icon={<Printer />} onClick={print} loading={printing}>Imprimir</OwnButton>}
            {sale?.active && <OwnButton icon={<Envelope />} onClick={() => sendEmail()} loading={sendingEmail}>Enviar por correo</OwnButton>}
            {sale?.active && <OwnButton icon={<Whatsapp />} onClick={() => sendWhatsapp()}>Enviar por WhatsApp</OwnButton>}
            {allowEdit() && <OwnButton icon={<Pencil />} onClick={() => setEditing(true)}>Editar</OwnButton>}
        </Flex>

if (!sale) return null;

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(null)}
            footer={null}
            closable={false}
            style={{ top: 20, width: "90%", height: "90%" }}
            width="90%"
            height="90%"
        >
            <NewQuote open={editing} setOpen={setEditing} sale={sale} refresh={refresh} editing refreshSale={fetchAllVersions} versionNumbner={sales.length} />
            <Flex justify="between">
                <Flex justify="space-between" align="flex-start" vertical style={{ width: "100%" }}>
                    <Tag color="blue">{sale?.status}</Tag>
                    <Title level={4}>Detalle de la venta</Title>
                </Flex>
                {buttons()}
            </Flex>
            <Flex justify="between" style={{ marginBottom: 10 }}>
               
            <Flex justify="between" vertical style={{ marginBottom: 10, width: "80%" }}>
            <TabsContainer tab={tab} setTab={setTab} sale={sale} refresh={refresh} renderId={renderId} setRefresh={setRenderId} />
           
            </Flex>
            <Flex justify="flex-start" style={{ marginTop: 10, marginLeft: 10 }} align="start" vertical>
                <Title level={5}>Versión</Title>
                <Steps current={sales.findIndex((s) => s.objectId === sale.objectId)} direction="vertical" style={{ width: "100%" }}>
                    {sales.map((s) => (
                        <Steps.Step title={moment(s.createdAt).format("DD/MM/YYYY HH:mm")} description={s.modificationComment} onClick={() => setSale(s)} />
                    ))}
                </Steps>
                </Flex>
            </Flex>
        </Modal>
    );
}

export default SaleDetail;
               