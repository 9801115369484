import Parse from "parse";
import { message } from "antd";

// Helper to ensure a value is numeric
const ensureNumber = (value) => (isNaN(parseFloat(value)) ? 0 : parseFloat(value));

const updateStockAndMovements = async ({ production, product, ingredient, quantity, type }) => {
    if (!production || (!product && !ingredient)) {
        throw new Error("Falta información: producción, producto o ingrediente.");
    }

    console.log(`Actualizando stock y movimientos para:`);
    console.log(`Producción ID: ${production.objectId}`);
    console.log(`Producto ID: ${product}`);
    console.log(`Ingrediente ID: ${ingredient}`);
    console.log(`Cantidad: ${quantity}, Tipo: ${type}`);

    const StockMovement = Parse.Object.extend("StockMovement");

    try {
        if (ingredient) {
            const Ingredient = Parse.Object.extend("Ingredient");
            const ingredientQuery = new Parse.Query(Ingredient);
            const ingredientResult = await ingredientQuery.get(ingredient);

            if (!ingredientResult) {
                throw new Error(`Ingrediente no encontrado: ${ingredient}`);
            }

            const previousStock = ingredientResult.get("stock") || 0;
            const newStock = previousStock - ensureNumber(quantity);

            ingredientResult.set("stock", newStock);
            await ingredientResult.save();

            console.log(`Stock actualizado para ingrediente ${ingredient}: Anterior = ${previousStock}, Nuevo = ${newStock}`);
        }

        const stockMovement = new StockMovement();
        ingredient && stockMovement.set("ingredient", { __type: "Pointer", className: "Ingredient", objectId: ingredient });
        product && stockMovement.set("product", { __type: "Pointer", className: "Product", objectId: product });
        stockMovement.set("quantity", ensureNumber(quantity));
        stockMovement.set("warehouse", "Producción");
        stockMovement.set("user", Parse.User.current());
        stockMovement.set("type", "Merma");

        await stockMovement.save();
        console.log(`Movimiento de stock guardado para ${ingredient ? "Ingrediente" : "Producto"}: ${ingredient || product}`);
    } catch (error) {
        console.error("Error actualizando stock o movimientos:", error);
        throw error;
    }
};

const updateProductionOrder = async ({ production, productionEvent, type, quantity }) => {
    if (!production || !productionEvent) {
        throw new Error("Falta información: producción o evento de producción.");
    }

    console.log(`Actualizando orden de producción ID: ${production.objectId}, Tipo: ${type}, Cantidad: ${quantity}`);

    try {
        const ProductionOrder = Parse.Object.extend("ProductionOrder");
        const query = new Parse.Query(ProductionOrder);
        const productionOrder = await query.get(production.objectId);

        if (!productionOrder) {
            throw new Error(`Orden de producción no encontrada para ID: ${production.objectId}`);
        }

        const previousEvents = productionOrder.get("productionEvents") || [];
        productionOrder.set("productionEvents", [
            ...previousEvents,
            { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
        ]);

        if (type === "product") {
            productionOrder.increment("produced", ensureNumber(quantity));
        }

        await productionOrder.save();
        console.log(`Orden de producción actualizada correctamente: ${production.objectId}`);
    } catch (error) {
        console.error("Error actualizando la orden de producción:", error);
        throw error;
    }
};

// Centralized addShrinkage function
const addShrinkage = async ({ production, product, ingredient, quantity, reason, type }) => {
    if (!production || (!product && !ingredient) || !quantity || !reason) {
        message.error("Por favor, complete todos los campos obligatorios.");
        return;
    }

    console.log(`Registrando merma para producción ID: ${production.objectId}`);
    console.log(`Producto: ${product}, Ingrediente: ${ingredient}`);
    console.log(`Cantidad: ${quantity}, Motivo: ${reason}, Tipo: ${type}`);

    const Shrinkage = Parse.Object.extend("Shrinkage");
    const ProductionEvents = Parse.Object.extend("ProductionEvents");

    try {
        // Crear el evento de producción
        const productionEvent = new ProductionEvents();
        productionEvent.set("event", "reportShrinkage");
        productionEvent.set("production", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        product && productionEvent.set("product", { __type: "Pointer", className: "Product", objectId: product });
        ingredient && productionEvent.set("ingredient", { __type: "Pointer", className: "Ingredient", objectId: ingredient });
        productionEvent.set("quantity", ensureNumber(quantity));
        productionEvent.set("reason", reason);
        productionEvent.set("user", Parse.User.current());

        await productionEvent.save();
        console.log(`Evento de producción guardado con ID: ${productionEvent.id}`);

        // Crear el registro de merma
        const shrinkage = new Shrinkage();
        shrinkage.set("productionEvent", { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id });
        product && shrinkage.set("product", { __type: "Pointer", className: "Product", objectId: product });
        ingredient && shrinkage.set("ingredient", { __type: "Pointer", className: "Ingredient", objectId: ingredient });
        shrinkage.set("quantity", ensureNumber(quantity));
        shrinkage.set("reason", reason);
        shrinkage.set("user", Parse.User.current());

        await shrinkage.save();
        console.log(`Merma registrada con ID: ${shrinkage.id}`);

        // Actualizar stock y movimientos
        await updateStockAndMovements({ production, product, ingredient, quantity, type });

        // Actualizar orden de producción
        await updateProductionOrder({ production, productionEvent, type, quantity });

        message.success("Merma agregada exitosamente.");
    } catch (error) {
        console.error("Error agregando la merma:", error);
        message.error("Ocurrió un error al registrar la merma. Intente de nuevo.");
        throw error;
    }
};

export { addShrinkage };