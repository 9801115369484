import React, { useEffect } from "react";
import {
  Tabs
} from "antd";
import { ClientInfo, ClientPurchases, ClientPayments, ClientDocuments } from "./Tabs";
import { Card, Statistic, Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import Parse from "parse";
import Title from "antd/es/typography/Title";

const items = [
  {
    key: "1",
    label: `Información`,
    children: ClientInfo
  },
  {
    key: "2",
    label: `Compras`,
    children: ClientPurchases
  },
  {
    key: "3",
    label: `Pagos`,
    children: ClientPayments
  },
  {
    key: "4",
    label: `Documentos`,
    children: ClientDocuments
  }
]
const StatisticsCard = () => {
<Card title="Estadísticas" style={{ width: 300 }}>
<Row gutter={16}>
  <Col span={12}>
    <Statistic
      title="Activos"
      value={1128}
      valueStyle={{ color: '#3f8600' }}
      prefix={<ArrowUpOutlined />}
      suffix="%"
    />
  </Col>
  <Col span={12}>
    <Statistic
      title="Inactivos"
      value={93}
      valueStyle={{ color: '#cf1322' }}
      prefix={<ArrowDownOutlined />}
      suffix="%"
    />
  </Col>
</Row>
</Card>
};

const fetchClient = async (uuid) => {
  return new Promise((resolve, reject) => {
    const Role = Parse.Object.extend("_Role");
    const roleQuery = new Parse.Query(Role);
    roleQuery.equalTo("name", "Cliente");
    roleQuery.first()
      .then((role) => {
        const relation = role.relation("users");
        const query = relation.query();
        query.include(["address", "fiscalInformation", "fiscalInformation.address", "sales", "payments", "contactPersons"]);
        query.equalTo("uuid", uuid);
        query.first()
          .then((result) => {
            let client = result.toJSON();
            client.sales = client?.sales?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            client.payments = client?.payments?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            resolve(client);
          })
          .catch((error) => {
            console.error("Error al obtener el cliente:", error); // Verifica si hay algún error
            reject(error);
          });
      })
      .catch((error) => {
        console.error("Error al obtener el cliente:", error); // Verifica si hay algún error
        reject(error);
      });
  }
  );
}

const ClientDetails = () => {
  const [client, setClient] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { uuid } = useParams();

  useEffect(() => {
    const getClient = async () => {
      setLoading(true);
      const client = await fetchClient(uuid);
      setClient(client);
      setLoading(false);
    };

    getClient();
  }, [uuid]);

  const getClient = () => {
    fetchClient(uuid).then((client) => {
      setClient(client);
    });
  }
  
  return (
    <div className="layout-content">
      <Card title={<Title level={2}>{client?.fullName}</Title>}>
       {client?.clientType === 'physical' ? <p>Persona física</p> : <p>Persona moral</p>}
      </Card>
      <Tabs defaultActiveKey="1">
        {items.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {client && <item.children client={client} refresh={getClient} />}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default ClientDetails;