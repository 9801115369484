import React, { useEffect } from "react";
import { Button, Space, Typography, Modal, Form, Input, Select, message } from "antd";
import Parse from "parse";

const { Title } = Typography;

const NewIngredient = ({ open, setOpen, refresh, ingredient = null }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (ingredient) {
                // Populate form with existing ingredient data for editing
                form.setFieldsValue({
                    name: ingredient.name,
                    basePresentation: ingredient.basePresentation,
                    presentations: ingredient.presentations || [],
                    warehouse: ingredient.warehouse,
                });
            } else {
                // Reset form for a new ingredient
                form.resetFields();
                form.setFieldsValue({
                    presentations: [],
                });
            }
        }
    }, [open, ingredient]);

    const saveIngredient = async () => {
        try {
            const values = form.getFieldsValue();
            const Ingredient = Parse.Object.extend("Ingredient");
            let ingredientToSave;

            if (ingredient) {
                // If editing an existing ingredient, fetch it
                const query = new Parse.Query(Ingredient);
                ingredientToSave = await query.get(ingredient.objectId);
            } else {
                // If creating a new ingredient
                ingredientToSave = new Ingredient();
                ingredientToSave.set("stock", 0);
                ingredientToSave.set("baseCost", 0);
            }

            // Set fields from form values
            ingredientToSave.set("name", values.name);
            ingredientToSave.set("basePresentation", values.basePresentation);
            ingredientToSave.set("presentations", values.presentations);
            ingredientToSave.set("warehouse", values.warehouse);

            // Save ingredient to Parse server
            await ingredientToSave.save();
            message.success(ingredient ? "Insumo actualizado correctamente" : "Insumo creado correctamente");
            refresh();
            setOpen(false);
        } catch (error) {
            console.error("Error saving ingredient:", error);
            message.error("Error al guardar el insumo");
        }
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(() => {
                saveIngredient();
            })
            .catch((error) => {
                console.log("Validation Failed:", error);
            });
    };

    return (
        <Modal
            title={ingredient ? "Editar insumo" : "Nuevo insumo"}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} name="ingredientForm">
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: "Por favor ingrese el nombre del ingrediente" }]}
                >
                    <Input />
                </Form.Item>

                <Title level={5}>Unidad base</Title>
                <Form.Item
                    name="basePresentation"
                    rules={[{ required: true, message: "Seleccione una presentación base" }]}
                >
                    <Select
                        placeholder="Presentación base"
                        onChange={() => {
                            form.setFieldsValue({
                                presentations: [{ presentation: "", quantity: "" }],
                            });
                        }}
                    >
                        <Select.Option value="Kg">Kg</Select.Option>
                        {/* <Select.Option value="L">L</Select.Option>
                        <Select.Option value="Pieza">Pieza</Select.Option> */}
                    </Select>
                </Form.Item>

                {form.getFieldValue("basePresentation") && <Title level={5}>Presentaciones</Title>}
                <Form.List name="presentations">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, "presentation"]}
                                        rules={[{ required: true, message: "Seleccione una presentación" }]}
                                    >
                                        <Select placeholder="Presentación" style={{ width: 120 }}>
                                            {form.getFieldValue("basePresentation") === "Kg" && (
                                                <>
                                                    <Select.Option value="Costal">Costal</Select.Option>
                                                    <Select.Option value="Litro">Litro</Select.Option>
                                                    <Select.Option value="Tonelada">Tonelada</Select.Option>
                                                    <Select.Option value="Camión">Camión</Select.Option>
                                                    <Select.Option value="Tórton">Tórton</Select.Option>
                                                    <Select.Option value="Trailer">Trailer</Select.Option>
                                                    <Select.Option value="Full">Full</Select.Option>
                                                    <Select.Option value="Rabón">Rabón</Select.Option>
                                                </>
                                            )}
                                            {form.getFieldValue("basePresentation") === "L" && (
                                                <>
                                                    <Select.Option value="Galón">Galón</Select.Option>
                                                    <Select.Option value="Barril">Barril</Select.Option>
                                                </>
                                            )}
                                            {form.getFieldValue("basePresentation") === "Pieza" && (
                                                <>
                                                    <Select.Option value="Caja">Caja</Select.Option>
                                                    <Select.Option value="Paquete">Paquete</Select.Option>
                                                    <Select.Option value="Bolsa">Bolsa</Select.Option>
                                                </>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "quantity"]}
                                        rules={[{ required: true, message: "Ingrese la cantidad" }]}
                                    >
                                        <Input placeholder="Cantidad" suffix={form.getFieldValue("basePresentation")} />
                                    </Form.Item>
                                    <Button onClick={() => remove(name)}>-</Button>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Agregar presentación
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item
                    label="Almacén"
                    name="warehouse"
                    rules={[{ required: true, message: "Por favor seleccione un almacén" }]}
                >
                    <Select placeholder="Almacén">
                        <Select.Option value="Forrajes">Forrajes</Select.Option>
                        <Select.Option value="Producción">Producción</Select.Option>
                        <Select.Option value="Envasado">Envasado</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewIngredient;