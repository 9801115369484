import React, { useEffect, useState } from 'react';
import { Button, Flex, message, Result, Skeleton, Table, Typography, Divider, Card } from 'antd';
import Parse from 'parse';
import { useParams, useNavigate } from 'react-router-dom';
import dasner_logo_black from '../../../images/dasner_logo_black.png';
import moment from 'moment';

const { Title, Text } = Typography;

const formatNumber = (value, locale = 'es-MX') => {
    if (typeof value === 'string') {
        value = value.replace(/[^\d.-]/g, '');
        value = parseFloat(value);
    }
    return isNaN(value) 
        ? new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(0) 
        : new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

const AcceptPurchaseOrder = () => {
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { uuid } = useParams();

    useEffect(() => {
        const fetchPurchaseOrder = async () => {
            setLoading(true);
            try {
                const PurchaseOrder = Parse.Object.extend('PurchaseOrder');
                const query = new Parse.Query(PurchaseOrder);
                query.equalTo('uuid', uuid);
                query.include(['supplier']);

                const response = await query.first();
                if (!response) {
                    setError(true);
                } else {
                    setPurchaseOrder(response.toJSON());
                }
            } catch (e) {
                setError(true);
            }
            setLoading(false);
        };

        fetchPurchaseOrder();
    }, [uuid]);

    if (error) {
        return (
            <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Result status="error" title="Error" subTitle="No se encontró la orden de compra" />
                <Title level={4} style={{ textAlign: 'center' }}>
                    La orden de compra que intentas aceptar no existe o no tienes permisos para verla.
                </Title>
            </Flex>
        );
    }

    if (loading) {
        return (
            <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Skeleton active style={{ width: '80%' }} />
            </Flex>
        );
    }

    if (purchaseOrder?.status === 'Cancelada') {
        return (
            <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px' }}>
                <img src={dasner_logo_black} alt="Dasner" style={{ width: 200, marginBottom: 20 }} onClick={() => navigate('/')} />
                <Result status="error" title="Orden de Compra Cancelada" subTitle="Esta orden de compra ha sido cancelada" />
            </Flex>
        );
    }

    return (
        <Flex vertical align="center" justify="center" style={{ minHeight: '100vh', padding: '20px', maxWidth: '900px', margin: 'auto' }}>
            {/* Logo */}
            <img src={dasner_logo_black} alt="Dasner" style={{ width: 150, marginBottom: 20 }} onClick={() => navigate('/')} />

            {/* Información de la orden de compra */}
            <Card style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
                <Title level={2}>Orden de Compra</Title>
                <Title level={4} style={{ marginBottom: 10 }}>{purchaseOrder?.purchaseOrderNumber}</Title>
                <Text type="secondary">Fecha: {moment(purchaseOrder?.createdAt).format('DD/MM/YYYY')}</Text>
            </Card>

            <Divider />

            {/* Tabla de productos */}
            <Table
                dataSource={purchaseOrder?.products}
                rowKey="objectId"
                pagination={false}
                style={{ width: '100%' }}
                bordered
                columns={[
                    {
                        title: 'Producto',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text) => <Text strong>{text}</Text>,
                        ellipsis: true,
                    },
                    {
                        title: 'Cantidad',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        align: 'center',
                        render: (text) => <Text>{formatNumber(text)}</Text>,
                    },
                    {
                        title: 'Presentación',
                        dataIndex: ['presentation', 'name'],
                        key: 'presentation',
                        align: 'center',
                    },
                    {
                        title: 'Volumen Total',
                        dataIndex: 'total',
                        key: 'total',
                        align: 'center',
                        render: (text, record) => (
                            <Text>
                                {formatNumber(record.purchaseUnitQuantity * record.presentation.quantity)} {record.basePresentation}
                            </Text>
                        ),
                    },
                ]}
            />

            <Divider />

        </Flex>
    );
};

export default AcceptPurchaseOrder;