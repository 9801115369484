import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button, Modal, Checkbox, Tag, message } from "antd";
import Parse from "parse";
import moment from "moment";
import Title from "antd/es/typography/Title";

const CalculateCommissions = ({ seller, sales, open, setOpen, refresh }) => {
    const [commissionPercentages, setcommissionPercentages] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedSales, setSelectedSales] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    
    const calculateCommissions = () => {
        const base = selectedSales.reduce((acc, curr) => acc + curr.total, 0);
        const total = base * seller.commissionPercentage/ 100;
        const newCommission = {
        order: "N/A",
        date: new Date().toISOString(),
        amount: total,
        commission: total,
        };
    
        setcommissionPercentages({ total, sales: selectedSales, commission: newCommission, base });
    };

    useEffect(() => {
        calculateCommissions();
    }, [selectedSales]);

    const createOutpayment = async () => {
        const Outpayment = Parse.Object.extend("Commission");
        const Index = Parse.Object.extend("Index");
        const query = new Parse.Query(Index);
        query.equalTo("name", "Commission");
        const index = await query.first();
        const commissionNumber = index.get("value");
        index.set("value", commissionNumber + 1);

        const outpayment = new Outpayment();
        outpayment.set("seller", { __type: "Pointer", className: "_User", objectId: seller.objectId });
        outpayment.set("amount", commissionPercentages.total);
        outpayment.set("base", commissionPercentages.base);
        outpayment.set("commissionPercentage", seller.commissionPercentage);
        outpayment.set("sales", selectedSales.map((sale) => ({ __type: "Pointer", className: "Sales", objectId: sale.objectId })));
        outpayment.set("status", "Pendiente");
        outpayment.set("number", `COM${(commissionNumber + 1).toString().padStart(4, "0")}-${new Date().getFullYear()}`);

        const Sales = Parse.Object.extend("Sales");
        selectedSales.forEach(async (sale) => {
            const saleObject = new Sales();
            saleObject.set("objectId", sale.objectId);
            saleObject.set("commissionCalculated", true);
            await saleObject.save();
        });

        try {
            await outpayment.save();
            await index.save();
            refresh();
            setModalVisible(false);
            setOpen(false);
            message.success("Orden de pago creada correctamente");
        }
        catch (error) {
            console.error("Error while creating outpayment: ", error);
            message.error("Error al crear la orden de pago");
        }
    }
    
    return (

        <Modal
            title="Calcular Comisión"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={1000}
        >
            <Table
                dataSource={sales?.filter((sale) => sale.status === "Venta" && !sale.commissionCalculated)}
                columns={[
                    {
                        title: "Agregar",
                        dataIndex: "objectId",
                        key: "objectId",
                        render: (text, sale) => (
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedSales([...selectedSales, sale]);
                                    } else {
                                        setSelectedSales(selectedSales.filter((s) => s.objectId !== sale.objectId));
                                    }
                                }}
                            />
                        ),
                    },
                    {
                        title: "Fecha",
                        dataIndex: "createdAt",
                        key: "createdAt",
                        render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
                    },
                    {
                        title: "Folio",
                        dataIndex: "saleNumber",
                        key: "saleNumber",
                    },
                    {
                        title: "Status",
                        dataIndex: "status",
                        key: "status",
                        render: (text) => <Tag color="green">{text}</Tag>,
                    },
                    {
                        title: "Total",
                        dataIndex: "total",
                        key: "total",
                        render: (text) => <h3>{text}</h3>,
                    },
                    {
                        title: "Pagado",
                        dataIndex: "totalPaid",
                        key: "totalPaid",
                        render: (text) => <h3>{text}</h3>,
                    },
                    {
                        title: "Saldo",
                        dataIndex: "balance",
                        key: "balance",
                        render: (text, sale) => <h3>{sale.total - sale.totalPaid}</h3>,
                    },
                    {
                        title: "Estatus",
                        dataIndex: "status",
                        key: "status",
                        render: (text, sale) => sale.total === sale.totalPaid ? <h3>Pagado</h3> : <h3>Pendiente</h3>
                    },
                    {
                        title: "Calculada",
                        dataIndex: "commissionCalculated",
                        key: "commissionCalculated",
                        render: (commissionCalculated) => commissionCalculated ? <h3>Si</h3> : <h3>No</h3>
                    }
                ]}
                pagination={false}
                rowKey={(record) => record.objectId}
                scroll={{ x: true }}
                footer={() => (
                    <Row justify="end">
                        <Col>
                            <Title level={4}>{`Total: $${commissionPercentages.total?.toLocaleString()}`}</Title>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={() => createOutpayment()} disabled={selectedSales.length === 0}>Generar orden de pago</Button>
                        </Col>
                    </Row>
                )}
            />
            
        </Modal>
    );
};

export default CalculateCommissions;