import React, { useEffect } from "react"
import { Row, Col, Card, Table, Space, Statistic, FloatButton, Modal, Select, Button, Input, Flex, message } from "antd"
import Title from "antd/es/typography/Title";
import { UserContext } from "../../../../../context/UserContext";
import Parse from "parse";
import moment from "moment";
// TODO: Implement credit management
const columns = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <p>{text}</p>,
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Fecha de pago',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (text) => text ? moment(text).format("DD/MM/YYYY") : 'Sin fecha',
      sorter: (a, b) => a.paymentDate?.iso.localeCompare(b.paymentDate?.iso),
    },
    {
        title: 'Fecha de vencimiento',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (text) => text ? moment(text).format("DD/MM/YYYY") : 'Sin fecha',
        sorter: (a, b) => a.dueDate?.iso.localeCompare(b.dueDate?.iso),
    },
    {
      title: 'Compra',
      dataIndex: ['purchaseOrder', 'purchaseOrderNumber'],
      key: 'purchaseOrder',
      render: (purchaseOrderNumber, payment) => purchaseOrderNumber
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
        render: (text) => `$${text?.toLocaleString()}`,
    },
    {
      title: 'Cumplimiento',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        if (record.status === 'Cancelado') return ''
        if (!record.paymentDate) {
            return <p style={{ color: 'red' }}>Pendiente</p>
        } else if (!record.dueDate) {
            return <p style={{ color: 'green' }}>Sin fecha de vencimiento</p>
        } else if (moment(record.paymentDate?.iso).diff(moment(record.dueDate?.iso), 'days') > 0) {
            return <p style={{ color: 'red' }}>Vencido por {moment(record.paymentDate?.iso).diff(moment(record.dueDate?.iso), 'days')} días</p>
        } else if (moment(record.paymentDate?.iso).diff(moment(record.dueDate?.iso), 'days') === 0) {
            return <p style={{ color: 'green' }}>A tiempo</p>
        } else {
            return <p style={{ color: 'green' }}>Adelantado por {moment(record.dueDate?.iso).diff(moment(record.paymentDate?.iso), 'days')} días</p>
        }
        }
    },
    ];

  const CreditModal = ({ open, setOpen, supplier }) => {
    const [credit, setCredit] = React.useState([])
    const [addingCredit, setAddingCredit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        if (supplier?.credit) {
            setCredit(supplier.credit)
        } else {
            setCredit([
                { name: 'Anticipo', value: 30, days: 0, from: 'authorizationDate', fromName: 'Fecha de autorización' },
                { name: 'Día de entrega', value: 20, days: 0, from: 'deliveryDate', fromName: 'Fecha de entrega' },
                { name: '30 días', value: 50, days: 30, from: 'deliveryDate', fromName: 'Fecha de entrega' },
            ])
        }
    }, [supplier, open])

    const saveCredit = () => {
        setLoading(true)
        const User = Parse.Object.extend('_User')
        const user = new User()
        user.set('objectId', supplier.objectId)
        user.set('credit', credit)
        user.save().then(() => {
            setLoading(false)
            setOpen(false)
            message.success('Crédito guardado correctamente')
        }).catch(() => {
            setLoading(false)
            message.error('Ocurrió un error al guardar el crédito')
        })  
    }


    return (
        <Modal
            title="Configuración de crédito"
            open={open}
            onOk={saveCredit}
            width={'80%'}
            onCancel={() => setOpen(false)}
            confirmLoading={loading}
            
        >
            <Row>
                <Col span={24}>
                    <Table 
                    footer={() => <Flex justifyContent="space-between" gap={10}>
                    <Input key="name" placeholder="Nombre" onChange={e => setAddingCredit({ ...addingCredit, name: e.target.value })} value={addingCredit.name} />
                    <Input key="value" placeholder="Porcentaje" onChange={e => setAddingCredit({ ...addingCredit, value: e.target.value })} value={addingCredit.value} />
                    <Input key="days" placeholder="Días" onChange={e => setAddingCredit({ ...addingCredit, days: e.target.value })} value={addingCredit.days} />
                    <Select key="from" placeholder="Desde" onChange={value => setAddingCredit({ ...addingCredit, from: value })} value={addingCredit.from} style={{ width: '100%' }}>
                        <Select.Option value="authorizationDate">Fecha de autorización</Select.Option>
                        <Select.Option value="deliveryDate">Fecha de entrega</Select.Option>
                    </Select>
                    <Button onClick={() => {
                        if (!addingCredit.name || !addingCredit.value || !addingCredit.days || !addingCredit.from) {
                            message.error('Todos los campos son requeridos')
                            return
                        }
                        if (isNaN(parseInt(addingCredit.value))) {
                            message.error('El porcentaje debe ser un número')
                            return
                        }
                        if (parseInt(addingCredit.value) < 0 || parseInt(addingCredit.value) > 100) {
                            message.error('El porcentaje debe ser un número entre 0 y 100')
                            return
                        }
                        if (isNaN(parseInt(addingCredit.days))) {
                            message.error('Los días deben ser un número')
                            return
                        }
                        if ((credit.reduce((acc, item) => acc + parseInt(item.value), 0) + parseInt(addingCredit.value)) > 100) {
                            message.error('La suma de los porcentajes no puede ser mayor a 100')
                            return
                        }
                        setCredit([...credit, addingCredit])
                        setAddingCredit({})
                    }}>Agregar</Button>
                </Flex>}
                    columns={[
                        {
                            title: 'Nombre',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record, index) => <Input value={text} onChange={e => {
                                const newCredit = [...credit]
                                newCredit[index].name = e.target.value
                                setCredit(newCredit)
                            }} />
                        },
                        {
                            title: 'Porcentaje',
                            dataIndex: 'value',
                            key: 'value',
                            render: (text, record, index) => <Input value={text} onChange={e => {
                                const newCredit = [...credit]
                                newCredit[index].value = e.target.value
                                setCredit(newCredit)
                            }} />
                        },
                        {
                            title: 'Días',
                            dataIndex: 'days',
                            key: 'days',
                            render: (text, record, index) => <Input value={text} onChange={e => {
                                const newCredit = [...credit]
                                newCredit[index].days = e.target.value
                                setCredit(newCredit)
                            }} />
                        },
                        {
                            title: 'Desde',
                            dataIndex: 'fromName',
                            key: 'fromName',
                            render: (text, record, index) => <Select value={record.from} onChange={value => {
                                const newCredit = [...credit]
                                newCredit[index].from = value
                                setCredit(newCredit)
                            }}>
                                <Select.Option value="authorizationDate">Fecha de autorización</Select.Option>
                                <Select.Option value="deliveryDate">Fecha de entrega</Select.Option>
                            </Select>
                        },
                        {
                            title: 'Eliminar',
                            key: 'action',
                            render: (text, record, index) => <Space>
                                <Button onClick={() => {
                                    const newCredit = [...credit]
                                    newCredit.splice(index, 1)
                                    setCredit(newCredit)
                                }}>Eliminar</Button>
                            </Space>
                        },
                    ]} dataSource={credit} />
                  
                    
                </Col>
            </Row>
        </Modal>
    )
}

  

const SupplierPayments = ({ supplier }) => {
    const { user, permissions } = React.useContext(UserContext)
    const [editCredit, setEditCredit] = React.useState(false)

    const [payments, setPayments] = React.useState([])

   useEffect(() => {
        const fetchPayments = async () => {
            const Outpayment = Parse.Object.extend('Outpayments')
            const query = new Parse.Query(Outpayment)
            query.equalTo('supplier', {__type: 'Pointer', className: 'Suppliers', objectId: supplier.objectId})
            query.include('purchaseOrder')
            query.descending('createdAt')

            const results = await query.find()
            setPayments(results.map(result => result.toJSON()))
        }

        fetchPayments()
    }, [supplier])

    const allowModifyCredit = () => {
        return permissions.some(p => p.group === 'suppliers' && p.action === 'setCredit')
    }

    return (
        <Row gutter={[24, 0]}>
            {/* {allowModifyCredit() && <FloatButton onClick={() => setEditCredit(true)} />} */}
            <CreditModal open={editCredit} setOpen={setEditCredit} supplier={supplier} />
            <Col span={24} lg={50} className="zindex">      
                <Card> 
                <h2>Pagos</h2>
                    <Table columns={columns} dataSource={payments} />
                </Card>
            </Col>
            {/* <Col span={24} lg={50} className="zindex">      
                <Card title={<h2>Crédito</h2>}>
                <Row style={{ justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20}}>
                {supplier?.credit? supplier?.credit?.map((item, index) => (
                    <Col>
                        <Statistic
                            title={item?.name}
                            value={item?.value + '%'}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Col>
                )) : <Title level={5}>Sin crédito asignado</Title>}
                    </Row>
                </Card>
            </Col> */}
        </Row>
    )
}

export { SupplierPayments }