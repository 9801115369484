import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Space, Input, message, Select, InputNumber } from "antd";
import DebounceSelect from "../../../form/DebounceSelect";
import { fetchProducts } from "../utils/query";
import moment from "moment";
import Parse from "parse";

const fetchPresentations = async (objectId) => {
    const Product = Parse.Object.extend("Product");
    const query = new Parse.Query(Product);
    const product = await query.get(objectId);
    return product.toJSON()?.presentations;
};

const ProductList = ({ form, setRenderId, freightChanged, deliveryPlace }) => {
    const [products, setProducts] = useState([]);

    // Initialize products from form values
    useEffect(() => {
        const formProducts = form.getFieldValue("products");
        if (formProducts) {
            setProducts(formProducts);
        }
    }, [form]);

    // Fetch presentations for products if missing
    useEffect(() => {
        const fetchMissingPresentations = async () => {
            const newProducts = await Promise.all(
                products.map(async (product) => {
                    if (!product.presentations) {
                        const presentations = await fetchPresentations(product.objectId);
                        return { ...product, presentations };
                    }
                    return product;
                })
            );
            form.setFieldsValue({ products: newProducts });
            setProducts(newProducts);
            setRenderId((prev) => prev + 1);
        };

        if (products.some((product) => !product.presentations)) {
            fetchMissingPresentations();
        }
    }, [products, form, setRenderId]);

    // Calculate totals and update form values
    const calculateAll = useCallback((field) => {
        const products = form.getFieldValue("products");
        const productsQuantity = products.reduce((acc, p) => acc + (parseInt(p.quantity) || 0), 0);
        const freightPrice = parseFloat(form.getFieldValue("deliveryPrice")) || 0;
        const freightUnitPrice = productsQuantity > 0 ? freightPrice / productsQuantity : 0;
    
        let newProducts = products.map((product) => {
            let price = parseFloat(product.price) || 0;
            let priceWithFreight = price + freightUnitPrice;
            let unitPrice = 0;
    
            if (freightPrice > 0 && deliveryPlace === 'Domicilio') {
                priceWithFreight = parseFloat(product.priceWithFreight) || 0;
                price = priceWithFreight - freightUnitPrice;
            }
    
            const content = parseFloat(product.presentation?.content) || 1; // Evitar división por 0
            unitPrice = price / content;
    
            return {
                ...product,
                quantity: parseInt(product.quantity) || 0,
                price: price.toFixed(2),
                priceWithFreight: priceWithFreight.toFixed(2),
                totalVolume: content * (parseInt(product.quantity) || 0),
                total: (parseInt(product.quantity) || 0) * price,
                totalWithFreight: (parseInt(product.quantity) || 0) * priceWithFreight,
                unitPrice: unitPrice.toFixed(2),
            };
        });
    
        if (newProducts.some((product) => product.price < product.minPrice)) {
            message.error("El precio no puede ser menor al mínimo");
            newProducts = newProducts.map((product) => {
                if (product.price < product.minPrice) {
                    const adjustedPrice = parseFloat(product.minPrice) || 0;
                    const adjustedPriceWithFreight = adjustedPrice + freightUnitPrice;
                    const adjustedUnitPrice = adjustedPrice / (parseFloat(product.presentation?.content) || 1);
                    
                    return {
                        ...product,
                        price: adjustedPrice.toFixed(2),
                        priceWithFreight: adjustedPriceWithFreight.toFixed(2),
                        total: (parseInt(product.quantity) || 0) * adjustedPrice,
                        totalWithFreight: (parseInt(product.quantity) || 0) * adjustedPriceWithFreight,
                        unitPrice: adjustedUnitPrice.toFixed(2),
                    };
                }
                return product;
            });
        }
    
        form.setFieldsValue({ products: newProducts });
        setProducts(newProducts);
        setRenderId((prev) => prev + 1);
    }, [form, setRenderId]);

    useEffect(() => {
        calculateAll();
    }, [freightChanged, deliveryPlace, calculateAll]);

    // Handle presentation selection
    const setPresentation = useCallback((value, object, key) => {
        const products = form.getFieldValue("products");
        const product = products[key];
        const selectedPresentation = product.presentations.find((p) => p.objectId === value);

        if (selectedPresentation) {
            const updatedProduct = {
                ...product,
                presentation: selectedPresentation,
                price: parseFloat(selectedPresentation.minPrice) || 0, // Ensure price is a float
                minPrice: parseFloat(selectedPresentation.minPrice) || 0,
                lastClientPrice: parseFloat(selectedPresentation.lastClientPrice) || 0,
                lastPurchase: selectedPresentation.lastPurchase,
                totalVolume: parseFloat(selectedPresentation.content) * (parseInt(product.quantity) || 0), // Ensure totalVolume is a float
                total: (parseInt(product.quantity) || 0) * (parseFloat(selectedPresentation.minPrice) || 0),
                unitPrice: parseFloat(selectedPresentation.minPrice) / parseFloat(selectedPresentation.content) || 0,
            };

            const updatedProducts = products.map((p, idx) => (idx === key ? updatedProduct : p));
            form.setFieldsValue({ products: updatedProducts });
            setProducts(updatedProducts);
            setRenderId((prev) => prev + 1);
        }
    }, [form, setRenderId]);

    // Handle field value changes (onChange)
    const handleFieldChange = useCallback((field, value, record) => {
        let toChange = {}
        switch (field) {
            case 'price':
                toChange = { 
                    price: parseFloat(value) || 0,
                    unitPrice: parseFloat(value) / parseFloat(record.presentation?.content) || 0,
                    priceWithFreight: parseFloat(value) + parseFloat(form.getFieldValue("deliveryPrice")) / products.length || 0,
                    total: (parseInt(record.quantity) || 0) * (parseFloat(value) || 0),
                }
                break;
            case 'quantity':
                toChange = { 
                    quantity: parseInt(value) || 0,
                    totalVolume: parseFloat(record.presentation?.content) * (parseInt(value) || 0),
                    total: (parseInt(value) || 0) * (parseFloat(record.price) || 0),
                }
                break;
            case 'unitPrice':
                toChange = { 
                    unitPrice: parseFloat(value) || 0,
                    price: parseFloat(value) * parseFloat(record.presentation?.content) || 0,
                    priceWithFreight: parseFloat(value) * parseFloat(record.presentation?.content) + parseFloat(form.getFieldValue("deliveryPrice")) / products.length || 0,
                    total: (parseInt(record.quantity) || 0) * (parseFloat(value) * parseFloat(record.presentation?.content) || 0),
                }
                break;
            case 'totalVolume':
                toChange = { 
                    totalVolume: parseInt(value) || 0,
                    quantity: parseInt(value) / parseFloat(record.presentation?.content) || 0,
                    total: (parseInt(value) / parseFloat(record.presentation?.content) || 0) * (parseFloat(record.price) || 0),
                }
                break;
            case 'priceWithFreight':
                toChange = { 
                    priceWithFreight: parseFloat(value) || 0,
                    price: parseFloat(value) - parseFloat(form.getFieldValue("deliveryPrice")) / products.length || 0,
                    total: (parseInt(record.quantity) || 0) * (parseFloat(value) || 0),
                }
                break;
            default:
                break;
        }
        const toUpdate = form.getFieldValue("products");
        const updatedProducts = toUpdate.map((p) => {
            if (p.objectId === record.objectId) {
                return {
                    ...p,
                    ...toChange,
                };
            }
            return p;
        });

        form.setFieldsValue({ products: updatedProducts });
        setProducts(updatedProducts);
    }, [form]);

    // Handle product selection
    const onProductSelected = useCallback((objectId, product) => {
        const products = form.getFieldValue("products");
        if (!products.find((p) => p.objectId === product.objectId)) {
            const updatedProducts = [
                ...products,
                {
                    ...product,
                    quantity: 1, // Default quantity as integer
                    price: parseFloat(product.price) || 0, // Ensure price is a float
                },
            ];
            form.setFieldsValue({ products: updatedProducts });
            setProducts(updatedProducts);
            setRenderId((prev) => prev + 1);
        }
    }, [form, setRenderId]);

    // Render volume in appropriate units
    const renderVolume = useCallback((totalVolume) => {
        if (totalVolume) {
            return totalVolume >= 1000 ? `${totalVolume / 1000} T` : `${totalVolume} kg`;
        }
        return "";
    }, []);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DebounceSelect
                fetchOptions={fetchProducts}
                onChange={onProductSelected}
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />
            <Table
                dataSource={products}
                rowKey="objectId"
                pagination={false}
                scroll={{ x: true }}
            >
                <Table.Column title="Producto" dataIndex="name" key="name" />
                <Table.Column
                    title="Presentación"
                    dataIndex="presentation"
                    key="presentation"
                    render={(text, product, key) => (
                        <Select
                            style={{ width: 200 }}
                            onChange={(value, object) => setPresentation(value, object, key)}
                            options={product.presentations?.map((p) => ({
                                label: p.name,
                                value: p.objectId,
                                ...p,
                            }))}
                        />
                    )}
                />
                <Table.Column
                    title="Precio mínimo"
                    dataIndex="minPrice"
                    key="minPrice"
                    render={(text) => <p>${text?.toLocaleString()}</p>}
                />
                 <Table.Column
                    title="Precio"
                    dataIndex="price"
                    key="price"
                    render={(text, record) => (
                        <Input
                            type="number"
                            value={text}
                            onClick={(e) => e.target.select()}
                            style={{ width: 80 }}
                            onChange={(e) => handleFieldChange("price", e.target.value, record)}
                            onBlur={() => calculateAll("price")}
                        />
                    )}
                />
                {/* <Table.Column
                    title="Última compra"
                    dataIndex="lastPurchase"
                    key="lastPurchase"
                    render={(text) => <p>{text ? moment(text).format("DD/MM/YYYY") : "Sin compras previas"}</p>}
                />
                <Table.Column
                    title="Último precio"
                    dataIndex="lastClientPrice"
                    key="lastClientPrice"
                    render={(text) => <p>{text ? `$${text.toLocaleString()}` : ""}</p>}
                /> */}
                <Table.Column
                    title="Cantidad"
                    dataIndex="quantity"
                    key="quantity"
                    render={(text, record) => (
                        <Input
                            type="number"
                            value={text}
                            onClick={(e) => e.target.select()}
                            style={{ width: 80 }}
                            onChange={(e) => handleFieldChange("quantity", e.target.value, record)}
                            onBlur={calculateAll}
                        />
                    )}
                />
                 <Table.Column
                    title="Precio por kg"
                    dataIndex="unitPrice"
                    key="unitPrice"
                    render={(text, record) => (
                        <Input
                            type="number"
                            value={text}
                            onClick={(e) => e.target.select()}
                            style={{ width: 80 }}
                            onChange={(e) => handleFieldChange("unitPrice", e.target.value, record)}
                            onBlur={() => calculateAll("price")}
                        />
                    )}
                />
                <Table.Column
                    title="Volumen"
                    dataIndex="totalVolume"
                    key="totalVolume"
                    render={(text, record) => (
                        <InputNumber
                            value={text}
                            style={{ width: 80 }}
                            suffix={'kg'}
                            // formatter={(value) => renderVolume(value)}
                            // parser={(value) => value.replace(/[^\d.]/g, "")}
                            onChange={(value) => handleFieldChange("totalVolume", value, record)}
                            onBlur={calculateAll}
                        />
                    )}
                />

               
                {form.getFieldValue("deliveryPlace") === 'Domicilio' && 
                    <Table.Column
                    title="Precio con flete"
                    dataIndex="priceWithFreight"
                    key="priceWithFreight"
                    render={(text, record) => (
                        <Input
                            type="number"
                            value={text}
                            onClick={(e) => e.target.select()}
                            style={{ width: 80 }}
                            onChange={(e) => handleFieldChange("priceWithFreight", e.target.value, record)}
                            onBlur={() => calculateAll("priceWithFreight")}
                        />
                    )}
                />}
                <Table.Column
                    title="Total"
                    dataIndex="total"
                    key="total"
                    render={(text) => <p>${text?.toLocaleString()}</p>}
                />
                {form.getFieldValue("deliveryPlace") === 'Domicilio' && 
                <Table.Column
                    title="Total con flete"
                    dataIndex="totalWithFreight"
                    key="totalWithFreight"
                    render={(text) => <p>${text?.toLocaleString()}</p>}
                />}
                <Table.Column
                    title="Acción"
                    key="action"
                    render={(text, record) => (
                        <Button
                            type="danger"
                            onClick={() => {
                                const updatedProducts = products.filter((p) => p.objectId !== record.objectId);
                                form.setFieldsValue({ products: updatedProducts });
                                setProducts(updatedProducts);
                                setRenderId((prev) => prev + 1);
                                calculateAll();
                            }}
                        >
                            Eliminar
                        </Button>
                    )}
                />
            </Table>
        </Space>
    );
};

export default ProductList;