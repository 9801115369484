import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, message } from "antd";
import AddShrinkage from "./Modals/AddShrinkage";
import moment from "moment";

const renderVolume = (volume) => {
    return volume > 1000 ? `${volume / 1000} Ton` : `${volume} kg`;
};

const Shrinkage = ({ production, refresh }) => {
    const [shrinkage, setShrinkage] = useState([]);
    const [openShrinkageModal, setOpenShrinkageModal] = useState(false);

    // Load and process data when `production` changes
    useEffect(() => {
        if (production) {
            console.log('production events', production.productionEvents);
            const shrinkage = production.productionEvents
                ?.filter((event) => event.event === "reportShrinkage")
       
            setShrinkage(shrinkage);
        }
    }, [production]);

    return (
        <Row gutter={16}>
            <AddShrinkage open={openShrinkageModal} setOpen={setOpenShrinkageModal} production={production} refresh={refresh} />
            
            <Col span={24}>
                <Table
                    dataSource={shrinkage}
                    rowKey="objectId"
                    columns={[
                        {
                            title: "Fecha",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
                            sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                        },
                        {
                            title: "Producto",
                            dataIndex: ["ingredient", "name"],
                            key: "name",
                            render: (text, record) => text || record?.product?.name,
                        },
                        {
                            title: "Cantidad",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (text, record) => `${text} ${record?.ingredient ? 'pz' : 'kg'}`,
                        }
                      
                    ]}
                />
            </Col>
            <Col span={24}>
                <Button type="primary" onClick={() => setOpenShrinkageModal(true)} disabled={production?.closed}>
                    Agregar merma
                </Button>
            </Col>

        </Row>
    );
};

export default Shrinkage;