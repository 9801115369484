import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Select, Switch, InputNumber, Card } from 'antd';
import { SettingsContext } from '../../../context/SettingsContext';


const Settings = () => {
    const [localSettings, setLocalSettings] = useState(null);
    const { settings, setSettings } = React.useContext(SettingsContext);

    useEffect(() => {
        setLocalSettings(settings);
    }, [settings]);

    const handleChange = (value) => {
        const newSettings = localSettings.map((setting) => {
            if (setting.objectId === value.objectId) {
                setting.value = value;
            }
            return setting;
        });
        setLocalSettings(newSettings);
    };

    const renderSetting = (setting) => {
        console.log("🔧 Setting:", setting);
        switch (setting.type) {
            case "text":
                return <Input value={setting.value} />;
            case "number":
                return <InputNumber value={setting.value} />;
            case "percentage":
                return <InputNumber value={setting.value} suffix="%" min={0} max={100} onChange={handleChange} />;
            case "boolean":
                return <Switch checked={setting.value} />;
            case "select":
                return (
                    <Select value={setting.value}>
                        {setting.options.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                );
            default:
                return null;
        }
        }   

    console.log("🔧 Settings:", settings);
    return (
        <div>
            <h1>Configuraciones</h1>
            {settings && (
                settings.map((setting) => (
                    <Card key={setting.objectId} title={setting.friendlyName} style={{ marginBottom: "20px" }}>
                       {setting?.values?.map((value) => (
                            <Form.Item key={value.objectId} label={value.friendlyName}>
                                {renderSetting(value)}
                            </Form.Item>
                        ))}

                    </Card>
                ))
            )}

        </div>
    )
}

export default Settings;