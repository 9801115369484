import React, { useEffect, useState, useContext, useCallback } from "react";
import { Card, Col, Row, Table, Typography, FloatButton, Progress, Tag, Tooltip, Input, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NewQuote from "./components/NewQuote";
import SaleDetail from "./components/SaleDetail";
import { UserContext } from "../../../context/UserContext";
import moment from "moment";
import dayjs from "dayjs";
import Parse from "parse";

const { Title } = Typography;

const SalesList = () => {
  const [newQuoteModal, setNewQuoteModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [sales, setSales] = useState([]);
  const [sale, setSale] = useState(null);
  const [filters, setFilters] = useState({
    status: ["Cotización", "Venta", "Confirmada"],
    date: [moment(moment().add(-1, "month")), moment()],
  });
  const { permissions } = useContext(UserContext);

  const fetchClients = async (name) => {
    const query = new Parse.Query("_User");
    query.matches("fullName", name, "i");
    return query.find();
  };

  const fetchSeller = async (name) => {
    const query = new Parse.Query("_User");
    query.matches("fullName", name, "i");
    return query.find();
  };

  const fetchPresentations = useCallback(async (products) => {
    const Presentation = Parse.Object.extend("Presentation");
    const query = new Parse.Query(Presentation);
    query.containedIn("objectId", products.map((product) => product.presentation.objectId));
    const result = (await query.find()).map((presentation) => presentation.toJSON());
    let newProducts = products.map((product) => {
      const presentation = result.find((presentation) => presentation.objectId === product.presentation.objectId);
      return { ...product, presentation };
    });
    return newProducts;
  }, []);

  const fetchSales = useCallback(async () => {
    const Sale = Parse.Object.extend("Sales");
    const query = new Parse.Query(Sale);
    query.descending("createdAt");
    query.equalTo("active", true);
    query.include([
      "seller",
      "client",
      "products",
      "payments",
      "deliveries",
      "comments.author",
      "outlets",
    ]);

    if (filters.client) {
      const clients = await fetchClients(filters.client);
      query.containedIn("client", clients);
    }
    if (filters.seller) {
      const sellers = await fetchSeller(filters.seller);
      query.containedIn("seller", sellers);
    }
    if (filters.status) {
      query.containedIn("status", filters.status);
    }
    if (filters.date) {
      const start = dayjs(filters.date[0]).startOf("day").toDate();
      const end = dayjs(filters.date[1]).endOf("day").toDate();
      query.greaterThanOrEqualTo("createdAt", start);
      query.lessThanOrEqualTo("createdAt", end);
    }

    const results = (await query.find()).map((result) => result.toJSON());
    for (let i = 0; i < results.length; i++) {
      results[i].products = await fetchPresentations(results[i].products);
    }
    setSales(results);
  }, [filters]);

  useEffect(() => {
    fetchSales();
  }, [fetchSales]);

  const totalSales = sales?.reduce((acc, curr) => acc + curr.total || 0, 0);

  const renderDeliveryPlace = (deliveryPlace, sale) => {
    if (deliveryPlace === "Pie de fábrica") {
      return "Pie de fábrica";
    }
    const terms = sale.deliveryAddress?.terms;
    return terms
      ? `${terms[terms.length - 3]?.value}, ${terms[terms.length - 2]?.value}`
      : sale.deliveryAddress?.city + ", " + sale.deliveryAddress?.state;
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <h3>{moment(text).format("DD/MM/YYYY")}</h3>,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      showSorterTooltip: false,
    },
    {
      title: "Folio",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      render: (text, sale) => (
        <p
          onClick={() => {
            setSale(sale);
            setShowDetail(true);
          }}
        >
          {sale?.saleNumber || sale?.quoteNumber}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (client) => (
        <Tooltip
          title={
            <div>
              <p>{client?.fullName}</p>
              <p>{client?.rfc}</p>
              <p>{client?.email}</p>
              <p>{client?.phone}</p>
            </div>
          }
        >
          <p>{client?.fullName}</p>
        </Tooltip>
      ),
    },
    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      render: (seller) => <p>{seller?.fullName}</p>,
    },
    {
      title: "Lugar de entrega",
      dataIndex: "deliveryPlace",
      key: "deliveryPlace",
      render: (deliveryPlace, sale) => (
        <Tooltip title={sale.deliveryAddress?.address}>
          <p>{renderDeliveryPlace(deliveryPlace, sale)}</p>
        </Tooltip>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <h3>${text.toLocaleString()}</h3>,
    },
  ];

  const renderFilters = () => (
    <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
      <Input
        placeholder="Cliente"
        style={{ width: 200 }}
        value={filters.client}
        onChange={(e) => setFilters({ ...filters, client: e.target.value })}
      />
      <Input
        placeholder="Vendedor"
        style={{ width: 200 }}
        value={filters.seller}
        onChange={(e) => setFilters({ ...filters, seller: e.target.value })}
      />
      <Input
        placeholder="Folio"
        style={{ width: 200 }}
        value={filters.saleNumber}
        onChange={(e) => setFilters({ ...filters, saleNumber: e.target.value })}
      />
      <Select
        placeholder="Status"
        style={{ width: 300 }}
        value={filters.status}
        onChange={(value) =>
          setFilters({
            ...filters,
            status: value || ["Cotización", "Confirmada", "Venta"],
          })
        }
        allowClear
        mode="multiple"
      >
        <Select.Option value="Cotización">Cotización</Select.Option>
        <Select.Option value="Confirmada">Confirmada</Select.Option>
        <Select.Option value="Venta">Venta</Select.Option>
      </Select>
      <DatePicker.RangePicker
        style={{ width: 300 }}
        value={filters.date}
        onChange={(value) => setFilters({ ...filters, date: value })}
      />
    </div>
  );

  return (
    <div className="layout-content">
      <NewQuote open={newQuoteModal} setOpen={setNewQuoteModal} refresh={fetchSales} />
      <SaleDetail open={showDetail} setOpen={setShowDetail} sale={sale} refresh={fetchSales} setSale={setSale} />
      <FloatButton
        style={{ right: 20, bottom: 20 }}
        tooltip="Nueva venta"
        icon={<PlusOutlined />}
        onClick={() => setNewQuoteModal(true)}
      />
      <Row gutter={[24, 0]}>
        <Col lg={24} className="zindex">
          <h2 className="p-15 h2">Ventas</h2>
          {renderFilters()}
          <Card
            bordered={false}
            bodyStyle={{ padding: "0px" }}
            className="criclebox cardbody mb-24 header-solid"
          >
            <Table
              dataSource={sales}
              columns={columns}
              rowKey={(record) => record.objectId}
              pagination={true}
              footer={() => (
                <div style={{ textAlign: "right", fontSize: "20px" }}>
                  <strong>Total:</strong> ${totalSales.toLocaleString()}
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SalesList;