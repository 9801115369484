import React, { useState } from "react";
import { Row, Col, FloatButton } from "antd";
import NewSupplier from "../../../components/NewSupplier";
import {
  SupplierDetails,
  SupplierAddress,
  SupplierStatistics,
  SupplierTransactions,
  SupplierContactPersons
} from "./components";

const SupplierInfo = ({ supplier, refresh }) => {
  const [editSupplier, setEditSupplier] = useState(false);

  if (!supplier) return null;

  return (
    <Row gutter={[24, 24]}>
      <NewSupplier
        open={editSupplier}
        setOpen={setEditSupplier}
        supplier={supplier}
        editing
        refresh={refresh}
      />
      <FloatButton onClick={() => setEditSupplier(true)} />
      
      <Col span={20} lg={14} id="my-scroll-layout">
        <SupplierDetails supplier={supplier} />
        <SupplierContactPersons supplier={supplier} />
        <SupplierAddress supplier={supplier} />
      </Col>

      <Col span={12} lg={8}>
        <SupplierStatistics supplier={supplier} />
      </Col>
    </Row>
  );
};

export default SupplierInfo;