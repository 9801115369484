import Parse from "parse";
import { message } from "antd";

// Function to update stock and save stock movements
const updateStockAndMovements = async ({ ingredients, productionEvent }) => {
    if (!ingredients || ingredients.length === 0 || !productionEvent) {
        throw new Error("Invalid input to updateStockAndMovements: missing ingredients or productionEvent");
    }

    const Ingredient = Parse.Object.extend("Ingredient");
    const StockMovement = Parse.Object.extend("StockMovement");

    const updates = ingredients.map(async (ingredient) => {
        try {
            const ingredientQuery = new Parse.Query(Ingredient);
            ingredientQuery.equalTo("objectId", ingredient.objectId);
            const ingredientResult = await ingredientQuery.first();

            if (!ingredientResult) {
                throw new Error(`Ingredient not found for objectId: ${ingredient.objectId}`);
            }

            const previousStock = ingredientResult.get("stock") || 0;
            const newStock = previousStock - (ingredient.quantity || 0);

            ingredientResult.set("stock", newStock);
            await ingredientResult.save();

            const stockMovement = new StockMovement();
            stockMovement.set("ingredient", { __type: "Pointer", className: "Ingredient", objectId: ingredient.objectId });
            stockMovement.set("quantity", ingredient.quantity);
            stockMovement.set("type", "Agregado a producción");
            stockMovement.set("warehouse", "Producción");
            stockMovement.set("productionEvent", { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id });
            stockMovement.set("previousStock", previousStock);
            stockMovement.set("currentStock", newStock);
            stockMovement.set("user", Parse.User.current());

            await stockMovement.save();
        } catch (error) {
            console.error("Error updating stock or movements for ingredient:", ingredient, error);
            throw error;
        }
    });

    return Promise.all(updates);
};


// Function to update the production order
const updateProductionOrder = async ({ production, productionEvent }) => {
    if (!production || !productionEvent) {
        throw new Error("Invalid input to updateProductionOrder: missing production or productionEvent");
    }

    const ProductionOrder = Parse.Object.extend("ProductionOrder");
    const query = new Parse.Query(ProductionOrder);
    query.equalTo("objectId", production.objectId);

    try {
        const productionOrder = await query.first();

        if (!productionOrder) {
            throw new Error(`ProductionOrder not found for objectId: ${production.objectId}`);
        }

        const previousEvents = productionOrder.get("productionEvents") || [];
        productionOrder.set("productionEvents", [
            ...previousEvents,
            { __type: "Pointer", className: "ProductionEvents", objectId: productionEvent.id },
        ]);
        await productionOrder.save();
    } catch (error) {
        console.error("Error updating production order:", error);
        throw error;
    }
};

// Main function to handle adding ingredients
export const addIngredients = async ({ production, ingredients }) => {
    if (!production || !ingredients || ingredients.length === 0) {
        message.error("Missing production or ingredients data");
        return;
    }

    try {
        const ProductionEvent = Parse.Object.extend("ProductionEvents");
        const productionEvent = new ProductionEvent();

        productionEvent.set("productionOrder", { __type: "Pointer", className: "ProductionOrder", objectId: production.objectId });
        productionEvent.set("event", "addIngredients");
        productionEvent.set("warehouse", "Producción");
        productionEvent.set("ingredients", ingredients);
        productionEvent.set("user", Parse.User.current());

        const savedEvent = await productionEvent.save();

        await updateStockAndMovements({ ingredients, productionEvent: savedEvent });
        await updateProductionOrder({ production, productionEvent: savedEvent });

    } catch (error) {
        console.error("Error adding ingredients:", error);
        message.error("Error al agregar ingredientes. Por favor, intente nuevamente.");
    }
};