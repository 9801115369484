import React, { useEffect, useState } from "react";
import { Button, Card, Input, Space, Typography, Modal, Form, DatePicker, Select, message} from "antd";
import Parse from "parse";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

const { Title } = Typography;


const NewPayment = ({open, setOpen, object, parseClass, refresh, pendingPayment, purchaseOrder, schedulePayment}) => {
    const [form] = Form.useForm();
    const [disableSave, setDisableSave] = useState(false);

    console.log("🚀 ~ file: NewPayment.js ~ line 9 ~ NewPayment ~ object", object)

    const savePayment = async () => {
        try {
            // Validate form fields
            await form.validateFields();
            const values = form.getFieldsValue();
            console.log("💸 Payment: ", values, object, parseClass);
    
            // Initialize Parse Object for Payment
            const payment = await preparePayment(values);
    
            // Save payment object and update related Parse objects
            await payment.save();
    
            // Save updated index if a new payment number was generated
            if (!pendingPayment) {
                const index = await updateIndex();
                await index.save();
            }
    
            // Update purchaseOrder and user objects
            await updateRelatedObjects(payment, values);
    
            // Provide user feedback
            refresh();
            message.success('Pago creado correctamente');
            setOpen(false);
        } catch (error) {
            console.error('Error while creating Payment: ', error);
            message.error('Error al crear el pago');
        }
    };
    
    // Function to prepare the payment object
    const preparePayment = async (values) => {
        const Payment = Parse.Object.extend('Outpayments');
        const payment = new Payment();
    
        if (pendingPayment) {
            // Set objectId if updating a pending payment
            payment.set('objectId', pendingPayment.objectId);
        } else {
            // Generate new payment number
            const { paymentNumber, index } = await generatePaymentNumber();
            payment.set("paymentNumber", paymentNumber);
            payment.set('uuid', uuidv4());
        }
    
        // Set common payment fields
        payment.set('amount', parseFloat(values.amount));
        payment.set('method', values.paymentType);
        payment.set('notes', values.notes);
        payment.set('supplier', {
            __type: 'Pointer',
            className: '_User',
            objectId: purchaseOrder.supplier.objectId,
        });
        payment.set('purchaseOrder', {
            __type: 'Pointer',
            className: 'PurchaseOrder',
            objectId: purchaseOrder.objectId,
        });
        payment.set('category', 'Insumos');
        payment.set('pending', false);
        if (schedulePayment) {
            payment.set('pending', true);
            payment.set('status', 'Pendiente');
            console.log('pppp', values.paymentDate)
            payment.set('dueDate', moment(values.paymentDate.$d).toDate());
        } else {
            payment.set('pending', false);
            payment.set('status', 'Pagado');
            payment.set('paymentDate', moment(values.paymentDate.$d).toDate());
        }
    
        return payment;
    };
    
    // Function to generate a new payment number
    const generatePaymentNumber = async () => {
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'Outpayment');
        query.equalTo('year', new Date().getFullYear());
    
        const index = await query.first();
        const paymentNumber = 'P' + (index.get('value') + 1).toString().padStart(4, '0') + '-' + new Date().getFullYear();
        index.increment('value', 1);
    
        // Return both the payment number and the updated index
        return { paymentNumber, index };
    };
    
    // Function to update the index object
    const updateIndex = async () => {
        const Index = Parse.Object.extend('Index');
        const query = new Parse.Query(Index);
        query.equalTo('name', 'Payment');
        query.equalTo('year', new Date().getFullYear());
    
        const index = await query.first();
        if (!index) {
            throw new Error('Index not found for Payments');
        }
    
        index.increment('value', 1);
        return index;
    };
    
    // Function to update the purchaseOrder and user objects
    const updateRelatedObjects = async (payment, values) => {
        // Update purchaseOrder object
        const ParseClass = Parse.Object.extend('PurchaseOrder');
        const purchaseOrderObject = new ParseClass();
        purchaseOrderObject.set('objectId', purchaseOrder.objectId);
        purchaseOrderObject.addUnique('payments', payment);
        !schedulePayment && purchaseOrderObject.increment('totalPaid', parseFloat(values.amount));
        await purchaseOrderObject.save();
    
        // Update user object
        const _User = Parse.Object.extend('_User');
        const user = new _User();
        user.set('objectId', purchaseOrder.supplier.objectId);
        user.addUnique('payments', payment);
    
        // Adjust user balance (logic identical for now but allows future customization)
        const amount = parseFloat(values.amount);
        !schedulePayment && user.increment('balance', amount);        
    
        await user.save();
    };
    
    const title = () => {
        if (pendingPayment) {
            return 'Confirmando pago';
        } else if (schedulePayment) {
            return 'Programando pago';
        } else {
            return 'Nuevo pago';
        } 
    }

    const minDate = schedulePayment ? moment().add(1, 'day') : undefined;
    const maxDate = schedulePayment ? undefined : moment();

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ top: 20, width: "90%" }}
    >
        <Title level={4}>{title()}</Title>
        <Form
            form={form}
            layout="vertical"
            onFinish={savePayment}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                    label="Fecha de pago"
                    name="paymentDate"
                    rules={[{ required: true, message: "Por favor, ingrese la fecha de pago" }]}
                >
                    <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Monto"
                    name="amount"
                    rules={[{ required: true, message: "Por favor, ingrese el monto del pago" }]}
                >
                    <Input type="number" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Tipo de pago"
                    name="paymentType"
                    rules={[{ required: true, message: "Por favor, seleccione el tipo de pago" }]}
                >
                    <Select style={{ width: "100%" }}>
                        <Select.Option value="Efectivo">Efectivo</Select.Option>
                        <Select.Option value="Transferencia">Transferencia</Select.Option>
                        <Select.Option value="Cheque">Cheque</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Observaciones"
                    name="notes"
                >
                    <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                        <Button type="primary" htmlType="submit" disabled={disableSave}>Guardar</Button>
                    </Space>
                </Form.Item>
            </Space>
        </Form>
    </Modal>
}

export default NewPayment;