import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, Modal, Select, message } from "antd";
import { retrieveReprocessing } from "../services";

const RetrieveReprocessing = ({ production, open, setOpen, refresh }) => {
    const [quantity, setQuantity] = useState(0);
    const [loading, setLoading] = useState(false);


    // // Function to handle form submission
    const handleAddShrinkage = async () => {
        if ((!quantity)) {
            message.error("Por favor, especifique la cantidad.");
            return;
        }

        setLoading(true);
        try {
            await retrieveReprocessing({
                production,
                quantity: parseFloat(quantity),
            });
            // message.success("Empaque agregado exitosamente.");
            setOpen(false);
        } catch (error) {
            console.error("Error adding packaging:", error);
            // message.error("Ocurrió un error al agregar el empaque. Intente de nuevo.");
        } finally {
            setLoading(false);
            refresh();
        }
    };

    useEffect(() => {
        if (!open) {
            
        }
    }, [open]);


    return (
        <Modal
            title="Recuperar reproceso"
            open={open}
            okText="Confirmar"
            cancelText="Cancelar"
            confirmLoading={loading}
            onOk={handleAddShrinkage}
            onCancel={() => setOpen(false)}
            width={800}
        >
            <Row gutter={16}>
                {/* Presentation Selection */}
               

                {/* Quantity Input */}
                <Col span={12}>
                    <Card title="Cantidad">
                        <Input
                            type="number"
                            placeholder="Cantidad"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            suffix="kg"
                        />
                    </Card>
                </Col>
                
               
            </Row>

        </Modal>
    );
};

export default RetrieveReprocessing;