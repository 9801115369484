import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, message } from "antd";
import AddPackaging from "./Modals/AddPackaging";

const renderVolume = (volume) => {
    return volume > 1000 ? `${volume / 1000} Ton` : `${volume} kg`;
};

const ForageAdding = ({ production, refresh }) => {
    const [ingredients, setIngredients] = useState([]);
    const [packaging, setPackaging] = useState([]);
    const [openPackagingModal, setOpenPackagingModal] = useState(false);

    // Load and process data when `production` changes
    useEffect(() => {
        if (production) {
            console.log('production events', production.productionEvents);
            const totalForage = production.productionEvents
                ?.filter((event) => event.event === "addIngredients")
                .filter((event) => event.warehouse === "Forrajes")
                .reduce((sum, event) => sum + event.ingredients
                .reduce((sum, ingredient) => sum + ingredient.quantity, 0), 0);
            const totalGrease = production.productionEvents
                ?.filter((event) => event.event === "addIngredients")
                .filter((ingredient) => ingredient.warehouse === "Producción")
                .reduce((sum, event) => sum + event.ingredients
                .reduce((sum, ingredient) => sum + ingredient.quantity, 0), 0);

            const totalReprocessing = production.productionEvents
                ?.filter((event) => event.event === "addReprocessing")
                .reduce((sum, event) => sum + event.quantity, 0);

            setIngredients({ totalForage, totalGrease, totalReprocessing });

            const packaging = production.productionEvents
                ?.filter((event) => event.event === "addPackaging")
            const totalPackaging = packaging?.reduce((sum, event) => sum + event.quantity, 0);
            const totalVolume = packaging?.reduce((sum, event) => sum + event.volume, 0);
            setPackaging({ packaging, totalPackaging, totalVolume });
        }
    }, [production]);

    return (
        <Row gutter={16}>
            <AddPackaging open={openPackagingModal} setOpen={setOpenPackagingModal} production={production} refresh={refresh} />
            {/* Ingredients Table */}
            <Col span={12}>
                <Card title="Forrajes">
                    <p>Total de forrajes: {renderVolume(ingredients.totalForage)}</p>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Grasa y digesta">
                    <p>Total de grasa y digesta: {renderVolume(ingredients.totalGrease)}</p>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Reproceso">
                    <p>Reproceso agregado: {ingredients.totalReprocessing} kg</p>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Envasado">
                    <p>Total envasado: {packaging.totalPackaging} piezas</p>
                    <p>Volumen total: {renderVolume(packaging.totalVolume)}</p>
                </Card>
            </Col>
           
            <Col span={24}>
                <Table
                    dataSource={packaging.packaging}
                    rowKey="objectId"
                    columns={[
                        {
                            title: "Fecha",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (date) => new Date(date).toLocaleString(),
                            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                        },
                        {
                            title: "Producto",
                            dataIndex: ["presentation", "name"],
                            key: "name",
                        },
                        {
                            title: "Envasado",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (quantity) => `${quantity} piezas`,
                        },
                        {
                            title: "Volumen",
                            dataIndex: "volume",
                            key: "volume",
                            render: renderVolume,
                        }
                      
                    ]}
                />
            </Col>
            <Col span={24}>
                <Button type="primary" onClick={() => setOpenPackagingModal(true)} disabled={production?.closed}>
                    Agregar empaque
                </Button>
            </Col>

        </Row>
    );
};

export default ForageAdding;