import React, { useEffect, useState } from "react";
import { Table, Button, Space, Input } from "antd";
import DebounceSelect from "../../../../form/DebounceSelect";
import { fetchPackaging } from "../services"

const PackagingList = ({ presentation, recipe, setPresentation }) => {
    const [packaging, setPackaging] = useState([]);

    // Update presentation whenever packaging changes
    useEffect(() => {
        setPresentation({
            ...presentation,
            packaging,
        });
    }, [packaging]);

    useEffect(() => {
        setPackaging(presentation.packaging || []);
    }, [presentation]);

    // Handle adding new items to packaging
    const onItemSelected = (objectId, item) => {
        if (packaging.find((p) => p.objectId === item.objectId)) return;

        const newPackaging = [
            ...packaging,
            {
                ...item,
                quantity: 1,
                total: item.lastCost || 0,
            },
        ];

        setPackaging(newPackaging);
    };

    // Handle quantity changes
    const handleQuantityChange = (objectId, value) => {
        const updatedPackaging = packaging.map((item) =>
            item.objectId === objectId
                ? {
                      ...item,
                      quantity: parseFloat(value) || 0,
                      total: (item.lastCost || 0) * (parseFloat(value) || 0),
                  }
                : item
        );

        setPackaging(updatedPackaging);
    };

    // Handle item removal
    const handleRemove = (objectId) => {
        const updatedPackaging = packaging.filter((item) => item.objectId !== objectId);
        setPackaging(updatedPackaging);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {/* Search Input for Packaging */}
            <DebounceSelect
                fetchOptions={fetchPackaging}
                onChange={onItemSelected}
                placeholder="Buscar insumo"
                style={{ width: "100%" }}
                allowClear
                showSearch
            />

            {/* Packaging Table */}
            <Table
                dataSource={packaging}
                rowKey="objectId"
                pagination={false}
                scroll={{ x: true }}
            >
                <Table.Column title="Insumo" dataIndex="name" key="name" />

                <Table.Column
                    title="Cantidad"
                    dataIndex="quantity"
                    key="quantity"
                    render={(text, record) => (
                        <Input
                            type="number"
                            value={text}
                            onClick={(e) => e.target.select()}
                            style={{ width: 80 }}
                            onChange={(e) => handleQuantityChange(record.objectId, e.target.value)}
                        />
                    )}
                />

                <Table.Column
                    title="Acción"
                    key="action"
                    render={(text, record) => (
                        <Button type="danger" onClick={() => handleRemove(record.objectId)}>
                            Eliminar
                        </Button>
                    )}
                />
            </Table>
        </Space>
    );
};

export default PackagingList;