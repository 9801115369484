import React, { useState } from "react";
import { Button, Card, Table, Typography, Modal, Row, Col, Descriptions, message } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import moment from "moment";
import { Printer } from "react-bootstrap-icons";
import Parse from "parse";
import OwnButton from "../../OwnButton";

const { Title, Text } = Typography;

const DeliveryDetail = ({ open, setOpen, delivery, sale }) => {
    const [printing, setPrinting] = useState(false);
    console.log("📦 Order: ", delivery);


    const handlePrint = async () => {
        setPrinting(true);
        const params = {
            deliveryOrderNumber: delivery.orderNumber,
            date: delivery.createdAt,
            toReceive: sale.deliveryPlace === "Domicilio" ? "Transportista" : "Cliente",
            createdBy: delivery.createdBy?.fullName || "N/A",
            client: sale.client.fullName,
            sale: sale.saleNumber,
            seller: sale.seller.fullName,
            comments: delivery.comments || "Sin comentarios",
            products: delivery.products,
        };

        
        try {
            const data = await Parse.Cloud.run("printDeliveryOrder", params);

            const pdfWindow = window.open();
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='data:application/pdf;base64,${data.pdf}'></iframe>`
            );

        } catch (error) {
            console.error("Error printing delivery order:", error);
            message.error("Ocurrió un error al imprimir la orden de salida. Intente de nuevo.");
        } finally {
            setPrinting(false);
        }
    };
    

    if (!delivery) return null;

    return (
        <Modal
            closable={false}
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <FileTextOutlined style={{ fontSize: "22px", color: "#1890ff" }} />
                        <Title level={4} style={{ marginBottom: 0 }}>
                            Orden de salida
                        </Title>
                    </div>
                    {sale?.active && (
                        <OwnButton
                            icon={<Printer />}
                            type="default"
                            onClick={handlePrint}
                            loading={printing}
                        >
                            Imprimir
                        </OwnButton>
                    )}
                </div>
            }
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="close" type="primary" onClick={() => setOpen(false)}>
                    Cerrar
                </Button>,
            ]}
            width={900}
            bodyStyle={{ padding: "24px" }}
        >
            {/* Order Info */}
            <Card bordered={false} style={{ backgroundColor: "#f9f9f9", marginBottom: "20px" }}>
                <Descriptions column={2} size="middle" layout="vertical">
                    <Descriptions.Item label="Fecha">
                        {moment(delivery.createdAt).format("DD/MM/YYYY")}
                    </Descriptions.Item>
                    <Descriptions.Item label="A recibir por">
                        {sale.deliveryPlace === "Domicilio" ? "Transportista" : "Cliente"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Emitida por">
                        {delivery.createdBy?.fullName || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cliente">
                        {sale.client.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Venta">
                        {sale.saleNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vendedor">
                        {sale.seller.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Comentarios" span={2}>
                        {delivery.comments || "Sin comentarios"}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {/* Product Table */}
            <Title level={4} style={{ marginBottom: "10px" }}>Productos</Title>
            <Table
                dataSource={delivery?.products}
                columns={[
                    {
                        title: "Producto",
                        dataIndex: "name",
                        key: "name",
                        render: (text) => <Text strong>{text}</Text>,
                    },
                    {
                        title: "Cantidad",
                        dataIndex: "quantity",
                        key: "quantity",
                        align: "center",
                        render: (text) => <Text>{text}</Text>,
                    },
                ]}
                pagination={false}
                rowKey={(record) => record.name}
                style={{ marginBottom: "20px", borderRadius: "8px" }}
            />
        </Modal>
    );
};

export default DeliveryDetail;