import React, { useState } from "react";
import { Button } from "antd";

const OwnButton = ({ icon, children, ...props }) => {
    const [showLabel, setShowLabel] = useState(false);
    return (
        <Button
            {...props}
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "all 0.3s ease",
            }}
            onMouseEnter={() => setShowLabel(true)}
            onMouseLeave={() => setShowLabel(false)}
        >
            {icon}
            {showLabel && <span>{children}</span>}
        </Button>
    );
};

export default OwnButton;