import React, { useEffect, useState } from "react";
import { Button, Table, Input, Card, Row, Col, message } from "antd";
import AddReprocessing from "./Modals/AddReprocessing";
import RetrieveReprocessing from "./Modals/RetrieveReprocessing";
import Title from "antd/es/typography/Title";
const renderVolume = (volume) => {
    return volume > 1000 ? `${volume / 1000} Ton` : `${volume} kg`;
};

const Reprocessing = ({ production, refresh }) => {
    const [addedReprocessing, setAddedReprocessing] = useState([]);
    const [retrievedReprocessing, setRetrievedReprocessing] = useState([]);
    const [addReprocessingModal, setAddReprocessingModal] = useState(false);
    const [retrieveReprocessingModal, setRetrieveReprocessingModal] = useState(false);

    // Load and process data when `production` changes
    useEffect(() => {
        if (production) {
            console.log('production events', production.productionEvents);
            const addReprocessing = production.productionEvents
                ?.filter((event) => event.event === "addReprocessing")
       
            const retrieveReprocessing = production.productionEvents
                ?.filter((event) => event.event === "retrieveReprocessing")

            setAddedReprocessing(addReprocessing);
            setRetrievedReprocessing(retrieveReprocessing);
        }
    }, [production]);

    return (
        <Row gutter={16}>
            <AddReprocessing open={addReprocessingModal} setOpen={setAddReprocessingModal} production={production} refresh={refresh} />
            <RetrieveReprocessing open={retrieveReprocessingModal} setOpen={setRetrieveReprocessingModal} production={production} refresh={refresh} />
            <Col span={12}>
                <Table
                    dataSource={addedReprocessing}
                    rowKey="objectId"
                    title={() => <Title level={4}>Reprocesos agregados</Title>}
                    columns={[
                        // {
                        //     title: "Producto",
                        //     dataIndex: ["ingredient", "name"],
                        //     key: "name",
                        //     render: (text, record) => text || record?.product?.name,
                        // },
                        {
                            title: "Fecha",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (text) => new Date(text).toLocaleString(),
                        },
                        {
                            title: "Cantidad",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (text, record) => `${text} ${record?.ingredient ? 'pz' : 'kg'}`,
                        },
                      
                    ]}
                />
            </Col>
            <Col span={12}>
                <Table
                    dataSource={retrievedReprocessing}
                    rowKey="objectId"
                    title={() => <Title level={4}>Reprocesos recuperados</Title>}
                    columns={[
                        // {
                        //     title: "Producto",
                        //     dataIndex: ["ingredient", "name"],
                        //     key: "name",
                        //     render: (text, record) => text || record?.product?.name,
                        // },
                        {
                            title: "Fecha",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (text) => new Date(text).toLocaleString(),
                        },
                        {
                            title: "Cantidad",
                            dataIndex: "quantity",
                            key: "quantity",
                            render: (text, record) => `${text} ${record?.ingredient ? 'pz' : 'kg'}`,
                        }
                      
                    ]}
                />
            </Col>
            <Col span={6}>
                <Button type="primary" onClick={() => setAddReprocessingModal(true)} disabled={production?.closed}>
                    Agregar reproceso
                </Button>
            </Col>
            <Col span={6}>
                <Button type="primary" onClick={() => setRetrieveReprocessingModal(true)} disabled={production?.closed}>
                Recuperar reproceso
                </Button>
            </Col>

        </Row>
    );
};

export default Reprocessing;